<template>
  <div class="main">
    <header-component
      ref="headerContext"
      @resize-type="setResponsive"
      @currentLanguageType="languageTrans"
      @sideMenu-call="sideMenuCall"
      :menuList="menuList"
    ></header-component>
    <ul id="menu" @click.stop>
      <li data-menuanchor="page1" class="active" @click="menuClikck(0)">
        <a href="#page1">Section 1</a>
      </li>
      <li data-menuanchor="page2" @click="menuClikck(1)">
        <a href="#page2">Section 2</a>
      </li>
      <li data-menuanchor="page3" @click="menuClikck(2)">
        <a href="#page3">Section 3</a>
      </li>
    </ul>
    <full-page
      class="fullPage-container main"
      ref="fullpage"
      :options="options"
      id="fullpage"
      @after-load="afterLoad"
      @on-leave="onLeave"
    >
      <div class="section default">
        <swiper :swiperList="swiperList" ref="swiperContext"></swiper>
      </div>
      <div class="section inBg">
        <div class="s-bg-left-top">
          <img src="@assets/images/main/main_img_half_top.svg" />
        </div>
        <news
          :newsList="newsList"
          :newsType="newsType"
          ref="newsContext"
        ></news>
      </div>
      <div class="section fp-auto-height inTopButton">
        <div class="s-bg-left-bottom">
          <img src="@assets/images/main/main_img_half_bottom.svg" />
        </div>
        <partner
          :partnerList="partnerList"
          :partnerIsMobile="partnerIsMobile"
          ref="partnerContext"
        ></partner>
        <top-button
          ref="topButtonContext2"
          @pressedTopButton="moveTop"
        ></top-button>
        <footer-component
          :footerList="footerList"
          ref="footerContext"
          @sideMenu-call="sideMenuCall"
        ></footer-component>
      </div>
    </full-page>
    <top-button ref="topButtonContext" @pressedTopButton="moveTop"></top-button>
  </div>
</template>
<script>
import HeaderComponent from "@component/Header.vue";
import FooterComponent from "@component/Footer.vue";
import TopButton from "@component/TopButton.vue";
import News from "@page/commons/News.vue";
import Partner from "@page/commons/Partner.vue";
import Swiper from "@page/main/Swiper.vue";
export default {
  data() {
    return {
      isGuided: null,
      partnerIsMobile: false,
      options: {
        licenseKey: "1B30CEC9-876B4E97-A3C378D3-89432445",
        afterLoad: this.afterLoad,
        onLeave: this.onLeave,
        scrollOverflow: true,
        scrollBar: false,
        fitToSectionDelay: 1000,
        menu: "#menu",
        navigation: false,
        anchors: ["page1", "page2", "page3"],
        sectionsColor: ["#ffffff", "#ffffff", "#ffffff", "#ffffff", "#ffffff"],
      },
      languageType: sessionStorage.getItem("languageType"),
      partnerList: {
        img: [
          "1.png",
          "2.png",
          "3.png",
          "4.png",
          "5.png",
          "6.png",
          "7.png",
          "8.png",
          "9.png",
          "10.png",
          "11.png",
          "12.png",
          "13.png",
          "14.png",
          "15.png",
          "16.png",
          "17.png",
          "18.png",
          "19.png",
          "20.png",
          "21.png",
          "22.png",
          "23.png",
          "24.png",
          "25.png",
          "26.png",
          "27.png",
          "28.png",
          "29.png",
          "30.png",
          "31.png",
        ],
      },
      menuList: [
        {
          title: "About Us",
          child: [
            "Who We Are",
            "Ethics Policy",
            "Partnerships",
            "Finehealthcare CI",
          ],
          link: ["about", "about#page2", "about#page3", "about#page4"],
        },
        {
          title: "Telederm Units",
          child: ["Skinex", "Lime Skin"],
          link: ["telederm/skinex", "telederm/lime"],
        },
        {
          title: "Big Data Platforms",
          child: ["Lime EMR", "Lime Team", "Lime Clinic"],
          link: ["platform/emr", "platform/team", "platform/clinic"],
        },
        {
          title: "Fine News",
          child: ["News"],
          link: ["news"],
        },
        {
          title: "Contact Us",
          child: ["Contact", "Direction & Map"],
          link: ["contact", "contact#page2"],
        },
      ],
      newsType: 1,
      newsList: [
        {
          img: "20241113.png",
          link: "https://www.sedaily.com/DigitalPaper/20241113?PAGE_NO=008#",
          contentEng: [
            "Establishing Our Place as a Leading AI Healthcare Innovator Alongside Lunit and Selvas",
            "Sedaily",
            "2024.11.13",
          ],
          contentKor: [
            "루닛·셀바스와 함께 주목받는 AI 헬스케어 혁신 기업으로 자리매김",
            "서울경제",
            "2024.11.13",
          ],
        },
        {
          img: "20240405.png",
          link: "https://news.mt.co.kr/mtview.php?no=2024040509470366557",
          contentEng: [
            "Signed an MOU with LINET, the world's No. 1 bed and medical device manufacturer",
            "MONEYTODAY",
            "2024.04.05",
          ],
          contentKor: [
            "세계 1위 침대 및 의료기기 제조업체 리넷과 MOU 체결",
            "머니투데이",
            "2024.04.05",
          ],
        },
        {
          img: "20240322.png",
          link: "https://news.mt.co.kr/mtview.php?no=2024032213420093019",
          contentEng: [
            "Clinical Photography Management Program, 'PCOM' Released",
            "MONEYTODAY",
            "2024.03.21",
          ],
          contentKor: [
            "임상사진관리프로그램, '피콤' 출시",
            "머니투데이",
            "2024.03.21",
          ],
        },
      ],
      swiperList: [
        {
          img: [
            "414_main_img_carousel_1.png",
            "768_main_img_carousel_1.png",
            "1024_main_img_carousel_1.png",
            "main_img_carousel_1.png",
          ],
          contentEng: [
            "Skin image analysing",
            "Medical AI",
            "For individually customizied care plan",
          ],
          contentKor: [
            "Skin image analysing",
            "Medical AI",
            "For individually customizied care plan",
          ],
        },
        {
          img: [
            "414_main_img_carousel_2.png",
            "768_main_img_carousel_2.png",
            "1024_main_img_carousel_2.png",
            "main_img_carousel_2.png",
          ],
          contentEng: [
            "Global multi-channel",
            "Big Data Platform",
            "To set up the digital healthcare eco-system",
          ],
          contentKor: [
            "Global multi-channel",
            "Big Data Platform",
            "To set up the digital healthcare eco-system",
          ],
        },
        {
          img: [
            "414_main_img_carousel_3.png",
            "768_main_img_carousel_3.png",
            "1024_main_img_carousel_3.png",
            "main_img_carousel_3.png",
          ],
          contentEng: [
            "Managing your whole life",
            "Digital Therapeutics",
            "Toward smart monitoring & Everyday private homecare",
          ],
          contentKor: [
            "Managing your whole life",
            "Digital Therapeutics",
            "Toward smart monitoring & Everyday private homecare",
          ],
        },
      ],
      footerList: {
        menuLink: ["", ""],
        menuKor: ["개인정보 처리방침", "사이트맵"],
        menuEng: ["Privacy Policy", "SiteMap"],
        companyKor: [
          "(주) Finehealthcare",
          "주소 : (06069) 서울특별시 강남구 선릉로 704(청담동), 청담빌딩 7층 ",
        ],
        companyEng: [
          "Finehealthcare",
          "(06069) 7F, Cheongdam Building. 704, Seolleung-ro, Gangnam-gu, Seoul",
        ],
        infoKor: [
          "대표이사 : 신현경",
          "사업자등록번호 : 141-86-01677",
          "대표번호 : 02-565-2920",
          "이메일 : fine@finehc.ai",
        ],
        infoEng: [
          "CEO: SHIN HYUN KYUNG",
          "Corporate Registration Number : 141-86-01677",
          "Telephone : 02-565-2920",
          "E-mail : fine@finehc.ai",
        ],
      },
      resizeType: 3,
    };
  },
  created() {},
  mounted() {
    console.log("메인페이지");
    /** 레이아웃시 토글
       *
        this.$refs.fullpage.api.setAutoScrolling(false);
         */
  },
  components: {
    Swiper,
    HeaderComponent,
    FooterComponent,
    TopButton,
    News,
    Partner,
  },
  methods: {
    setResponsive(type) {
      console.log(type, "type");
      this.resizeType = type;
      console.log("type:", type);
      if (type === 3) {
      } else if (type === 2) {
        this.options.autoScrolling = false;
        this.options.scrollOverflow = false;
        this.isGuided = false;
        this.$nextTick((_) => {
          document.querySelector(".default").classList.add("fp-auto-height");
          document.querySelector(".inBg").classList.add("fp-auto-height");
        });
      } else if (type === 1) {
      } else if (type === 0) {
        this.partnerIsMobile = true;
        this.$nextTick((_) => {
          document.querySelector(".default").classList.add("fp-noscroll");
          document.querySelector(".inBg").classList.add("fp-auto-height");
        });
      }
      this.$nextTick((_) => {
        this.$refs.swiperContext.setResizeType(type);
      });
    },
    sideMenuCall(condition) {
      if (condition) {
        this.$refs.fullpage.api.silentMoveTo("page1");
        this.$refs.headerContext.isSpreadMenuByChild(true);
        this.$refs.topButtonContext.isView(false);
      }
    },
    moveTop() {
      this.$refs.fullpage.api.moveTo("page1");
      this.$refs.headerContext.navTrans(0);
      this.$refs.topButtonContext.isView(false);
    },
    languageTrans(languageType) {
      this.$nextTick((_) => {
        if (languageType === 0) {
          this.$refs.swiperContext.isEng(false);
          this.$refs.newsContext.isEng(false);
          this.$refs.footerContext.isEng(false);
        } else {
          this.$refs.swiperContext.isEng(true);
          this.$refs.newsContext.isEng(true);
          this.$refs.footerContext.isEng(true);
        }
        this.$refs.fullpage.api.reBuild();
      });
    },
    effectInit() {
      this.$refs.swiperContext.slideEnd();
      this.$refs.newsContext.initNews();
      this.$refs.partnerContext.initPartner();
      document.querySelector(".s-bg-left-top").classList.remove("ani-on");
      document.querySelector(".s-bg-left-bottom").classList.remove("ani-on");
    },
    initThings() {
      this.$refs.headerContext.navTrans(2);
      this.$refs.headerContext.isSpreadMenu(false);
      this.$refs.topButtonContext2.isView(false);
      if (this.isGuided !== false) {
        this.$refs.topButtonContext.isView(true);
      }
      this.effectInit();
    },
    onLeave(origin, destination, direction) {
      if (destination.index === 0) {
        this.effectInit();
        if (direction === "up") {
          this.$refs.headerContext.navTrans(0);
          this.$refs.topButtonContext.isView(false);
          this.$refs.topButtonContext2.isView(false);
          this.$refs.swiperContext.slideStart(0);
        } else {
        }
      } else if (destination.index === 1) {
        this.initThings();
        this.$refs.newsContext.setNews();
        document.querySelector(".s-bg-left-top").classList.add("ani-on");
        if (direction === "up") {
        } else {
        }
      } else if (destination.index === 2) {
        this.initThings();
        this.$refs.partnerContext.setPartner();
        document.querySelector(".s-bg-left-bottom").classList.add("ani-on");

        if (direction === "up") {
        } else {
          if (this.isGuided !== false) {
            this.$refs.topButtonContext.isView(false);
          }
          this.$refs.topButtonContext2.isView(true);
        }
      }
      console.log("Leave origin", origin.index);
      console.log("Leave destination", destination.index);
      console.log("Leave direction", direction);
    },
    afterLoad(origin, destination, direction) {
      console.log("After origin", origin.index);
      console.log("After destination", destination.index);
      console.log("After direction", direction);
    },
  },
  unmounted() {},
};
</script>
<style lang="scss" scoped>
@import "@assets/css/responsive";
.fullPage-container {
  .section {
    .s-bg-left-top {
      position: absolute;
      bottom: 0;
      left: 0;
      &.ani-on {
        animation: slideUp 1.9s cubic-bezier(0.65, 0, 0.35, 1) both;
        -webkit-animation: slideUp 1.9s cubic-bezier(0.65, 0, 0.35, 1) both;
      }
    }

    .s-bg-left-bottom {
      position: absolute;
      top: 0px;
      left: 0;
      &.ani-on {
        animation: slideUp2 1.9s cubic-bezier(0.65, 0, 0.35, 1) both;
        -webkit-animation: slideUp2 1.9s cubic-bezier(0.65, 0, 0.35, 1) both;
      }
    }

    &:not(.default) {
      .m-context {
        max-width: 1160px;
        margin: 0 auto;
        height: 100%;
      }
    }

    &.inTopButton {
      .top-button {
        bottom: 399px;
      }
    }
  }
}

.top-button {
  position: absolute;
  z-index: 3;
  right: 100px;
  bottom: 100px;
  width: 0px;
  height: 0px;
  background-image: url("~@assets/images/common/btn-top.svg");
  background-size: cover;
  cursor: pointer;
  transition: height 0.5s;

  &.active {
    &:hover {
      background-image: url("~@assets/images/common/btn-top-hover.svg");
    }

    width: 170px;
    height: 170px;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

#menu {
  display: none;
}

.section {
  &.inBg {
    .m-context {
      padding: 156px 0 100px;
      min-height: 804px;
    }
  }
}
@media screen and (max-width: 1024px) {
  .fullPage-container {
    .section {
      .s-bg-left-top,
      .s-bg-left-bottom {
        display: none;
      }
    }
  }
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .fullPage-container {
    .section {
      &.inBg {
        .m-context {
          height: 764px;
          min-height: 604px;
          position: relative;
        }
      }
    }
  }
}
</style>
