<template>
   <div class="top-button" @click="hideButton()" :class="{active: view}">
    </div>
</template>

<script>
export default {
  data() {
    return {
      view: false
    };
  },
  created() {},
  methods: {
    hideButton() {
      this.view = false;
      this.$emit("pressedTopButton");
    },
    isView(condition) {
      this.view = condition;
    }
  },
  computed: {},
  components: {},
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 1399px) {
   #app .top-button.active {
    right: 65px;
    bottom: 40px;
    width: 145px;
    height: 145px;
  }

   #app .section.inTopButton .top-button {
    bottom: 340px;
  }
}

   @media screen and (min-width: 769px) and (max-width: 1024px) {
     #app .top-button.active {
        right: 50px;
        width: 80px;
        height: 80px;
    }
   
   }
   @media screen and (max-width: 767px) {
   #app .top-button.active {
        display: none;
  }
   #app .section {
    &.inTopButton {
      .top-button {
            display: block;
    width: 120px;
    height: 120px;
    right: 0px;
    bottom: 340px;
      }
    }
  }
}
</style>