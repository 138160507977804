<template>
  <div class="m-context effect-contact">
    <h3 class="section-title">Contact</h3>
    <div class="contact">
      <div class="info">
        <div class="article">
          <h6 class="title">Subject</h6>
          <p class="kor">전략적 제휴, 서비스 문의, 의료기관 인증 기술 컨설팅, 인터뷰 문의</p>
          <p class="eng">Strategic alliance, Service inquiry,</p>
<p class="eng">Medical institution certification technology consulting, Interview</p>
        </div>
        <div class="article">
          <h6 class="title">Manager</h6>
          <p class="kor">디지털헬스케어연구소 이 형 래 수석연구원</p>
          <p class="eng">Hyunglae Lee (Chief Researcher, Digital Healthcare Research Center)</p>
        </div>
        <div class="article">
          <h6 class="title">E - mail</h6>
          <p class="kor">anobo@finehc.ai</p>
        </div>
        <div class="article half first">
          <h6 class="title">Tel.</h6>
          <p class="kor">02-565-2920</p>
        </div>
        <div class="article half">
          <h6 class="title">Fax.</h6>
          <p class="kor">02-518-4958</p>
        </div>
      </div>
      <div class="message">
        <form class="gform" method="POST" data-email="naddana2@gmail.com"
          action="https://script.google.com/macros/s/AKfycbxu_6j_mPL8el_n86LOaQgqP51uamiTBiXhM96OBSqBA0o_cUKnyHZb/exec"
          target="hidden-form">
          <div class="article-wrap">
            <div class="article">
              <input name="name" v-model="message.name" placeholder="Name" />
            </div>
            <div class="article">
              <input name="email" v-model="message.email" placeholder="Email" />
            </div>
            <div class="article content">
              <input name="message" v-model="message.message" placeholder="Message" />
            </div>
          </div>
          <IFRAME style="display:none" name="hidden-form"></IFRAME>
          <div class="btn-wrap">
        <button type="submit" @click="submitMessage()">Send Message</button>
      </div>
        </form>
        
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        message: {
          name: "",
          email: "",
          message: ""
        }
      };
    },
    created() {},
    mounted() {
      this.setContact();
    },
    methods: {
      initContact() {
         document.querySelector(".effect-contact").classList.remove("ani-on");
      },
      setContact() {
         document.querySelector(".effect-contact").classList.add("ani-on");
      },
      initArray() {
        this.message.name = '';
        this.message.email = '';
        this.message.message = '';
      },

      submitMessage() {
        alert("전송이 완료되었습니다.");
        setTimeout(() => {
          this.initArray();
        }, 1500);

      }
    },
    computed: {},
    components: {},
  };
</script>
<style lang="scss" scoped>
  .m-context {
    padding: 160px 0 90px;
    &.effect-contact {
      &.ani-on {
      h3.section-title {
        animation: slideUp 0.9s cubic-bezier(0.65, 0, 0.35, 1) both;
        -webkit-animation: slideUp 0.9s cubic-bezier(0.65, 0, 0.35, 1) both;
        }
        .contact {
          .info {
            .article {
              &:nth-of-type(1) {
                 animation: slideUp 1.0s cubic-bezier(0.65, 0, 0.35, 1) both;
                 -webkit-animation: slideUp 1.0s cubic-bezier(0.65, 0, 0.35, 1) both;
              }
              &:nth-of-type(2) {
                animation: slideUp 1.1s cubic-bezier(0.65, 0, 0.35, 1) both;
                 -webkit-animation: slideUp 1.1s cubic-bezier(0.65, 0, 0.35, 1) both;
              }
              &:nth-of-type(3) {
                animation: slideUp 1.2s cubic-bezier(0.65, 0, 0.35, 1) both;
                 -webkit-animation: slideUp 1.2s cubic-bezier(0.65, 0, 0.35, 1) both;
              }
              &:nth-of-type(4), &:nth-of-type(5) {
                animation: slideUp 1.3s cubic-bezier(0.65, 0, 0.35, 1) both;
                 -webkit-animation: slideUp 1.3s cubic-bezier(0.65, 0, 0.35, 1) both;
              }
            }
          }
          .message {
             animation: slideUp 1.0s cubic-bezier(0.65, 0, 0.35, 1) both;
                 -webkit-animation: slideUp 1.0s cubic-bezier(0.65, 0, 0.35, 1) both;
          }
        }
    }
    }
    
    
    h3.section-title {
      @include initfont($font-size:40px, $line-height:70px, $font-family:'Poppins-Bold', $color:#292a2b, $text-align:left);
      margin: 0 0 59px 0;
    }

    .contact {
      display: flex;
      align-items: center;

      .info {
        width: calc(100% - 720px);
        margin: 0 180px 0 0;
        display: flex;
        flex-wrap: wrap;

        .article {
          margin: 24px 0 0 0;
          width: 100%;

          &.half {
            width: calc(50% - 5px);
            margin: 24px 0 0 10px;

            &.first {
              margin: 24px 0 0 0;
            }
          }

          &:first-of-type {
            margin: 0;
          }

          h6.title {
            @include initfont($font-size:18px, $font-family:'Poppins-SemiBold', $color:#292a2b, $line-height: 24px, $text-align: left, $letter-spacing: -0.54px);
            padding: 0 0 10px 0;
            border: 0px solid #292a2b;
            border-width: 0 0 1px 0;
            margin: 0 0 10px 0;
          }

          p {
            &.kor {
              @include initfont($font-size:18px, $font-family:'AppleSDGothicNeoR00', $color:#292a2b, $line-height: 24px, $text-align: left, $letter-spacing: -0.54px);
              margin: 0 0 8px 0;
            }

            &.eng {
              @include initfont($font-size:16px, $font-family:'AppleSDGothicNeoR00', $color:#8d8f91, $line-height: 20px, $text-align: left, $letter-spacing: -0.48px);
            }
          }
        }
      }

      .message {
        width: 540px;
        .article-wrap {
          margin: 0 0 20px 0;
          .article {
            margin: 20px 0 0 0;
            width: inherit;
            height: 50px;
            position: relative;
            border: solid 1px #d7dadc;
            overflow: hidden;

            &.content {
              height: 191px;
            }

            &:first-of-type {
              margin: 0;
            }

            input {
              padding: 12px 16px;
              width: 100%;
              position: relative;
              background: #fff;
              @include initfont($font-size:18px, $font-family:'Poppins-Regular', $color:#8d8f91, $line-height: 24px, $text-align: left, $letter-spacing: -0.54px);

              &::placeholder {}
            }
          }
        }
        .btn-wrap {
          width: 100%;
          height: 50px;
          border-radius: 2px;
          box-shadow: 0 3px 20px 0 rgba(112, 116, 120, 0.3);
          background-color: #0e3056;
          overflow: hidden;
          &:hover {
            background-color: #292a2b;
          }
          button {
            border: 0;
            width: inherit;
            height: inherit;
            cursor:pointer;
            @include initfont($font-size:18px, $font-family:'Poppins-SemiBold', $color:#fff, $line-height: 24px );
            background: transparent;
          }
        }
      }
    }
  }
  @media screen and (min-width: 1025px) and (max-width: 1299px) {

  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .m-context {
      h3.section-title{
        margin: 0 0 20px 0;
      }
      .contact {
        flex-direction: column;
        .info {
          margin: 0 0 100px auto;
          width: calc(100% - 280px);
          .article {
            p.kor {
              margin: 0 0 5px 0;
            }
          }
        }
        .message {
          width: calc(100% - 280px);
          margin: 0 0 0 auto;
          .article-wrap {
            .article {
              input {
                padding:12px 15px;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 610px) and (max-width: 768px) {
    .m-context {
      h3.section-title{
        margin: 0;
        font-size: 30px;
        line-height: 40px;
        position: absolute;
      }
      .contact {
        flex-direction: column;
        padding: 10px 0 0 0;
        .info {
          margin: 0 0 40px auto;
          width: calc(100% - 200px);
          .article {
            margin: 16px 0 0 0;
            h6.title {
              padding: 0 0 8px 0;
              margin: 0 0 8px 0;
              font-size:16px;
              line-height: 21px;
            }
            p {
              &.kor {
                margin: 0 0 5px 0;
                font-size:16px;
              line-height: 21px;
              }
              &.eng {
                 font-size:16px;
              line-height: 21px;
              }
            }
          }
        }
        .message {
          width: calc(100% - 200px);
          margin: 0 0 0 auto;
          .article-wrap {
            .article {
              input {
                padding:12px 15px;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 609px) {
    .m-context {
      h3.section-title{
        margin: 0 0 20px 0;
        font-size: 30px;
        line-height: 40px;
      }
      .contact {
        flex-direction: column;
        padding: 0;
        .info {
          margin: 0 0 40px auto;
          width: 100%;
          .article {
            margin: 15px 0 0 0;
            h6.title {
              padding: 0 0 8px 0;
              margin: 0 0 8px 0;
              font-size:15px;
              line-height: 21px;
            }
            p {
              &.kor {
                margin: 0 0 5px 0;
                font-size:15px;
              line-height: 21px;
              }
              &.eng {
                 font-size:16px;
              line-height: 21px;
              }
            }
          }
        }
        .message {
          width: 100%;
          margin: 0 0 0 auto;
          .article-wrap {
            .article {
              input {
                padding:12px 15px;
              }
            }
          }
        }
      }
    }
  }
</style>
