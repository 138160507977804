<template>
  <div class="m-context effect-news" :class="{relativeLayout: isPlusMode}">
    <h3 class="section-title" v-text="titleName"></h3>
    <div class="ab-box" :class="{isEng: view.isEng}">
      <div class="card-wrap">
        <router-link v-if="newsType === 1" to="/news" class="action">View all NEWS<img src="@assets/images/main/main_viewallnews.svg" />
        </router-link>
        <div class="card" v-for="(news, index) in list" :key="index" @click="goNews(news.link)">
          <div class="c-img-wrap">
            <img :src="require(`@/assets/images/news/${news.img}`)" />
          </div>
          <div class="c-content">
            <h5 v-html="computedLine(news.content[0])"></h5>
            <p class="media">{{news.content[1]}}</p>
            <p>{{news.content[2]}}</p>
          </div>
        </div>
      </div>
      <div class="more" @click="moreList(button.isHide)" v-if="button.isVisible" :class="{isSpread: button.isHide}">
        <div class="icon-wrap">
        </div>
        <button type="button" v-text="button.name"></button>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      newsList: {
        type: Array
      },
      isPlusMode: {
        type: Boolean,
        default: false
      },
      newsType: {
        type: Number,
        default: 0
      }
    },
    data() {
      return {
        list: [],
        plusList: [],
        button: {
          isHide: false,
          name: 'more',
          isVisible: false
        },
        view: {
          isEng: false
        },
        titleName: ""
      };
    },
    created() {
      this.initNewsList(Number(sessionStorage.getItem('languageType')));
      this.checkPlusMode();
      this.makeTitle();
    },
    mounted() {
      if (this.newsType === 0) {
        this.setNews();
      }
    },
    components: {},
    computed: {
      computedLine() {
        return text => {
          if (text.indexOf("\n") !== -1) {
            return text.split("\n").join("<br />");
          } else {
            return text;
          }
        };
      },
    },
    methods: {
      initNews() {
         document.querySelector(".effect-news").classList.remove("ani-on");
      },
      setNews() {
         document.querySelector(".effect-news").classList.add("ani-on");
      },
      makeTitle() {
        if (this.newsType === 1) {
          this.titleName = 'Fine News'
        } else {
          this.titleName = 'News'
        }
      },
      checkPlusMode() {
        if (this.isPlusMode) {
          this.button.isVisible = true;
        }
      },
      initArray() {
        this.list = [];
        this.plusList = [];
      },
      moreList(condition) {
        if (condition) {
          this.initNewsList(Number(sessionStorage.getItem('languageType')));
          this.button.isHide = false;
          this.button.name = 'more';
        } else {
          let material;
          for (let i = 0; i < this.plusList.length; i++) {
            material = {
              img: this.plusList[i].img,
              link: this.plusList[i].link,
              content: this.plusList[i].content
            }
            this.list.push(material);
          }
          this.button.isHide = true;
          this.button.name = 'hide';
        }
      },
      isEng(languageType) {
        this.initArray();
        let material
        if (languageType) {
          for (let i = 0; i < this.newsList.length; i++) {
            if (i < 9) {
              material = {
                img: this.newsList[i].img,
                link: this.newsList[i].link,
                content: this.newsList[i].contentEng
              }
              this.list.push(material);
            } else {
              material = {
                img: this.newsList[i].img,
                link: this.newsList[i].link,
                content: this.newsList[i].contentEng
              }
              this.plusList.push(material);
            }
          }
          this.view.isEng = true;
        } else {
          for (let i = 0; i < this.newsList.length; i++) {
            if (i < 9) {
              material = {
                img: this.newsList[i].img,
                link: this.newsList[i].link,
                content: this.newsList[i].contentKor
              }
              this.list.push(material);
            } else {
              material = {
                img: this.newsList[i].img,
                link: this.newsList[i].link,
                content: this.newsList[i].contentKor
              }
              this.plusList.push(material);
            }
          }
          this.view.isEng = false;
        }
        if (this.button.isHide) {
          this.moreList(false);
        }
      },
      initNewsList(languageType) {
        this.initArray();
        let material;
        if (languageType === 0) {
          for (let i = 0; i < this.newsList.length; i++) {

            if (i < 9) {
              material = {
                img: this.newsList[i].img,
                link: this.newsList[i].link,
                content: this.newsList[i].contentKor
              }
              this.list.push(material);
              
            } else {
              material = {
                img: this.newsList[i].img,
                link: this.newsList[i].link,
                content: this.newsList[i].contentKor
              }
              this.plusList.push(material);
             
            }
          }
      
          this.view.isEng = false;
        } else {
          for (let i = 0; i < this.newsList.length; i++) {
            if (i < 9) {
              material = {
                img: this.newsList[i].img,
                link: this.newsList[i].link,
                content: this.newsList[i].contentEng
              }
              this.list.push(material);
            } else {
              material = {
                img: this.newsList[i].img,
                link: this.newsList[i].link,
                content: this.newsList[i].contentEng
              }
              this.plusList.push(material);
            }
          }
          this.view.isEng = true;
        }
      },
      goNews(href) {
        window.open(
          href,
          "_blank"
        );
      }
    },
    unmounted() {},
  };
</script>
<style lang="scss" scoped>
  @import "@assets/css/common";

  .m-context {
    padding: 156px 0 360px;
    &.effect-news {
      &.ani-on {
      h3.section-title {
           animation: slideUp 0.9s cubic-bezier(0.65, 0, 0.35, 1) both;
          -webkit-animation: slideUp 0.9s cubic-bezier(0.65, 0, 0.35, 1) both;
      }
      .ab-box {
        .card-wrap {
          a.action {
            animation: slideUp 0.9s cubic-bezier(0.65, 0, 0.35, 1) both;
          -webkit-animation: slideUp 0.9s cubic-bezier(0.65, 0, 0.35, 1) both;
          }
          .card {
            animation: slideUp 0.9s cubic-bezier(0.65, 0, 0.35, 1) both;
          -webkit-animation: slideUp 0.9s cubic-bezier(0.65, 0, 0.35, 1) both;
          }
        }
      }
    } 
    }
    &.relativeLayout {
      padding: 160px 0 200px;

      .ab-box {
        position: relative;
        height: auto;
        z-index: 0;
        margin: 0 auto;
      }
    }
  }

  h3.section-title {
    @include initfont($font-size:40px, $line-height:70px, $font-family:'Poppins-ExtraBold', $color:#292a2b, $text-align:left);
    margin: 0 0 78px 0;
  }

  .ab-box {
    position: absolute;
    width: 1160px;
    height: 400px;
    z-index: 0;

    &.isEng {
      .card-wrap {
        .card {
          .c-content {

            h5,
            p {
              font-family: "Poppins-Regular";
            }
          }
        }
      }
    }

    .more {
      position: relative;
      width: 260px;
      height: 50px;
      border-radius: 25px;
      border: 1px solid #d7dadc;
      margin: 0 auto;
      background: transparent;

      &.isSpread {
        .icon-wrap {
          background-image: url("~@assets/images/unit/icon_more_hide.svg");
        }

        &:hover {
          .icon-wrap {
            background-image: url("~@assets/images/unit/icon_more_hide_hover.svg");
          }
        }
      }

      &:hover {
        background: #292a2b;
        border: 1px solid #292a2b;

        .icon-wrap {
          background-image: url("~@assets/images/unit/icon_more_white.svg");
          /**
          background-image: url("~@assets/images/unit/icon_more_hide.svg");
           */
        }

        button {
          color: #fff;
        }
      }

      .icon-wrap {
        position: absolute;
        background-image: url("~@assets/images/unit/icon_more.svg");
        width: 24px;
        height: 24px;
        margin: 0 5px 0 0;
        left: calc(50% - 36px);
        top: calc(50% + 1.5px);
        transform: translate(-50%, -50%);
      }

      button {
        @include initfont($font-size:18px, $font-family:'AppleSDGothicNeoB00', $color:#8d8f91, $line-height: 24px);
        background: transparent;
        padding: 0;
        border: 0;
        width: inherit;
        height: inherit;
        cursor: pointer;
      }

    }
  }

  .card-wrap {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 100px 0;

    a.action {
      position: absolute;
      @include initfont($font-size:18px, $line-height:24px, $font-family:'Poppins-Regular', $color:#8d8f91, $text-align:left);
      right: 0;
      top: -42px;
      display: block;
      cursor: pointer;

      &:hover {
        &:after {
          content: "";
          width: 124px;
          height: 1px;
          background: #8d8f91;
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }
    }

    .card {
      border-radius: 10px;
      box-shadow: 0 3px 20px 0 rgba(112, 116, 120, 0.16);
      background-color: #ffffff;
      width: calc((100% / 3) - 12px);
      margin-left: 18px;
      overflow: hidden;
      cursor: pointer;
      border: 2px solid transparent;

      &:hover {
        border: 2px solid #114b8d;
        box-shadow: 0 3px 20px 0 rgba(112, 116, 120, 0.4);

        .c-img-wrap {
          img {
            transform: scale(1.2);
          }
        }
      }

      .c-img-wrap {
        height: 244px;
        background: #8d8f91;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        img {
          transition: transform .5s;
          max-width: 100%;
          max-height: 244px;
        }
      }

      .c-content {
        background: #fff;
        padding: 20px;

        h5 {
          @include initfont($font-size:18px, $line-height:24px, $font-family:'AppleSDGothicNeoB00', $color:#292a2b, $text-align:left);
          margin: 0 0 16px 0;
          height: 48px;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          word-break: break-word;
        }

        p {
          @include initfont($font-size:16px, $line-height:21px, $font-family:'AppleSDGothicNeoR00', $color:#8d8f91, $text-align:left);

          &.media {
            margin: 0 0 4px 0;
            color: #8d8f91;
            font-family: 'AppleSDGothicNeoSB00';
            font-size: 16px;
            line-height: 22px;
          }
        }
      }
    }
  }

  .section {
    &.news {
      h3.section-title {
        margin: 0 0 20px 0;
      }
    }
  }

  @media screen and (min-width: 1025px) and (max-width: 1299px) {
    #app {
      .news {
        .m-context {
          width: calc(100% - 200px);

          .ab-box {
            width: 100%;
          }
        }
      }

      .ab-box {
        width: calc(100% - 200px);
      }
    }
  }

  @media screen and (max-width: 1024px) {
    #app {
      .news {
        .m-context {
           padding: 180px 0 40px 0;
          h3.section-title {
            display: none;
          }

          .ab-box .card-wrap {
            a.action {
              display: none;
            }
          }
        }
      }

    }

  }

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    #app {
      .news {
        .m-context {
          width: calc(100% - 200px) !important;

          .ab-box {
            width: 100%;

            .more {
              margin: 100px auto 0 auto;
            }
          }
        }
      }

      .m-context {
        max-width: 100%;
        width: calc(100% - 112px) !important;
      }

      .ab-box {
        width: 100%;
      }
    }

    .ab-box {
      .card-wrap {
        margin: 0;

        a.action {}

        .card {
          margin-left: 12px;

          .c-content {
            h5 {
              font-size: 16px;
              line-height: 22px;
              margin: 0 0 12px 0;
            }

            p {
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 769px) {
    .card-wrap {
      .card {
        &:nth-of-type(3n+1) {
          margin-left: 0 !important;
        }

        &:nth-of-type(n+4) {
          margin-top: 40px;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    #app {
      .ab-box {
        height: auto;
      }
    }

    .card-wrap {
      .card {
        width: calc((100% / 2) - 20px);

        &:nth-of-type(2n-1) {
          margin-left: 0 !important;
        }

        &:nth-of-type(n+3) {
          margin-top: 40px;
        }
      }
    }
  }

  @media screen and (min-width: 610px) and (max-width: 768px) {
    #app {
      .news {
        .m-context {
          .ab-box {
            .more {
              margin: 100px auto 0 auto;
            }
          }
        }
      }

      .m-context {
        h3.section-title {
          text-align: center;
          margin: 0;
        }
      }

      .ab-box {
        width: calc(100% - 112px);
      }
    }

    .ab-box {
      .card-wrap {
        margin: 0;

        a.action {
          width: 100%;
          text-align: center;
          top: 0;
          position: relative;
          margin: 0 0 62px 0;
        }

        .card {
          width: calc((100% / 2) - 20px);
          margin-left: 40px;

          &:nth-of-type(2n-1) {
            margin-left: 0 !important;
          }

          &:nth-of-type(n+3) {
            margin-top: 40px;
          }

          .c-img-wrap {
            height: 205px;
          }

          .c-content {
            h5 {
              font-size: 16px;
              line-height: 20px;
              margin: 0 0 12px 0;
              height: auto;
              min-height: 40px;
              br {
                display: none;
              }
            }

            p {
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
      }
    }
  }



  @media screen and (max-width: 609px) {
    #app {
      .news {
        .m-context {
          width: calc(100% - 152px) !important;
          padding: 130px 0 40px 0;

          .ab-box {
            width: 100%;
            height: auto;
            position: relative;

            .card-wrap {
              margin: 0 auto;
              max-width: 266px;

              .card {
                &:nth-of-type(3) {
                  display: none;
                }
              }
            }

            .more {
              margin: 40px auto 0 auto;
            }
          }
        }
      }

      .section.inBg {
        .m-context {
          width: calc(100% - 152px) !important;

          h3.section-title {
            margin: 0 !important;
            text-align: center;
          }
        }

        .ab-box {
          width: 100%;
          height: auto;
          position: relative;

          .card-wrap {
             margin: 0 auto;
              max-width: 266px;
            .card {
              &:nth-of-type(3) {
                display: none;
              }
            }
          }
        }
      }
    }

    .ab-box {
      .card-wrap {
        margin: 0;

        a.action {
          width: 100%;
          text-align: center;
          top: 0;
          position: relative;
          margin: 0 0 30px 0;
        }

        .card {
          margin-left: 0;
          width: 100%;

          &:nth-of-type(n+2) {
            margin: 20px 0 0 0;
          }

          .c-img-wrap {
            height: 174px;
          }

          .c-content {
            height: auto;
            padding: 13px;

            h5 {
              font-size: 16px;
              line-height: 20px;
              margin: 0 0 12px 0;
              height: auto;
            }

            p {
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
</style>