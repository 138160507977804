<template>
  <div class="header-box">
    <div class="bg-cleaner" :class="{active: navStatus === 1}"></div>
    <div class="header-wrap" @mouseleave="navLeave" :class="{active: navStatus === 1, deactive: navStatus === 2}">
      <div class="header">
        <div class="logo">
          <router-link to="/">
            <img src="@assets/images/header/header_logo_color.svg" />
          </router-link>
        </div>
        <div class="nav-context" @mouseover="navTrans(1)">
          <ul class="navigator">
            <li class="menu" v-for="(menu, index) in menuList" :key="index + 'A'">
              <router-link class="title" :to="'/' + menu.link[0]">{{menu.title}}</router-link>
            </li>
          </ul>
        </div>
           <router-link class="prev-action" :to="'/' + prevDirection">
          <img src="@assets/images/header/header_back_r.svg" />
          </router-link>
        <h3 class="title" v-text="titleName"></h3>
        <div class="h-action">
          <ul class="trans">
            <li @click="transLanguage()" :class="{selected: languageType === 0}">KOR</li>
            <li @click="transLanguage()" :class="{selected: languageType === 1}">ENG</li>
          </ul>
          <div class="spread-call" @click="isSpreadMenu(true)">
            <img src="@assets/images/header/header_menu.svg" />
          </div>
        </div>
        <div class="spread" :class="{active:isSpread}">
          <!--
        <div class="close" @click="isSpreadMenu(false)">
          <img src="@assets/images/header/header_icon_close.svg" />
        </div>
        -->
          <div class="bg-right" v-if="resizeType === 2 || resizeType === 3">
            <img src="@assets/images/header/menu_img_bg_right.png" />
          </div>
          <div class="bg-left" v-if="resizeType === 2 || resizeType === 3">
            <img src="@assets/images/header/menu_img_bg_left.png" />
          </div>
          <div class="bg-left" v-if="resizeType === 1">
            <img src="@assets/images/header/768_menu_img_bg_left.svg" />
          </div>
          <div class="bg-left" v-if="resizeType === 0">
            <img src="@assets/images/header/414_menu_img_bg_left.svg" />
          </div>
          <div class="s-header">
            <div class="logo" @click="goHome()">
              <img src="@assets/images/header/header_logo_white.svg" />
            </div>
            <div class="action">
              <ul class="language">
                <li @click="transLanguage()" :class="{selected: languageType === 0}">KOR</li>
                <li @click="transLanguage()" :class="{selected: languageType === 1}">ENG</li>
              </ul>
              <div class="close" @click="isSpreadMenu(false)">
                <img src="@assets/images/header/header_icon_close.svg" />
              </div>
            </div>
          </div>
          <ul class="s-menu">
            <li class="item" v-for="(menu, index) in menuList" :key="index + 'B'" @click="sidemenuClicked()">
              <router-link class="i-title" :to="'/' + menu.link[0]">{{menu.title}}</router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="nav-detail">
        <div class="h-context">
          <ul class="nav" v-for="(navMenu, meunIndex) in menuList" :key="meunIndex">
            <li class="n-item" v-for="(navItem, itemIndex) in menuList[meunIndex].child" :key="itemIndex"
              @click="naviClick(meunIndex,itemIndex)">
              <router-link :to="'/' + menuList[meunIndex].link[itemIndex]">{{navItem}}</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      navType: {
        type: Number,
        default: 0
      },
      menuList: {
        type: Array
      },
      pageType: {
        type: Number,
         default: 0
      }
    },
    data() {
      return {
        languageType: null,
        isSpread: false,
        navStatus: 0,
        titleName: "",
        prevDirection: "",
        resizeType: 3
      };
    },
    created() {
      this.initLanguage();
      this.setPage();
    },
    mounted() {
      this.getResizeType();
      window.addEventListener('resize', this.getResizeType);
    },
     unmounted() {
      window.removeEventListener('resize', this.getResizeType);
    },
    methods: {
      goHome() {
        this.$router.push("/");
      },
      sidemenuClicked() {
        this.isSpread = false;
      },
      getResizeType() {
        this.width = window.outerWidth;
        this.height = window.outerHeight;
        if (window.outerWidth < 610) {
          this.resizeType = 0;
          this.$emit('resize-type', 0);
        } else if (window.outerWidth < 769) {
          this.resizeType = 1;
          this.$emit('resize-type', 1);
        } else if (window.outerWidth < 1025) {
          this.resizeType = 2;
          this.$emit('resize-type', 2);
        } else {
          this.resizeType = 3;
          this.$emit('resize-type', 3);
        }
      },
      setPage() {
        if (this.pageType === 0) {
          this.titleName = "About us";
          this.prevDirection = ""
        } else if (this.pageType === 1) {
          this.titleName = "Telederm Units";
          this.prevDirection = "about"
        } else if (this.pageType === 2) {
          this.titleName = "Telederm Units";
          this.prevDirection = "telederm"
        } else if (this.pageType === 3) {
          this.titleName = "Fine News";
          this.prevDirection = "platform"
        } else if (this.pageType === 4) {
          this.titleName = "Contact Us";
          this.prevDirection = "news"
        }
      },
      naviClick(parentOrder, childOrder) {
        if (parentOrder === 0) {
          this.$emit('about-nav-clicked', childOrder);
        } else if (parentOrder === 1) {
          this.$emit('telederm-nav-clicked', childOrder);
        } else if (parentOrder === 2) {
          this.$emit('bigdata-nav-clicked', childOrder);
        } else if (parentOrder === 3) {
          this.$emit('news-nav-clicked', childOrder);
        } else if (parentOrder === 4) {
          this.$emit('contact-nav-clicked', childOrder);
        }
      },
      isSpreadMenuByChild(condition) {
        if (condition) {
          this.isSpread = true;
        } else {
          this.isSpread = false;
        }
      },
      isSpreadMenu(condition) {
        console.log("스프레드 클릭");
        if (condition) {
          this.isSpread = true;
          if (this.resizeType === 2 || this.resizeType === 3) {
             document.querySelector(".bg-right").style.WebkitAnimation = "rightMove ease-in-out 2.5s"
             document.querySelector(".bg-right").style.animation = "rightMove ease-in-out 2.5s"
          }
          document.querySelector(".bg-left").style.WebkitAnimation = "leftMove ease-in-out 2.5s"
          document.querySelector(".bg-left").style.animation = "leftMove ease-in-out 2.5s"
          this.$emit("sideMenu-call", true);
        } else {
          this.isSpread = false;
           if (this.resizeType === 2 || this.resizeType === 3) {
          document.querySelector(".bg-right").style.WebkitAnimation = "none"
          document.querySelector(".bg-right").style.animation = "none"
           }
          document.querySelector(".bg-left").style.WebkitAnimation = "none"
          document.querySelector(".bg-left").style.animation = "none"
          this.$emit("sideMenu-call", false);
        }
      },
      initLanguage() {
        if (sessionStorage.getItem("languageType") === null) {
          sessionStorage.setItem("languageType", "0");
          this.languageType = 0;
          this.$emit("currentLanguageType", 0);
        } else {
          if (sessionStorage.getItem('languageType') === '0') {
            this.languageType = 0;
          } else {
            this.languageType = 1;
            this.$emit("currentLanguageType", 1);
          }
        }
      },
      transLanguage() {
        if (this.languageType === 0) {
          this.languageType = 1;
          sessionStorage.setItem("languageType", "1");
          this.$emit("currentLanguageType", 1);
        } else {
          this.languageType = 0;
          sessionStorage.setItem("languageType", "0");
          this.$emit("currentLanguageType", 0);
        }
      },
      navTrans(type) {
        this.navStatus = type;
      },
      navLeave() {
        if (this.navStatus !== 2) {
          this.navStatus = 0;
        }
      },
    },
    computed: {},
    components: {},
  };
</script>
<style lang="scss" scoped>
  .header-box {
    padding: 0;

    .bg-cleaner {
      &.active {
        position: fixed;
        background: rgba(112, 116, 120, 0.1);
        width: 100%;
        height: 100%;
        z-index: 2;
      }
    }
  }

  .header-wrap {
    position: absolute;
    background: #fff;
    z-index: 2;
    width: 100%;
    transition: display .5s ease-in-out, height .5s ease-in-out;
    height: 100px;
    border-bottom: 1px solid #eef1f3;

    &.deactive {
      display: none;
    }

    &.active {
      overflow: hidden;
      height: 310px;
      background: #fff;

      ul.navigator li.currented {
        &:before {
          display: none;
        }
      }

      .nav-detail {
        display: block;

        .h-context {
          ul.nav {
            border: 0px solid #eef1f3;
            border-width: 0px 1px 0px 0;

            &:first-of-type {
              border-width: 0px 1px 0px 1px;
            }

            li {
              padding: 10px 0 0 0;

              &:first-of-type {
                padding: 0;
              }
            }
          }
        }
      }
    }

    .header {
      display: flex;
      position: relative;
      max-width: 1720px;
      margin: 0 auto;
      justify-content: space-between;
      height: 100px;
      align-items: center;
      border-bottom: 1px solid transparent;

      .logo {
        cursor: pointer;

        img {
        }
      }
       h3.title {
        display: none;
        @include initfont($font-size:20px, $line-height:28px, $font-family:'Poppins-SemiBold', $color:#292a2b);
      }
      .prev-action {
        display: none;
      }
      .h-action {
        display: flex;

        ul.trans {
          display: flex;
          margin: 0 34px 0 0;
          align-items: center;

          li {
            position: relative;
            margin: 0 0 0 24px;
            @include initfont($font-size:15px, $line-height:21px, $font-family:'Poppins-Regular', $color:#8d8f91);
            cursor: pointer;

            &.selected {
              color: #292a2b;
            }

            &:first-of-type {
              margin: 0;

              &:after {
                content: "";
                position: absolute;
                right: -11.5px;
                height: 13px;
                width: 2px;
                background: #292a2b;
                top: 4px;
              }
            }


          }
        }

        .spread-call {
          cursor: pointer;

          img {}
        }
      }

      .nav-context {
        max-width: 1028px;
        margin: 0 auto;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        ul.navigator {
          display: flex;
          position: relative;

          li {
            min-width: 206px;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            a {
              @include initfont($font-size:17px, $line-height:24px, $font-family:'Poppins-Regular', $color:#292a2b);
              display: block;
              text-align: center;
            }

            &.currented {
              &:before {
                content: "";
                position: absolute;
                width: 135px;
                left: 50%;
                transform: translateX(-50%);
                bottom: -1.5px;
                height: 3px;
                background: #292a2b;
                z-index: 2;
              }
            }

            &:hover {
              a {
                font-family: 'Poppins-SemiBold';
              }

              &:after {
                content: "";
                position: absolute;
                width: 135px;
                left: 50%;
                transform: translateX(-50%);
                bottom: -2.5px;
                height: 3px;
                background: #292a2b;
                z-index: 2;
              }
            }
          }
        }
      }
    }

    .nav-detail {
      display: none;
      border-top: 1px solid #eef1f3;

      .h-context {
        max-width: 1028px;
        margin: 0 auto;
        display: flex;

        ul.nav {
          border: 0px solid #eef1f3;
          border-width: 0px 1px 0px 0;
          height: 210px;
          position: relative;
          padding: 18.5px 0 0 0;
          min-width: 206px;
          width: 206px;

          &:first-of-type {
            border-width: 0px 0px 0px 1px;
          }

          li {
            width: 100%;

            &:hover {
              a {
                font-family: 'Poppins-SemiBold';
              }

              &:after {
                content: "";
                position: absolute;
                width: 135px;
                left: 50%;
                transform: translateX(-50%);
                top: -2.5px;
                height: 3px;
                background: #292a2b;
                z-index: 3;
              }
            }

            a {
              @include initfont($font-size:16px, $line-height:23px, $font-family:'Poppins-Regular', $color:#292a2b);
              display: block;
              text-align: center;
            }
          }
        }
      }
    }
    /** 시작 */
    .spread {
      width: 100%;
      height: 100%;
      position: fixed;
      background: linear-gradient(to bottom right, #0B1A2E 0%, #002657 100%) 100% no-repeat;
      right: -100%;
      top: 0;
      z-index: 4;
      overflow: hidden;
      transition: .3s linear;

      &.active {
        right: 0;
      }

      .bg-right {
        position: absolute;
        right: 0;
        top: 0;

        img {}
      }

      .bg-left {
        position: absolute;
        left: 0;
        bottom: 0;

        img {}
      }

      .s-header {
        display: flex;
        justify-content: space-between;
        max-width: 1720px;
        margin: 0 auto;
        height: 100px;
        align-items: center;

        .logo {
          img {}
        }

        .action {
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 166px;

          .close {
            cursor: pointer;

            img {}
          }

          ul.language {
            display: flex;

            li {
              position: relative;
              margin: 0 0 0 24px;
              @include initfont($font-size:15px, $line-height:21px, $font-family:'Poppins-Regular', $color:#8d8f91);
              cursor: pointer;

              &:first-of-type {
                margin: 0;

                &:after {
                  content: "";
                  position: absolute;
                  right: -11.5px;
                  height: 13px;
                  width: 2px;
                  background: #eef1f3;
                  top: 4px;
                }
              }

              &.selected {
                color: #fff;

                &:after {
                  background: #fff;
                }
              }
            }
          }
        }
      }

      .s-menu {
        width: 1160px;
        position: absolute;
        margin: 0 auto;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 50%;

        .item {
          margin: 50px 0 0 0;
          position: relative;
          width: fit-content;

          &:after {
            content: "";
            position: absolute;
            bottom: 10px;
            left: 0;
            width: 0;
            height: 2px;
            transition: width .3s ease-in-out;
            background: #fff;
            opacity: 0;
          }

          &:hover {
            &:after {
              width: 100%;
              opacity: 1;
            }
          }

          .i-title {
            @include initfont($font-size:50px, $line-height:71px, $font-family:'Poppins-SemiBold', $color:#fff, $letter-spacing: -0.5px);
            font-weight: 600;
          }

          &:first-of-type {
            margin: 0;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1799px) {
    .header-wrap {
      .spread {
        .s-header {
          padding: 0 100px;
        }
      }
    }
  }

  @media screen and (max-width: 1299px) {
    .header-wrap {
      height: 110px;

      .header {
        max-width: 100%;
        padding: 0 25px;
        justify-content: space-between;
        height: 110px;

        .logo {
          img {}
        }

        .nav-context {
          display: none;
        }

        .h-action {
          ul.trans {
            display: none;
          }

          .spread-call {
            img {
              max-width: 40px;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 1450px) and (max-width: 1799px) {
    .header-wrap {
      height: 110px;

      &.active {
        height: 300px;
      }

      .header {
        max-width: 100%;
        padding: 0 40px;
        justify-content: flex-start;
        height: 110px;

        .logo {
          img {
            max-width: 105px;
          }
        }

        .nav-context {
          position: relative;
          left: inherit;
          transform: inherit;
          width: calc(100% - 271px);
          max-width: 100%;
          ul.navigator {
            li.menu {
              min-width: inherit;
              width: calc(100% / 5);
              height: 110px;

              &:hover:after {}

              a {
                font-size: 16px;
                line-height: 22px;
              }
            }
          }
        }
      }

      .nav-detail {
        .h-context {
          width: calc(100% - 431px);
          margin: 0 246px 0 185px;

          ul.nav {
            height: 190px;
            width: calc(100% / 5);

            ul.n-item {
              width: 100%;

              a {
                font-size: 14px;
                line-height: 19px;
              }

              &:hover:after {}
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 1200px) and (max-width: 1449px) {
    .header-wrap {
      height: 110px;

      &.active {
        height: 300px;
      }

      .header {
        max-width: 100%;
        padding: 0 25px;
        justify-content: flex-start;
        height: 110px;

        .logo {
          img {
            max-width: 90px;
          }
        }

        .nav-context {
          position: relative;
          left: inherit;
          transform: inherit;
          margin: 0 25px;
          width: calc(100% - 234px);
          max-width: 100%;
          ul.navigator {
            li.menu {
              min-width: inherit;
              width: calc(100% / 5);
              height: 110px;

              &.currented {
                position: relative;

                &:after {
                  content: "";
                  position: absolute;
                  width: 135px;
                  left: 50%;
                  transform: translateX(-50%);
                  bottom: -1.5px;
                  height: 3px;
                  background: #292a2b;
                  z-index: 2;
                }
              }

              &:hover:after {
                width: 105px;
              }

              a {
                font-size: 16px;
                line-height: 22px;
              }
            }
          }
        }

        .h-action {
          ul.trans {
            margin: 0 20px 0 0;
          }

          .spread-call {
            img {
              max-width: 40px;
            }
          }
        }
      }

      .nav-detail {
        .h-context {
          width: calc(100% - 334px);
          margin: 0 194px 0 140px;

          ul.nav {
            height: 190px;
            width: calc(100% / 5);

            li.n-item {
              width: 100%;

              a {
                font-size: 14px;
                line-height: 19px;
              }

              &:hover:after {
                width: 105px;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 1025px) and (max-width: 1399px) {
    .header-wrap {

      .spread {
        .s-header {
          padding: 0 100px;
        }

        .s-menu {
          width: 100%;
          left: calc(50% + 100px);

          .item {

            a.i-title {
              /**
              line-height: 51px;
              font-size: 34px;
               */

            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 1025px) and (max-width: 1299px) {
    .header-wrap {
      .header {
        padding: 0 100px;
        justify-content: space-between;
        .logo {
          img {
          max-width: 110px;
          }
        }
      }
    }
  }
@media screen and (max-width: 1024px) {
  .header-wrap {
    height: 100px;
    .header {
      height: 100px;
      .s-header {
        .logo {
          display: block;
        }
      }
      .logo {
        display: none;
        img {
          max-width: 90px;
        }
      }
      h3.title {
        display: block;
      }
      .prev-action {
        display: block;
      }
    }
  }
}
  @media screen and (min-width: 769px) and (max-width: 1024px) {
     .fullPage-container {
        .section {
            .m-context {
              
            }
        }
     }
    .header-wrap {
      .header {
        padding: 0 100px;
      }

      .spread {
        .s-header {
          padding: 0 56px;
        }

        .s-menu {
          width: auto;
          left: calc(50%);

          .item {
            margin: 50px auto 0;

            &:first-of-type {
              margin: 0 auto 0;
            }

            a.i-title {
              font-family: "Poppins-Medium";
              line-height: 56px;
              font-size: 40px;
              font-weight: normal;

            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 610px) and (max-width: 768px) {
    .header-wrap {
      .header {
        padding: 0 56px;
      }

      .spread {
        .s-header {
          padding: 0 40px;

          .action {
            width: auto;
          }
        }

        .s-menu {
          width: auto;
          left: calc(50%);

          .item {
            margin: 40px auto 0;

            &:first-of-type {
              margin: 0 auto 0;
            }

            a.i-title {
              font-family: "Poppins-Medium";
              line-height: 48px;
              font-size: 36px;
              font-weight: normal;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 609px) {
    .header-wrap {
      .header {
        padding: 0 28px;
      }

      .spread {
        .s-header {
          padding: 0 28px;

          .action {
            width: auto;
          }
        }

        .s-menu {
          width: auto;
          left: calc(50%);

          .item {
            margin: 30px auto 0;

            &:first-of-type {
              margin: 0 auto 0;
            }

            a.i-title {
              font-family: "Poppins-Medium";
              line-height: 43px;
              font-size: 30px;
              font-weight: normal;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
</style>