<template>
   <div class="top-button relative" :class="{active: view}">
    </div>
</template>

<script>
export default {
  data() {
    return {
      view: true
    };
  },
  created() {},
  methods: {
    hideButton() {
        window.scrollTo(0,0);
    },
    isView(condition) {
      this.view = condition;
    }
  },
  mounted() {
    document.querySelector(".top-button").addEventListener('click', () => window.scrollTo({
  top: 0,
  behavior: 'smooth',
}));
  },
  computed: {},
  components: {},
};
</script>
<style lang="scss" scoped>

.top-button {
  &.relative {
     bottom: 399px;
  }
  }

  @media screen and (max-width: 1399px) {
     #app .top-button.active {
    right: 65px;
    bottom: 340px;
    width: 145px;
    height: 145px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
     #app .top-button.active {
      right: 25px;
      width: 130px;
      height: 130px;
     }
   }
  @media screen and (max-width: 767px) {
   #app .top-button.active {
              display: block;
    width: 120px;
    height: 120px;
    right: 0px;
  }
    
}
</style>
