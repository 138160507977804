import Vue from "vue";
import VueRouter from "vue-router";
import Home from '@page/MainPage';
import FullPageMain from '@util/FullPageMain';
import FullPageSub from '@util/FullPageSub';
import About from '@page/About';
import Telederm from '@page/Telederm';
import Platform from '@page/Platform';
import Contact from '@page/Contact';
import Material from '@component/material';
import News from '@page/News';
import NotFound from '@page/NotFound';
import ssTT from '@util/ssTT';

Vue.use(VueRouter);
const routeMap = [{
    path: '/',
    component: Home,
    name: 'home',
    meta: {
      unauthorized: true
    },
  },
  {
    path: '/test',
    component: FullPageMain,
    name: 'fullPageMain',
    meta: {
      unauthorized: true
    },
  },
  {
    path: '/material/:code?',
    component: Material,
    name: 'Material',
    meta: {
      unauthorized: true
    },
  },
  {
    path: '/test2',
    component: FullPageSub,
    name: 'fullPageSub',
    meta: {
      unauthorized: true
    },
  },
    {
    path: '/test99',
    component: ssTT,
    name: 'ssTT',
    meta: {
      unauthorized: true
    },
  },
  {
    path: '/about',
    component: About,
    name: 'about',
    meta: {
      unauthorized: true
    },
  },
  {
    path: '/contact',
    component: Contact,
    name: 'contact',
    meta: {
      unauthorized: true
    },
  },
  {
    path: '/news',
    component: News,
    name: 'news',
    meta: {
      unauthorized: true
    },
  },
  {
    path: '/telederm/:code?',
    component: Telederm,
    name: 'telederm',
    meta: {
      unauthorized: true
    },
  },
  {
    path: '/platform/:code?',
    component: Platform,
    name: 'platform',
    meta: {
      unauthorized: true
    },
  },
  {
    path: '/:catchAll(.*)',
    component: NotFound,
    meta: {
      unauthorized: true
    }
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routeMap,
  linkActiveClass: "active"
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.unauthorized)) {
    next();
  }
});
export default router;
