<template>
    <div class="swiper-wrap">
      <swiper class="swiper" :options="swiperOption" ref="mySwiperRef" v-if="view.swiperOn">
        <!-- v-for -->
        <swiper-slide v-for="(swiperItem, index) in list" :key="index">
          <div class="banner">
            <div class="b-background">
              <img v-lazy="require(`@/assets/images/swiper/${swiperItem.img}`)" />
              <div class="b-content">
                <h4 class="subtitle" :class="{isEng: view.isEng}">{{swiperItem.content[0]}}</h4>
                <h2 class="title" :class="{isEng: view.isEng}">{{swiperItem.content[1]}}</h2>
                <p :class="{isEng: view.isEng}">{{swiperItem.content[2]}}</p>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <div class="custom-swiper-button-prev" @click="prevClick()">
        <img src="@/assets/images/swiper/main_icon_carousel_arrow_left.svg" />
      </div>
      <div class="custom-swiper-button-next" @click="nextClick()">
        <img src="@/assets/images/swiper/main_icon_carousel_arrow_right.svg" />
      </div>
    </div>
</template>

<script>
  import {
    Swiper,
    SwiperSlide
  } from "vue-awesome-swiper";
  import "swiper/swiper-bundle.css";
  export default {
    props: {
      swiperList: {
        type: Array
      }
    },
    data() {
      return {
        list: [],
        view: {
          swiperOn: false,
          isEng: false
        },
        swiperOption: {
          slidesPerView: 1,
          grabCursor: true,
          setWrapperSize: true,
          Height: window.outerHeight,
          resistanceRatio: 0,
          init: false,
          observeParents: true,
          autoplay: {
            delay: 152500,
            disableOnInteraction: false,
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
          }
        }
      };
    },
    mounted() {
      this.$nextTick(_ => {
        this.setSwiperOpt();
      });
    },
    computed: {},
    created() {
      this.initSwiperList(Number(sessionStorage.getItem('languageType')));
    },
    methods: {
      isEng(languageType) {
        this.view.swiperOn = false;
        if (languageType) {
          for (let i = 0; i < this.swiperList.length; i++) {
            this.list[i].content = this.swiperList[i].contentEng;
          }
          this.view.isEng = true;
        } else {
          for (let i = 0; i < this.swiperList.length; i++) {
            this.list[i].content = this.swiperList[i].contentKor;
          }
          this.view.isEng = false;
        }
        this.swiperOption.init = true;
        this.view.swiperOn = true;
      },
      initSwiperList(languageType) {
        this.list = this.swiperList;
        if (languageType === 0) {
          for (let i = 0; i < this.swiperList.length; i++) {
            this.list[i].content = this.swiperList[i].contentKor;
          }
          this.view.isEng = false;
        } else {
          for (let i = 0; i < this.swiperList.length; i++) {
            this.list[i].content = this.swiperList[i].contentEng;
          }
          this.view.isEng = true;
        }
        this.swiperOption.init = true;
        this.view.swiperOn = true;
      },
      setSwiperOpt() {
        /** document.querySelector(".swiper-wrapper").classList.add("special"); */
      },
      prevClick() {
        this.$refs.mySwiperRef.$swiper.slidePrev();
      },
      nextClick() {
        this.$refs.mySwiperRef.$swiper.slideNext();
      }
    },
    components: {
      Swiper,
      SwiperSlide
    }
  };
</script>

<style lang="scss" scoped>
  .banner {
    height: 100%;
    width: 100%;
    position: relative;

    .b-background {
      height: 100%;

      img {
        height: inherit;
      }
    }

    .b-content {
      position: absolute;
      top: 30px;

      p,
      h2,
      h4 {
        color: white;
      }
    }
  }
</style>
