<template>
  <div class="about">
    <header-component ref="headerContext" :pageType="pageType" @sideMenu-call="sideMenuCall" @contact-nav-clicked="moveSection"
      @currentLanguageType="languageTrans" :menuList="menuList"></header-component>
    <div class="menu-wrap" @click.stop :class="{deactive:!isMenu}">
      <ul id="menu" class="alone">
        <li data-menuanchor="page1" :class="{active: selectedMenu === 0}" @click="menuClick(0)"><a
            href="#page1">Contact</a></li>
        <li data-menuanchor="page2" :class="{active: selectedMenu === 1}" @click="menuClick(1)"><a
            href="#page2">Direction & map</a></li>
      </ul>
      <p class="milestone">home > Contact Us > <b>{{currentPage}}</b></p>
    </div>
    <full-page class="fullPage-container" ref="fullpage" :options="options" id="fullpage" @after-load="afterLoad"
      @on-leave="onLeave">
      <div class="section fp-auto-height-responsive in-close">
        <!-- Contact -->
        <p class="milestone inner">home > Contact Us > <b>{{currentPage}}</b></p>
        <contact ref="contactContext"></contact>
      </div>
      <div class="section bg-gray inTopButton fp-auto-height">
        <!-- Direction & Map -->
        <p class="milestone inner">home > Contact Us > <b>{{currentPage}}</b></p>
        <direction ref="directionContext"></direction>
        <top-button ref="topButtonContext" @pressedTopButton="moveTop"></top-button>
        <footer-component :footerList="footerList" ref="footerContext" @sideMenu-call="sideMenuCall"></footer-component>
      </div>
    </full-page>

  </div>
</template>
<script>
  import HeaderComponent from '@component/HeaderBlack.vue';
  import FooterComponent from '@component/Footer.vue';
  import TopButton from '@component/TopButton.vue';
  import Contact from '@page/contact/Contact.vue';
  import Direction from '@page/contact/Direction.vue';
  export default {
    data() {
      return {
        pageType: 4,
        options: {
          licenseKey: '1B30CEC9-876B4E97-A3C378D3-89432445',
          afterLoad: this.afterLoad,
          onLeave: this.onLeave,
          scrollOverflow: true,
          autoScrolling: true,
          fitToSectionDelay: 1000,
          normalScrollElements: '.map-wrap',
          menu: '#menu',
          responsiveWidth: '768',
          responsiveSlides: true,
          navigation: false,
          anchors: ['page1', 'page2'],
        },
        menuList: [{
            title: "About Us",
            child: ["Who We Are", "Ethics Policy", "Partnerships", "Finehealthcare CI"],
            link: ["about", "about#page2", "about#page3", "about#page4"]
          },
          {
            title: "Telederm Units",
            child: ["Skinex", "LimeSkin"],
            link: ["telederm/skinex", "telederm/lime"]
          },
          {
            title: "Big Data Platforms",
            child: ["Lime EMR", "Lime Team", "Lime Clinic"],
            link: ["platform/emr", "platform/team", "platform/clinic"]
          },
          {
            title: "Fine News",
            child: ["News"],
            link: ["news"]
          },
          {
            title: "Contact Us",
            child: ["Contact", "Direction & Map"],
            link: ["contact", "contact#page2"]
          }
        ],
        footerList: {
          menuLink: ["", ""],
          menuKor: ["개인정보 취급방침", "사이트맵"],
          menuEng: ["Privacy Policy", "SiteMap"],
          companyKor: ["(주) Finehealthcare", "주소 : (06069) 서울특별시 강남구 선릉로 704(청담동), 청담빌딩 7층 "],
          companyEng: ["Finehealthcare",
            "(06069) 7F, Cheongdam Building. 704, Seolleung-ro, Gangnam-gu, Seoul"
          ],
          infoKor: ["대표이사 : 신현경", "사업자등록번호 : 141-86-01677", "대표번호 : 02-565-2920",
            "이메일 : anobo@finehc.ai"
          ],
          infoEng: ["CEO: SHIN HYUN KYUNG", "Corporate Registration Number : 141-86-01677",
            "Telephone : 02-565-2920", "E-mail : anobo@finehc.ai"
          ],
        },
        isMenu: false,
        selectedMenu: 0,
        languageType: sessionStorage.getItem('languageType'),
        currentPage: "Contact",
        isGuided: null
      };
    },
    created() {},
    mounted() {
       setTimeout(() => {
          this.$refs.fullpage.api.reBuild();
        }, 800);
      this.currentMenu();
    },
    components: {
      HeaderComponent,
      FooterComponent,
      Contact,
      Direction,
      TopButton,
    },
    computed: {},
    methods: {
      effectInit() {
        /** content */
        this.$refs.contactContext.initContact();
        this.$refs.directionContext.initDirection();
        /** map */
      },
      initThings() {
        this.isMenu = true;
          this.$refs.headerContext.navTrans(2);
          this.$refs.headerContext.isSpreadMenu(false);
          if (this.isGuided !== false) {
             this.$refs.topButtonContext.isView(true);
          }
          this.effectInit();
      },
       currentMenu() {
        document.querySelector('.navigator').childNodes[4].classList.add("currented");
      },
      languageTrans(languageType) {
        this.$nextTick(_ => {
          if (languageType === 0) {
            this.$refs.footerContext.isEng(false);
          } else {
            this.$refs.footerContext.isEng(true);
          }
          this.$refs.fullpage.api.reBuild();
        });
      },
      sideMenuCall(condition) {
        if (condition) {
          this.$refs.fullpage.api.silentMoveTo('page1');
          this.$refs.headerContext.isSpreadMenuByChild(true);
          this.$refs.topButtonContext.isView(false);

        } else {
          this.$refs.topButtonContext.isView(true);
        }
      },
      moveSection(order) {
        const direction = "page" + (order + 1)
        this.$refs.fullpage.api.moveTo(direction);
      },
      menuClick(order) {
        this.selectedMenu = order;
      },
      moveTop() {
        this.$refs.fullpage.api.moveTo('page1');
        this.$refs.headerContext.navTrans(0);
        this.$refs.topButtonContext.isView(false);
      },
      onLeave(origin, destination, direction) {
        this.selectedMenu = destination.index;
        if (destination.index === 0) {
          console.log("0번 수행");
          this.effectInit();
          this.$refs.contactContext.setContact();
          this.isMenu = false;
          this.currentPage = 'Contact'
          if (direction === 'up') {
            this.$refs.headerContext.navTrans(0);
            this.$refs.topButtonContext.isView(false);
          } else {

          }
        } else if (destination.index === 1) {
          this.initThings();
          this.$refs.directionContext.setDirection();
          if (direction === 'up') {
          } else {
            if (this.isGuided !== false) {
            this.$refs.topButtonContext.isView(false);
            }
          }
          this.currentPage = 'Direction & Map'
        }
        console.log('Leave origin', origin.index);
        console.log('Leave destination', destination.index);
        console.log('Leave direction', direction);
      },
      onLeave_origin(origin, destination, direction) {
        this.selectedMenu = destination.index;
        if (destination.index === 0 && direction === 'up') {
          this.$refs.headerContext.navTrans(0);
          this.$refs.topButtonContext.isView(false);
          this.isMenu = false;
        } else {
          console.log("리브", origin.index, " ", destination.index, " ", direction);
          this.$refs.headerContext.navTrans(2);
          this.$refs.headerContext.isSpreadMenu(false);
          this.$refs.topButtonContext.isView(true);
          this.isMenu = true;
        }
        if (destination.index === 0) {
          this.currentPage = 'Contact'
        } else if (destination.index === 1) {
          this.currentPage = 'Direction & Map'
        }

        console.log('Leave origin', origin.index);
        console.log('Leave destination', destination.index);
        console.log('Leave direction', direction);
      },
      afterLoad(origin, destination, direction) {
        console.log('After origin', origin.index);
        console.log('After destination', destination.index);
        console.log('After direction', direction);
      },
    },
    unmounted() {},
  };
</script>
<style lang="scss" scoped>
  @import "@assets/css/sectionType2";
  @import "@assets/css/responsive";
  .section {
    &.in-partner {
      .m-context {
        padding: 198.5px 0 274px;
      }

      h3.section-title {
        margin: 0 0 58px 0;
      }
    }

    &.inTopButton {
      .top-button {
        bottom: 399px;
      }
    }
  }
</style>
