<template>
  <div class="m-context effect-ci">
    <h3 class="section-title">CI</h3>
    <div class="ci" :class="{isEng: view.isEng}">
      <div class="logo-area">
        <div class="text">
          <p class="pre"><b>{{ciList.logo[0]}}</b>{{ciList.logo[1]}}</p>
          <p>{{ciList.logo[2]}}</p>
        </div>
        <div class="logo">
          <img src="@assets/images/ci/aboutus_img_ci.svg" />
        </div>
      </div>
      <div class="download">
        <div class="item" @click="downloadFile('ai')">
          <h6 class="name">AI Download</h6>
          <div class="img-wrap">
          </div>
        </div>
        <div class="item" @click="downloadFile('jpg')">
          <h6 class="name">JPG Download</h6>
          <div class="img-wrap">
          </div>
        </div>
      </div>
      <div class="divider"></div>
      <div class="color-area">
        <div class="text">
          <h6>Color System</h6>
          <p>{{ciList.color}}</p>
        </div>
        <div class="color-picker">
          <div class="item">
            <div class="color-box">
              <div class="img-wrap" v-if="resizeType === 3 || resizeType === 2">
                <img src="@assets/images/ci/aboutus_img__colorsystem_1.svg" />
              </div>
              <div class="img-wrap" v-if="resizeType === 1">
                <img src="@assets/images/ci/768_aboutus_img__colorsystem_1.svg" />
              </div>
              <div class="img-wrap" v-if="resizeType === 0">
                <img src="@assets/images/ci/414_aboutus_img__colorsystem_1.svg" />
              </div>
              <ul class="percentage">
                <li>0%</li>
                <li>20%</li>
                <li>40%</li>
                <li>60%</li>
                <li>80%</li>
              </ul>
            </div>
            <div class="color-info">
              <div class="article">
                <h6 class="name">CMYK</h6>
                <p>C 91 / M 92 / Y 43 / K 9</p>
              </div>
              <div class="article">
                <h6 class="name">RGB</h6>
                <p>R 36 / G 49 / B 100</p>
              </div>
              <div class="article">
                <h6 class="name">Hex</h6>
                <p>#243164</p>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="color-box">
              <div class="img-wrap" v-if="resizeType === 3 || resizeType === 2">
                <img src="@assets/images/ci/aboutus_img__colorsystem_2.svg" />
              </div>
              <div class="img-wrap" v-if="resizeType === 1">
                <img src="@assets/images/ci/768_aboutus_img__colorsystem_2.svg" />
              </div>
              <div class="img-wrap" v-if="resizeType === 0">
                <img src="@assets/images/ci/414_aboutus_img__colorsystem_2.svg" />
              </div>
              <ul class="percentage">
                <li>0%</li>
                <li>20%</li>
                <li>40%</li>
                <li>60%</li>
                <li>80%</li>
              </ul>
            </div>
            <div class="color-info">
              <div class="article">
                <h6 class="name">CMYK</h6>
                <p>C 68 / M 27 / Y 5 / K 0</p>
              </div>
              <div class="article">
                <h6 class="name">RGB</h6>
                <p>R 79 / G 154 / B 206</p>
              </div>
              <div class="article">
                <h6 class="name">Hex</h6>
                <p>#4F9ACE</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      ciList: {
        type: Object
      }
    },
    data() {
      return {
        list: [],
        view: {
          isEng: false
        },
        resizeType: 3
      };
    },
    created() {
      this.initCiList(Number(sessionStorage.getItem('languageType')));
    },
    methods: {
      initCi() {
         document.querySelector(".effect-ci").classList.remove("ani-on");
      },
      setCi() {
         document.querySelector(".effect-ci").classList.add("ani-on");
      },
      setResizeType(order) {
        this.resizeType = order;
      },
      downloadFile(type) {
        if (type === 'ai') {
          window.open(
            'https://drive.google.com/file/d/1eyyIhQtw1Dh0BF3HC02R4OHx0IRQUx-D/view?usp=sharing',
            "_blank"
          );
        } else if (type === 'jpg') {
          window.open(
            'https://drive.google.com/file/d/1LSiaKWhzK_T87AtB3nih6EZ7XIFJ0uqO/view?usp=sharing',
            "_blank"
          );

        }
      },
      isEng(languageType) {
        if (languageType) {
          this.list.logo = this.ciList.logoEng;
          this.list.color = this.ciList.colorEng;
          this.view.isEng = true;
        } else {
          this.list.logo = this.ciList.logoKor;
          this.list.color = this.ciList.colorKor;
          this.view.isEng = false;
        }
      },
      initCiList(languageType) {
        this.list = this.ciList;
        if (languageType === 0) {
          this.list.logo = this.ciList.logoKor;
          this.list.color = this.ciList.colorKor;
          this.view.isEng = false;
        } else {
          this.list.logo = this.ciList.logoEng;
          this.list.color = this.ciList.colorEng;
          this.view.isEng = true;
        }
      },
    },
    components: {},
  };
</script>
<style lang="scss" scoped>
  .m-context {
    padding: 180px 0 142px 0;
    &.effect-ci {
      &.ani-on {
      h3.section-title {

      }
      .ci {
        .logo-area {
          .text {
            animation: slideUp 0.9s cubic-bezier(0.65, 0, 0.35, 1) both;
          -webkit-animation: slideUp 0.9s cubic-bezier(0.65, 0, 0.35, 1) both;
            p {
              &.pre {

              }
            }
          }
          .logo {
            animation: slideUp 0.9s cubic-bezier(0.65, 0, 0.35, 1) both;
          -webkit-animation: slideUp 0.9s cubic-bezier(0.65, 0, 0.35, 1) both;
          }
        }
        .download {
          animation: slideUp 1.0s cubic-bezier(0.65, 0, 0.35, 1) both;
          -webkit-animation: slideUp 1.0s cubic-bezier(0.65, 0, 0.35, 1) both;
          .item {
            &:nth-of-type(2) {

            }
          }
        }
        .color-area {
          .text {
            animation: slideUp 1.1s cubic-bezier(0.65, 0, 0.35, 1) both;
          -webkit-animation: slideUp 1.1s cubic-bezier(0.65, 0, 0.35, 1) both;
            h6 {

            }
            p {

            }
          }
          .color-picker {
            animation: slideUp 1.2s cubic-bezier(0.65, 0, 0.35, 1) both;
          -webkit-animation: slideUp 1.2s cubic-bezier(0.65, 0, 0.35, 1) both;
            .item {
              .color-box {

              }
              .color-info {

              }
              &:nth-of-type(2) {
              .color-box {

              }
              .color-info {
                
              }
              }
            }
          }
        }
      }
    }
    }
  }

  h3.section-title {
    @include initfont($font-size:40px, $line-height:70px, $font-family:'Poppins-Bold', $color:#292a2b, $text-align:left);
    margin: 0;
  }

  .ci {
    &.isEng {
      .logo-area {
        .text {
          p {
            font-family: 'Poppins-Regular';

            &.pre {
              b {
                font-family: 'Poppins-SemiBold';
              }
            }
          }
        }
      }


      .color-area {
        .text {
          p {
            font-family: 'Poppins-Regular';
          }
        }
      }

      .color-picker {
        .item {


          ul.percentage {
            li {}
          }

          .color-info {
            .article {

              h6.name {}

              p {}
            }
          }
        }
      }
    }

    .logo-area {
      display: flex;
      align-items: center;
      justify-content: center;

      .text {
        margin: 0 79.2px 0 0;

        p {
          @include initfont($font-size:16px, $font-family:'AppleSDGothicNeoR00', $color:#292a2b, $line-height: 24px, $text-align:left);

          &.pre {
            margin: 0 0 18px 0;

            b {
              font-family: "AppleSDGothicNeoB00";
              font-weight: normal;
            }
          }
        }
      }

      .logo {
        img {}
      }
    }

    .download {
      margin: 0 0 50px 0;
      display: flex;

      .item {
        display: flex;
        align-items: center;
        margin: 0 0 0 30px;
        padding: 0 20px;
        position: relative;
        width: 240px;
        height: 48px;
        border-radius: 2px;
        border: solid 1px #292a2b;
        cursor: pointer;
        overflow: hidden;

        &:first-of-type {
          margin: 0;
        }

        &:hover {
          background: #292a2b;

          h6.name {
            color: #fff;
          }

          .img-wrap {
            background-image: url("~@assets/images/ci/aboutus_icon_download_hover.svg");
          }
        }

        h6.name {
          @include initfont($font-size:18px, $font-family:'AppleSDGothicNeoB00', $color:#292a2b, $line-height: 24px, $text-align:left);
        }

        .img-wrap {
          top: 50%;
          transform: translateY(-50%);
          background-image: url("~@assets/images/ci/aboutus_icon_download.svg");
          background-repeat: no-repeat;
          background-size: cover;
          right: 20px;
          position: absolute;
          width: 24px;
          height: 24px;
        }
      }
    }

    .divider {
      margin: 0 0 30px 0;
      width: 100%;
      height: 1px;
      background: #d7dadc;
    }

    .color-area {
      .text {
        margin: 0 0 30px 0;

        h6 {
          @include initfont($font-size:20px, $font-family:'Poppins-SemiBold', $color:#292a2b, $line-height: 50px, $text-align:left);
          margin: 0 0 18px 0;
        }

        p {
          @include initfont($font-size:16px, $font-family:'AppleSDGothicNeoR00', $color:#292a2b, $line-height: 24px, $text-align:left);
        }
      }

      .color-picker {
        display: flex;

        .item {
          margin: 0 0 0 104px;

          &:first-of-type {
            margin: 0;
          }

          .img-wrap {
            margin: 0 0 10px 0;

            img {}
          }

          ul.percentage {
            padding: 0 0 0 21px;
            display: flex;
            margin: 0 0 25px 0;

            li {
              @include initfont($font-size:16px, $font-family:'AppleSDGothicNeoB00', $color:#8d8f91, $line-height: 22px, $text-align:left);
              margin: 0 0 0 26px;

              &:first-of-type {
                margin: 0;
              }
            }
          }

          .color-info {
            .article {
              display: flex;
              margin: 6px 0 0 0;

              &:first-of-type {
                margin: 0;
              }

              h6.name {
                @include initfont($font-size:16px, $font-family:'AppleSDGothicNeoB00', $color:#292a2b, $line-height: 24px, $text-align:left);
                margin: 0 8px 0 0;
                min-width: 45px;
              }

              p {
                @include initfont($font-size:16px, $font-family:'AppleSDGothicNeoR00', $color:#292a2b, $line-height: 24px, $text-align:left);
              }
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 1025px) and (max-width: 1299px) {
    .ci .color-area .color-picker .item {
      width: calc(50% - 30px);
      margin: 0 0 0 60px;

      .img-wrap {
        img {
          max-width: 100%;
        }
      }
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .ci {
      .logo-area {
        margin: 0 0 20px 0;

        .text {
          margin: 0 45px 0 0;
        }

        .logo {
          img {
            max-width: 205px;
          }
        }
      }

      .download {
            max-width: 480px;

        .item {
          margin: 0 0 0 20px;
        }
      }

      .color-area .color-picker {
        flex-wrap: wrap;

        .item {
          width: 100%;
          margin: 45px 0 0 0;
          display: flex;

          .color-info {
            width: 215px;
          }

          .color-box {
            width: calc(100% - 248px);
            margin: 0 33px 0 0;

            .img-wrap {
              img {
                max-width: 100%;
              }
            }
          }

          &:first-of-type {
            margin: 0;
          }
        }
      }
    }
  }

  @media screen and (min-width: 610px) and (max-width: 768px) {
    .ci {
      .logo-area {
        margin: 0 0 20px 0;

        .text {
          margin: 0 45px 0 0;
        }

        .logo {
          img {
            max-width: 205px;
          }
        }
      }

      .download {
        max-width: 480px;

        .item {
          margin: 0 0 0 20px;
        }
      }

      .color-area .color-picker {
        flex-wrap: wrap;

        .item {
          width: 100%;
          margin: 45px 0 0 0;
          display: flex;
          ul.percentage li {
            margin: 0 0 0 15px;
          }
          .color-info {
            width: 215px;
          }

          .color-box {
            width: calc(100% - 248px);
            margin: 0 33px 0 0;

            .img-wrap {
              img {
                max-width: 100%;
              }
            }
          }

          &:first-of-type {
            margin: 0;
          }
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    .ci .color-area .color-picker .item .img-wrap img {
      max-width: 100%;
    }

    .ci .color-area .color-picker {
      flex-wrap: wrap;
    }
  }

  @media screen and (max-width: 609px) {
    .section {
        .m-context {
          h3.section-title {
            margin: 0 0 25px 0;
            font-size: 30px;
            line-height: 40px;
          }
          .ci {
            .logo-area {
              flex-direction: column-reverse;
              align-items: inherit;
              justify-content: inherit;
              .logo {
                margin: 0 0 20px 0;
                img {
                  max-width: 205px;
                }
              }
              .text {
                margin: 0;
                p.pre {
                  margin: 0 0 8px 0;
                }
              }
            }
            .download {
              margin: 20px 0 30px 0;
              display: block;
              .item {
                margin: 20px 0 0 0;
              }
            }
            .color-area {
              .text {
                margin: 0 0 5px 0;
                h6 {
                  margin: 0;
                }
                p {
                  display: none;
                }
              }
            }
            .color-picker {
              .img-wrap {
                margin: 0 0 5px 0;
              }
              .item {
                margin: 20px 0 0 0;
                ul.percentage li {
                  font-size: 12px;
                  line-height: 17px;
                }
                .color-info .article {
                  h6,p {
                    font-size: 15px;
                    line-height: 20px;
                  }
                }
              }
            }
          }
      }
    }
  }
</style>