<template>
  <section class="container">
      <div class="slider">
        <ul ref="sliderRef">
          <li class="slider_item" v-for="(image, index) in images" :key="index">
            <img :src="require(`@/assets/images/modal/${image}.png`)" alt="image" />
          </li>
        </ul>
      </div>
    <div class="button_block" v-if="!this.$isMobile()">
        <button @click="prevBtn"><img src="@/assets/images/modal/carousel_left_arrow@2x.png" /></button>
        <button @click="nextBtn"><img src="@/assets/images/modal/carousel_right_arrow@2x.png" /></button>
      </div>
  </section>
</template>

<script>
import Hammer from 'hammerjs';

export default {
  props: {
    images: {
      type: Array,
      default: null
    },
  },
  data() {
    return {
      currentImage: 0,
      totalImage: this.images.length,
    }
  },
  mounted () {
    const hammer = new Hammer(this.$refs.sliderRef);

    hammer.on('swipe', (e) => {
      if (e.deltaX > 0) {
        if (this.currentImage === this.totalImage - 1) {
          this.currentImage = 0;
        } else {
          this.currentImage += 1;
        }
        this.$refs.sliderRef.style.transition = "all 0.5s ease-in-out";
        this.$refs.sliderRef.style.transform = `translateX(-${this.currentImage}00%)`
      
        this.$emit('currentImage', this.currentImage);
      } else {
        if (this.currentImage === 0) {
          this.currentImage = this.totalImage - 1;
        } else {
          this.currentImage -= 1;
        }
        this.$refs.sliderRef.style.transition = "all 0.5s ease-in-out";
        this.$refs.sliderRef.style.transform = `translateX(-${this.currentImage}00%)`
        this.$emit('currentImage', this.currentImage);
      }
    })
  },
  methods: {
    prevBtn() {
      if (this.currentImage === 0) {
        this.currentImage = this.totalImage - 1;
      } else {
        this.currentImage -= 1;
      }
      this.$refs.sliderRef.style.transition = "all 0.5s ease-in-out";
      this.$refs.sliderRef.style.transform = `translateX(-${this.currentImage}00%)`
      this.$emit('currentImage', this.currentImage);
    },
    nextBtn() {
      if (this.currentImage === this.totalImage - 1) {
        this.currentImage = 0;
      } else {
        this.currentImage += 1;
      }
      this.$refs.sliderRef.style.transition = "all 0.5s ease-in-out";
      this.$refs.sliderRef.style.transform = `translateX(-${this.currentImage}00%)`
    
      this.$emit('currentImage', this.currentImage);
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@assets/css/_mixin';

.container {
  position: relative;
  .slider {
      position: relative;
      max-width: 1353px;
      margin: 0 auto;
      overflow: hidden;

      .slider_item {
        width: 100%;
        height: 760px;
        img {
          object-fit: contain;
          width: 100%;
          height: 760px;
          @include mobile {
            height: 495px;
          }
        } 
      }
    }
  
  .button_block {
    display: flex;
    width: 100%;
    position: absolute;
    justify-content: space-between;
    align-items: center;
    top: 45%;
    z-index: 20;
    padding: 0 50px;
    
    button {
      cursor: pointer;
      background-color: black;
      border: none;
      background: none;
      img {
        width: 56px;
        height: 56px;
      }
    }
  }
  ul {
    list-style: none;
    white-space: nowrap;
    position: relative;
  }
  ul, li {
    display: inline-block;
  }
}
</style>