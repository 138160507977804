const data = {
  searchParam: 'sdsd',
};

const getters = {
  getSearchParams: (state) => state.searchParam,

};

const mutations = {
  SET_SEARCH_PARAMS(state, json) {
    state.searchParam = json;
  },
};

const actions = {};

export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: data,
  mutations,
  getters,
  actions,
};
