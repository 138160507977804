<template>
  <div id="map"></div>
</template>
<script>
  export default {
    data() {
      return {

      };
    },
    created() {
      console.log("로드됨");
    },
    mounted() {
      window.kakao && window.kakao.maps ? this.initMap() : this.addScript();
    },
    components: {},
    computed: {},
    methods: {
      initMap() {
        var container = document.getElementById('map');
        var options = {
          center: new kakao.maps.LatLng(37.517883715964444, 127.04136260489616),
          level: 3
        };
        var map = new kakao.maps.Map(container,
        options); 
        var marker = new kakao.maps.Marker({ position: map.getCenter() });
        marker.setMap(map);
        }, addScript() { const script = document.createElement('script'); /* global kakao */ script.onload = () => kakao.maps.load(this.initMap); script.src='//dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=67b36793429fd3750c5946bf0c5d833c'; document.head.appendChild(script); }
      },
      unmounted() {},
    };
</script>
<style lang="scss" scoped>

</style>