<template>
    <div class="swiper-wrap">
      <swiper class="swiper" :options="swiperOption">
        <!-- v-for -->
        <swiper-slide>1</swiper-slide>
        <swiper-slide>2</swiper-slide>
        <swiper-slide>3</swiper-slide>
        <swiper-slide>4</swiper-slide>
        <swiper-slide>5</swiper-slide>
        <swiper-slide>6</swiper-slide>
      </swiper>
    </div>
</template>

<script>
  import {
    Swiper,
    SwiperSlide
  } from "vue-awesome-swiper";
  import "swiper/swiper-bundle.css";
  export default {
    
    data() {
      return {
        swiperOption: {
          slidesPerView: 2,
          grabCursor: true,
        }
      };
    },
    mounted() {
    
    },
    computed: {},
    created() {
    },
    methods: {
      
    },
    components: {
      Swiper,
      SwiperSlide
    }
  };
</script>

<style lang="scss" scoped>
 
</style>
