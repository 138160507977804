<template>
  <div class="about">
    <header-component ref="headerContext" :pageType="pageType" @currentLanguageType="languageTrans" :menuList="menuList" @sideMenu-call="sideMenuCall" @about-nav-clicked="moveSection" @resize-type="setResponsive"></header-component>
    <div class="menu-wrap" @click.stop :class="{deactive:!isMenu}">
      <div class="swiper-menu-wrap">
       <swiper class="swiper-menu" :options="swiperOption">
      <!-- v-for -->
      <swiper-slide class="menu-child">
          <div class="menu-holder" data-menuanchor="page1" :class="{active: selectedMenu === 0}" @click="menuClick(0)"><a href="#page1">Who We
          Are</a></div>
      </swiper-slide>
      <swiper-slide class="menu-child">
        <div class="menu-holder" data-menuanchor="page2" :class="{active: selectedMenu === 1}" @click="menuClick(1)"><a href="#page2">Ethics
          Policy</a></div>
      </swiper-slide>
      <swiper-slide class="menu-child">
        <div class="menu-holder" data-menuanchor="page3" :class="{active: selectedMenu === 2}" @click="menuClick(2)"><a
          href="#page3">Partnerships</a></div>
      </swiper-slide>
      <swiper-slide class="menu-child">
        <div class="menu-holder" data-menuanchor="page4" :class="{active: selectedMenu === 3}" @click="menuClick(3)"><a
          href="#page4">Finehealthcare CI</a></div>
      </swiper-slide>
    </swiper>
      </div>
    <ul id="menu">
      <li data-menuanchor="page1" :class="{active: selectedMenu === 0}" @click="menuClick(0)"><a href="#page1">Who We
          Are</a></li>
      <li data-menuanchor="page2" :class="{active: selectedMenu === 1}" @click="menuClick(1)"><a href="#page2">Ethics
          Policy</a></li>
      <li data-menuanchor="page3" :class="{active: selectedMenu === 2}" @click="menuClick(2)"><a
          href="#page3">Partnerships</a></li>
      <li data-menuanchor="page4" :class="{active: selectedMenu === 3}" @click="menuClick(3)"><a
          href="#page4">Finehealthcare CI</a></li>
    </ul>
    <p class="milestone">home > About Us > <b>{{currentPage}}</b></p>
    </div>
    <full-page class="fullPage-container" ref="fullpage" :options="options" id="fullpage" @after-load="afterLoad"
      @on-leave="onLeave">
      <div class="section fp-auto-height-responsive in-close">
        <!-- WHO -->
        <p class="milestone inner">home > About Us > <b>{{currentPage}}</b></p>
        <vision :visionList="visionList" ref="visionContext"></vision>
        <div class="s-bg-left-bottom" v-if="resizeType === 2 || resizeType === 3">
          <img src="@assets/images/about/aboutus_img_bg_left.svg" />
        </div>
         <div class="s-bg-right-top" v-if="resizeType === 3">
            <img src="@assets/images/about/aboutus_img_bg_right.svg" />
        </div>
      </div>
      <div class="section bg-gray">
        <!-- ETHICS -->
         <p class="milestone inner">home > About Us > <b>{{currentPage}}</b></p>
        <policy :policyList="policyList" ref="policyContext"></policy>
      </div>
      <div class="section in-partner">
        <p class="milestone inner">home > About Us > <b>{{currentPage}}</b></p>
        <partner :partnerList="partnerList" ref="partnerContext" :partnerType="partnerType"></partner>
      </div>
      <div class="section fp-auto-height bg-gray inTopButton">
        <!-- CI -->
        <p class="milestone inner">home > About Us > <b>{{currentPage}}</b></p>
        <ci :ciList="ciList" ref="ciContext"></ci>
        <top-button ref="topButtonContext2" @pressedTopButton="moveTop"></top-button>
        <footer-component :footerList="footerList" ref="footerContext" @sideMenu-call="sideMenuCall"></footer-component>
      </div>
    </full-page>
    <top-button ref="topButtonContext" @pressedTopButton="moveTop"></top-button>
  </div>
</template>
<script>
  import HeaderComponent from '@component/HeaderBlack.vue';
  import FooterComponent from '@component/Footer.vue';
  import TopButton from '@component/TopButton.vue';
  import Vision from '@page/about/Vision.vue';
  import Ci from '@page/about/Ci.vue';
  import Policy from '@page/about/Policy.vue';
  import Partner from '@page/commons/Partner.vue';
   import {
    Swiper,
    SwiperSlide
  } from "vue-awesome-swiper";
  import "swiper/swiper-bundle.css";
  export default {
    data() {
      return {
        partnerType: 1,
        isGuided: null,
        swiperOption: {
          slidesPerView: 3,
        },
        options: {
          licenseKey: '1B30CEC9-876B4E97-A3C378D3-89432445',
          afterLoad: this.afterLoad,
          onLeave: this.onLeave,
          scrollOverflow: true,
          fitToSectionDelay: 1000,
           autoScrolling: true,
            offsetSections: true,
          menu: '#menu',
          navigation: false,
          anchors: ['page1', 'page2', 'page3', 'page4'],
          sectionsColor: ['#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff'],
        },
       partnerList: {
          img: [
            "1.png", "2.png", "3.png", "4.png", "5.png", "6.png", "7.png", "8.png",
            "9.png", "10.png", "11.png", "12.png", "13.png", "14.png", "15.png", "16.png",
            "17.png", "18.png", "19.png", "20.png", "21.png", "22.png", "23.png", "24.png",
            "25.png", "26.png", "27.png", "28.png", "29.png", "30.png", "31.png"
            ]
        },
        ciList: {
          logoKor: ["파인헬스케어 로고", "는 영문을 기본으로 디자인되었습니다.",
            "로고에 적용된 심볼은 'fine'의 'f'와 'i'에서 착안, 데이터가 모여드는 형태와 웃는 모습을 형상화 하였으며 헬스케어와 AI를 통합해 당신의 더 나은 삶을 위해 사용한다는 파인헬스케어의 미션을 표현한 것입니다."
          ],
          logoEng: ["Finehealthcare’s logo has been designed",
            "to symbolise the assembly of data and people smiling, to express fine healthcare’s mission of combining healthcare with AI to improve the quality of life."
          ],
          colorKor: "전용색상은 워드마크와 더불어 파인헬스케어의 이미지를 전달하는 중요한 기능을 합니다. 전용색상의 효과적인 사용을 위해서는 인쇄방법, 잉크의 농도, 종이의 재질, 적용매체의 상황 등을검토하여 본 매뉴얼이 정한 표준 색상을 유지하도록 합니다. 인쇄시에는 본 매뉴얼에 기재된 CMYK 값을 기준으로 합니다.",
          colorEng: "Our signature color has an important role in conveying Fine Healthcare’s image. To effectively use this signature color, the printing method, ink intensity, printing material and medium should be considered to maintain this signature color. When being printed, the CMYK values recorded in this manual will be the standard."
        },
        policyList: {
          title: {
            titleKor: ["파인헬스케어", "빅데이터-인공지능 알고리즘 윤리헌장"],
            titleEng: ["Finehealthcare", "Big Data – AI code of Ethics"]
          },
          content: [{
              contentKor: ["AIR", "공정성-법률 준수의 의무", "파인헬스케어는 빅데이터와 인공지능 알고리즘을 우리 사회의 보편적 질서와 법규, 사회 통념에 준수하여 운용한다."],
              contentEng: ["AIR", "Legal obligations",
                "Finehealthcare will observe legal regulations and common societal order in managing big data and AI algorithms."
              ]
            },
            {
              contentKor: ["NDEPENDENT", "독립성-정보 보호의 의무",
                "파인헬스케어는 빅데이터와 인공지능 알고리즘을 제3자의 훼손과 접근, 영향으로부터 철저히 보호될 수 있도록 관리한다."
              ],
              contentEng: ["NDEPENDENT", "Confidentiality obligations",
                "Finehealthcare will protect big data and AI algorithms from 3rd parties accessing or influencing them."
              ]
            },
            {
              contentKor: ["EUTRAL", "중립성-가치 중립의 의무",
                "파인헬스케어는 빅데이터와 인공지능 알고리즘이 특정한 가치나 이념을 지향하지 않고 오직 사실에 근거하도록 운용한다."
              ],
              contentEng: ["EUTRAL", "Value neutrality obligations",
                "Finehealthcare will manage big data and AI algorithms strictly based on facts, without being biased towards any specific values."
              ]
            },
            {
              contentKor: ["THICAL", "윤리성 -기본 윤리의 의무",
                "파인헬스케어는 빅데이터 수집 및 인공지능 알고리즘의 학습 과정에 있어서 우리 사회의 상식과 윤리적 기준에 부합하게 운용한다."
              ],
              contentEng: ["THICAL", "Ethical obligations",
                "Finehealthcare will comply with societal and ethical standards in collecting and training big data and AI algorithms in both the process and the outcome."
              ]
            }
          ]
        },
        visionList: [{
            contentKor: ["Medical Big Data & AI Science",
              "개별 고객 데이터 및 빅데이터에 대한\n 인공지능 분석을 통한 맞춤형 헬스케어 서비스\n(Customized Healthcare Service) 제공"
            ],
            contentEng: ["Medical Big Data &  AI Science",
              "Customized healthcare service via\n Al analysis of individual client\n data and big data"
            ],
          },
          {
            contentKor: ["Virtual Healthcare Core Infra",
              "디지털 헬스케어 플랫폼을 통해 지리적 한계를 넘어\n어디서나 사용 가능한 글로벌 O2O 서비스\n(GLOBAL Online to Offline Service) 제공"
            ],
            contentEng: ["Virtual Healthcare Core Infra",
              "Providing Global online-to-Offline\n (020) service by breaking geographical\n barriers through a digital healthcare platform"
            ],
          },
          {
            contentKor: ["Combinable Healthcare In Iot",
              "다양한 디지털 디바이스와의 연동을 통해\nDigital Therapeutics, Emergency Surport\nNutrition Care 등 확장된 생활밀착형 헬스케어\n서비스 (Life-Friendly Healthcare Service) 제공"
            ],
            contentEng: ["Combinable Healthcare In Iot",
              "Providing an extensive range of life-friendly\n healthcare services such as digital therapeutics, emergency support, nutrition care by connecting with various digital devices"
            ],
          }
        ],
        pageType: 0,
        menuList: [
          {
            title: "About Us",
            child: ["Who We Are", "Ethics Policy", "Partnerships", "Finehealthcare CI"],
            link: ["about", "about#page2", "about#page3", "about#page4"]
          },
          {
            title: "Telederm Units",
            child: ["Skinex","Lime Skin"],
            link: ["telederm/skinex","telederm/lime"]
          },
          {
            title: "Big Data Platforms",
            child: ["Lime EMR", "Lime Team", "Lime Clinic"],
            link: ["platform/emr", "platform/team", "platform/clinic"]
          },
          {
            title: "Fine News",
            child: ["News"],
            link: ["news"]
          },
          {
            title: "Contact Us",
            child: ["Contact", "Direction & Map"],
            link: ["contact", "contact#page2"]
          }
        ],
        footerList: {
          menuLink:["",""],
          menuKor: ["개인정보 취급방침", "사이트맵"],
          menuEng: ["Privacy Policy", "SiteMap"],
          companyKor: ["(주) Finehealthcare", "주소 : (06069) 서울특별시 강남구 선릉로 704(청담동), 청담빌딩 7층 "],
          companyEng: ["Finehealthcare",
            "(06069) 7F, Cheongdam Building. 704, Seolleung-ro, Gangnam-gu, Seoul"
          ],
          infoKor: ["대표이사 : 신현경", "사업자등록번호 : 141-86-01677", "대표번호 : 02-565-2920",
            "이메일 : anobo@finehc.ai"
          ],
          infoEng: ["CEO: SHIN HYUN KYUNG", "Corporate Registration Number : 141-86-01677",
            "Telephone : 02-565-2920", "E-mail : anobo@finehc.ai"
          ],
        },
        isMenu: false,
        selectedMenu: 0,
        languageType: sessionStorage.getItem('languageType'),
        currentPage:"Who We Are",
        resizeType: 3
      };
    },
    created() {},
    mounted() {
      this.currentMenu();
      if (document.querySelector('.s-bg-right-top') !== null) {
      document.querySelector('.s-bg-right-top').classList.add("ani-on-down");
       document.querySelector('.s-bg-left-bottom').classList.add("ani-on-up");
      }
    },
    components: {
      HeaderComponent,
      FooterComponent,
      Partner,
      Ci,
      TopButton,
      Vision,
      Policy,
      Swiper,
      SwiperSlide
    },

    computed: {},
    methods: {
      setResponsive(type) {
        this.resizeType = type;
        console.log("type:", type);
        if (type === 3) {

        } else if (type === 2) {
        } else if (type === 1) {
        } else if (type === 0) {
           this.options.autoScrolling = false;
          this.options.scrollOverflow = false;
          /**
          this.options.scrollBar = true;
           */
        }
        /** CI */
        this.$refs.ciContext.setResizeType(type);
      },
      currentMenu() {
        document.querySelector('.navigator').childNodes[0].classList.add("currented");
      },
    sideMenuCall(condition) {
        if (condition) {
          this.$refs.fullpage.api.silentMoveTo('page1');
          this.$refs.headerContext.isSpreadMenuByChild(true);
        this.$refs.topButtonContext.isView(false);
        }
      },
      moveSection(order) {
          const direction = "page" + (order + 1)
        this.$refs.fullpage.api.moveTo(direction);
      },
      menuClick(order) {
        this.selectedMenu = order;
      },
      moveTop() {
        this.$refs.fullpage.api.moveTo('page1');
        this.$refs.headerContext.navTrans(0);
        this.$refs.topButtonContext.isView(false);
      },
      languageTrans(languageType) {
        this.$nextTick(_ => {
          if (languageType === 0) {
            this.$refs.visionContext.isEng(false);
            this.$refs.policyContext.isEng(false);
            this.$refs.ciContext.isEng(false);
            this.$refs.footerContext.isEng(false);
          } else {
            this.$refs.visionContext.isEng(true);
            this.$refs.policyContext.isEng(true);
            this.$refs.ciContext.isEng(true);
            this.$refs.footerContext.isEng(true);
          }
          this.$refs.fullpage.api.reBuild();
        });
      },
        effectInit() {
          this.$refs.visionContext.initVision();
          this.$refs.policyContext.initPolicy();
          this.$refs.partnerContext.initPartner();
          this.$refs.ciContext.initCi();
          /**
        this.$refs.swiperContext.slideEnd();
        this.$refs.newsContext.initNews();
        this.$refs.partnerContext.initPartner();
         */
        if (document.querySelector('.s-bg-right-top') !== null) {
        if (document.querySelector('.s-bg-right-top').classList.contains("ani-on-up")) {
          document.querySelector('.s-bg-right-top').classList.remove("ani-on-up");
        }
         if (document.querySelector('.s-bg-right-top').classList.contains("ani-on-down")) {
          document.querySelector('.s-bg-right-top').classList.remove("ani-on-down");
        }
         if (document.querySelector('.s-bg-left-bottom').classList.contains("ani-on-up")) {
          document.querySelector('.s-bg-left-bottom').classList.remove("ani-on-up");
        }
         if (document.querySelector('.s-bg-left-bottom').classList.contains("ani-on-down")) {
          document.querySelector('.s-bg-left-bottom').classList.remove("ani-on-down");
        }
        }
      },
      initThings() {
        this.isMenu = true;
          this.$refs.headerContext.navTrans(2);
          this.$refs.headerContext.isSpreadMenu(false);
          this.$refs.topButtonContext2.isView(false);
          if (this.isGuided !== false) {
             this.$refs.topButtonContext.isView(true);
          }
          this.effectInit();
      },
      onLeave(origin, destination, direction) {
        this.selectedMenu = destination.index;
        if (destination.index === 0) {
          console.log("0번 수행");
          this.effectInit();
          if (document.querySelector('.s-bg-right-top') !== null) {
           document.querySelector('.s-bg-right-top').classList.add("ani-on-down");
          document.querySelector('.s-bg-left-bottom').classList.add("ani-on-up");
          }
          this.$refs.visionContext.setVision();
          this.isMenu = false;
          if (direction === 'up') {
            this.$refs.headerContext.navTrans(0);
            this.$refs.topButtonContext.isView(false);
            this.$refs.topButtonContext2.isView(false);
          } else {

          }
        } else if (destination.index === 1) {
          this.initThings();
          this.$refs.policyContext.setPolicy();
          if (direction === 'up') {
          } else {
          }
        } else if (destination.index === 2) {
          this.initThings();
          this.$refs.partnerContext.setPartner();
          if (direction === 'up') {
          } else {
          }
        } else if (destination.index === 3) {
          this.initThings();
          this.$refs.ciContext.setCi();
              /**CI */
          if (direction === 'up') {
          } else {
             if (this.isGuided !== false) {
            this.$refs.topButtonContext.isView(false);
            }
            this.$refs.topButtonContext2.isView(true);
          }
        }
        console.log('Leave origin', origin.index);
        console.log('Leave destination', destination.index);
        console.log('Leave direction', direction);
      },
      onLeave_origin(origin, destination, direction) {
        this.selectedMenu = destination.index;
        if (destination.index === 0 && direction === 'up') {
          this.$refs.headerContext.navTrans(0);
          this.$refs.topButtonContext.isView(false);
          this.$refs.topButtonContext2.isView(false);
          this.isMenu = false;
        } else {
          this.$refs.headerContext.navTrans(2);
          this.$refs.headerContext.isSpreadMenu(false);
          this.isMenu = true;
          if (destination.index === 3 && direction === 'down') {
           this.$refs.topButtonContext.isView(false);
           this.$refs.topButtonContext2.isView(true);
           } else {
             this.$refs.topButtonContext.isView(true);
               this.$refs.topButtonContext2.isView(false);
           }
        }
        if (destination.index === 0) {
          this.currentPage = 'Who We Are'
        } else if (destination.index === 1) {
          this.currentPage = 'Ethics Policy'
        } else if (destination.index === 2) {
          this.currentPage = 'Partnerships'
        } else if (destination.index === 3) {
          this.currentPage = 'Finehealthcare CI'
        }
        console.log('Leave origin', origin.index);
        console.log('Leave destination', destination.index);
        console.log('Leave direction', direction);
      },
      afterLoad(origin, destination, direction) {
        console.log('After origin', origin.index);
        console.log('After destination', destination.index);
        console.log('After direction', direction);
      },
    },
    unmounted() {},
  };
</script>
<style lang="scss" scoped>
    @import "@assets/css/sectionType2";
    @import "@assets/css/responsive";
    .section {
      position: relative;
      &.in-partner {
        .m-context {
          padding: 160px 0 90px;

        }
        h3.section-title {
           margin: 0 0 58px 0;
        }

      }
   &.inTopButton {
        .top-button {
          bottom: 399px;
        }
      }
    }
</style>
