import Vue from 'vue'
import VueHead from 'vue-head'
import App from './App.vue'
import router from './router'
import 'fullpage.js/vendors/scrolloverflow' // Optional. When using scrollOverflow:true
import VueFullPage from 'vue-fullpage.js';
import 'fullpage.js/dist/fullpage.css'
import 'swiper/swiper-bundle.css'
import { Swiper as SwiperClass, Pagination, Mousewheel, Autoplay } from 'swiper/core'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
import { store } from "./store/index";
import VueMobileDetection from 'vue-mobile-detection';



// Swiper modules
SwiperClass.use([Pagination, Mousewheel, Autoplay])

// -------------------------------------------------
Vue.config.productionTip = false
Vue.use(VueFullPage);
Vue.use(VueHead);
Vue.use(getAwesomeSwiper(SwiperClass));
Vue.use(VueMobileDetection);
Vue.directive("lazy", {
  inserted: (el, { value }) => {
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          observer.unobserve(entry.target)
          let image = new Image()
          image.src = value
          image.onload = () => {
            entry.target.src = value
          }
          image = null
        }
      })
    })
    observer.observe(el)
  }
})
new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
