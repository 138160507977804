<template>
  <div class="m-context effect-direction">
    <h3 class="section-title">Direction & Map</h3>
    <div class="direction">

      <div class="map-wrap">
        <map-content></map-content>
      </div>
      <!--
     <div class="sample">
     </div>
     -->
      <div class="d-info">
        <h6>Address</h6>
        <p id="addressTxt" class="kor" @click="selectText('addressTxt')">(06069) 서울특별시 강남구 선릉로 704(청담동), 청담빌딩 7층 
          (주)파인헬스케어</p>
        <p>7F, Cheongdam Building. 704, Seolleung-ro, Gangnam-gu, Seoul</p>
      </div>
    </div>
  </div>
</template>

<script>
  import MapContent from '@page/commons/Map.vue';
  export default {
    data() {
      return {
        isSelected: false
      };
    },
    created() {},
    methods: {
       initDirection() {
        document.querySelector(".effect-direction").classList.remove("ani-on");
      },
      setDirection() {
         document.querySelector(".effect-direction").classList.add("ani-on");
      },
      selectText(containerid) {
        if (this.isSelected) {
          window.getSelection().removeAllRanges();
          this.isSelected = false;
        } else {
          if (window.getSelection) {
            var range = document.createRange();
            range.selectNode(document.getElementById(containerid));
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(range);
            this.isSelected = true;
          }
        }
      }
    },
    computed: {},
    components: {
      MapContent
    },
  };
</script>
<style lang="scss" scoped>
  .m-context {
    padding: 160px 0 90px;
    &.effect-direction {
      &.ani-on {
      h3.section-title {
        animation: slideUp 0.9s cubic-bezier(0.65, 0, 0.35, 1) both;
        -webkit-animation: slideUp 0.9s cubic-bezier(0.65, 0, 0.35, 1) both;
      }
      .direction {
        .map-wrap {
          animation: slideUp 1.1s cubic-bezier(0.65, 0, 0.35, 1) both;
        -webkit-animation: slideUp 1.1s cubic-bezier(0.65, 0, 0.35, 1) both;
        }
        .d-info {
           animation: slideUp 1.2s cubic-bezier(0.65, 0, 0.35, 1) both;
        -webkit-animation: slideUp 1.2s cubic-bezier(0.65, 0, 0.35, 1) both;
        }
      }
    }
    }
    
    h3.section-title {
      @include initfont($font-size:40px, $line-height:70px, $font-family:'Poppins-Bold', $color:#292a2b, $text-align:left);
      margin: 0 0 30px 0;
    }

    .sample {
      min-height: 970px;
    }

    .direction {
      .map-wrap {
        width: 100%;
        height: 400px;
        margin: 0 0 60px 0;

        #map {
          width: inherit;
          height: inherit;
        }
      }

      .d-info {
        h6 {
          padding: 0 0 10px 0;
          margin: 0 0 10px 0;
          border: 0px solid #8d8f91;
          border-width: 0 0 1px 0;
          @include initfont($font-size:18px, $line-height:24px, $font-family:'Poppins-SemiBold', $letter-spacing: -0.54px, $color:#292a2b, $text-align:left);
        }

        p {
          -webkit-user-select: auto;
          -moz-user-select: auto;
          user-select: auto;
          @include initfont($font-size:16px, $line-height:20px, $font-family:'AppleSDGothicNeoR00', $letter-spacing: -0.48px, $color:#8d8f91, $text-align:left);

          &.kor {
            @include initfont($font-size:18px, $line-height:24px, $font-family:'AppleSDGothicNeoR00', $letter-spacing: -0.54px, $color:#292a2b, $text-align:left);
            margin: 0 0 5px 0;
          }
        }
      }
    }
  }

  @media screen and (min-width: 1025px) and (max-width: 1299px) {}

  @media screen and (min-width: 769px) and (max-width: 1024px) {
  }

  @media screen and (min-width: 610px) and (max-width: 768px) {
    .m-context {
      h3.section-title {
        margin: 0 0 30px 0;
        font-size: 30px;
        line-height: 40px;
      }
      .direction {
        .map-wrap {
          margin: 0 0 50px 0;
        }
      }
    }
  }

  @media screen and (max-width: 609px) {
    .m-context {
      h3.section-title {
        margin: 0 0 20px 0;
        font-size: 30px;
        line-height: 40px;
      }
      .direction {
        .map-wrap {
          margin: 0 0 25px 0;
        }
        .d-info {
          h6 {
            padding: 0 0 8px 0;
            margin: 0 0 8px 0;
          }
          p {
            &.kor {
            font-size: 15px;
            line-height: 20px;
            }
          }
        }
      }
    }
  }
</style>
