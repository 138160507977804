<template>
  <div class="m-context effect-partner">
    <h3 class="section-title">Partnerships</h3>
    <div class="partner-wrap" :class="{active: partnerType === 1}" v-if="!partnerIsMobile">
      <div class="partner">
        <div class="item" v-for="(partner, index) in partnerList.img" :key="index +'a'">
          <img :src="require(`@/assets/images/partner/${partner}`)" />
        </div>
      </div>
    </div>
    <div class="partner-swiper-wrap" v-else>
      <swiper class="partner-swiper" :options="swiperOption" ref="partnerSwipe" @slide-change-transition-start="slideStart" @slide-change-transition-end="slideEnd">
        <swiper-slide class="partner-child">
          <div class="partner">
            <div class="item" v-for="(mPartner1, index) in mobileList1" :key="index + 'b'">
              <img :src="require(`@/assets/images/partner/${mPartner1}`)" />
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="partner-child">
          <div class="partner">
            <div class="item" v-for="(mPartner2, index) in mobileList2" :key="index + 'c'">
              <img :src="require(`@/assets/images/partner/${mPartner2}`)" />
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <div class="nav-button-wrap">
      <div class="partner-swiper-button-prev active" @click="prevClick()"></div>
      <div class="partner-swiper-button-next" @click="nextClick()"></div>
      </div>
    </div>
  </div>
</template>
<script>
  import {
    Swiper,
    SwiperSlide
  } from "vue-awesome-swiper";

  export default {
    props: {
      partnerList: {
        type: Object
      },
      partnerType: {
        type: Number,
        default: 0
      },
      partnerIsMobile: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        list: [],
        view: {
          isEng: false
        },
        swiperOption: {
          slidesPerView: 1,
          grabCursor: true,
          setWrapperSize: true,
          loop: true,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
          }
        },
        mobileList1: [],
        mobileList2: []
      };
    },
    created() {
      this.storeMobileItem();
    },
    mounted() {},
    components: {
      Swiper,
      SwiperSlide
    },
    computed: {},
    methods: {
      slideStart(info) {
        this.btnEffectInit();
        if(info.realIndex === 0) {
          document.querySelector('.partner-swiper-button-prev').classList.add('active');
        } else {
          document.querySelector('.partner-swiper-button-next').classList.add('active');
        }
      },
      slideEnd() {

      },
      btnEffectInit() {
        if (document.querySelector('.partner-swiper-button-prev').classList.contains('active')) {
          document.querySelector('.partner-swiper-button-prev').classList.remove('active');
        }
        if (document.querySelector('.partner-swiper-button-next').classList.contains('active')) {
          document.querySelector('.partner-swiper-button-next').classList.remove('active');
        }
      },
      prevClick() {
        this.btnEffectInit();
        this.$refs.partnerSwipe.$swiper.slidePrev();
        document.querySelector('.partner-swiper-button-prev').classList.add('active');
      },
      nextClick() {
        this.btnEffectInit();
        this.$refs.partnerSwipe.$swiper.slideNext();
        document.querySelector('.partner-swiper-button-next').classList.add('active');
      },
      storeMobileItem() {
        const mobile1 = [];
        const mobile2 = [];
        for (let i = 0; i < this.partnerList.img.length; i++) {
          if (i < 15) {
            mobile1.push(this.partnerList.img[i]);
          } else {
            mobile2.push(this.partnerList.img[i]);
          }
        }
        this.mobileList1 = mobile1;
        this.mobileList2 = mobile2;
      },
      initPartner() {
        document.querySelector(".effect-partner").classList.remove("ani-on");
      },
      setPartner() {
        document.querySelector(".effect-partner").classList.add("ani-on");
      },
    },
    unmounted() {},
  };
</script>
<style lang="scss" scoped>
  @import "@assets/css/common";

  .section {
    &.in-partner {
      .m-context {
        padding: 160px 0 90px;
      }
    }
  }

  .m-context {
    padding: 120px 0;

    .partner-swiper-wrap {
      position: relative;

      .nav-button-wrap {
        width: 66px;
        height: 5px;
        position: absolute;
        bottom: -40px;
        left: 50%;
        transform: translateX(-50%);
        display:flex;
        .partner-swiper-button-prev {
          width: 30px;
          height: 5px;
          background: #eef1f3;
          margin: 0 6px 0 0;
          &.active {
            background: #114b8d;
          }
        }

        .partner-swiper-button-next {
          width: 30px;
          height: 5px;
          background: #eef1f3;
          &.active {
            background: #114b8d;
          }
        }
      }
    }

    .partner-wrap {
      &.active {
        overflow-x: scroll;
        padding: 0 0 34.5px 0;
        overflow-y: hidden;

        .partner {
          display: flex;
          flex-wrap: wrap;
          min-width: 530px;
          overflow-y: hidden;
        }
      }
    }
  }

  h3.section-title {
    @include initfont($font-size:40px, $line-height:70px, $font-family:'Poppins-Bold', $color:#292a2b, $text-align:left);
    margin: 0 0 40px 0;
  }

  .partner {
    display: flex;
    flex-wrap: wrap;

    .item {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 100%;
        height: auto;
      }
    }
  }


  @media screen and (min-width: 1025px) {
    .m-context {
      &.effect-partner {
        &.ani-on {
          h3.section-title {
            animation: slideUp 0.9s cubic-bezier(0.65, 0, 0.35, 1) both;
            -webkit-animation: slideUp 0.9s cubic-bezier(0.65, 0, 0.35, 1) both;
          }

          .partner {
            .item {
              &:nth-child(n+1):nth-child(-n+5) {
                animation: slideUp 1.1s cubic-bezier(0.65, 0, 0.35, 1) both;
                -webkit-animation: slideUp 1.1s cubic-bezier(0.65, 0, 0.35, 1) both;
              }

              &:nth-child(n+6):nth-child(-n+10) {
                animation: slideUp 1.2s cubic-bezier(0.65, 0, 0.35, 1) both;
                -webkit-animation: slideUp 1.2s cubic-bezier(0.65, 0, 0.35, 1) both;
              }

              &:nth-child(n+11):nth-child(-n+15) {
                animation: slideUp 1.3s cubic-bezier(0.65, 0, 0.35, 1) both;
                -webkit-animation: slideUp 1.3s cubic-bezier(0.65, 0, 0.35, 1) both;
              }

              &:nth-child(n+16):nth-child(-n+20) {
                animation: slideUp 1.4s cubic-bezier(0.65, 0, 0.35, 1) both;
                -webkit-animation: slideUp 1.4s cubic-bezier(0.65, 0, 0.35, 1) both;
              }

              &:nth-child(n+21):nth-child(-n+25) {
                animation: slideUp 1.5s cubic-bezier(0.65, 0, 0.35, 1) both;
                -webkit-animation: slideUp 1.5s cubic-bezier(0.65, 0, 0.35, 1) both;
              }

              &:nth-child(n+26):nth-child(-n+30) {
                animation: slideUp 1.6s cubic-bezier(0.65, 0, 0.35, 1) both;
                -webkit-animation: slideUp 1.6s cubic-bezier(0.65, 0, 0.35, 1) both;
              }

              &:nth-child(n+31) {
                animation: slideUp 1.7s cubic-bezier(0.65, 0, 0.35, 1) both;
                -webkit-animation: slideUp 1.7s cubic-bezier(0.65, 0, 0.35, 1) both;
              }
            }
          }
        }


        .partner {
          .item {
            width: calc((100% / 5) - 12px);
            margin-left: 15px;

            &:nth-of-type(5n+1),
            &:first-of-type {
              margin-left: 0;
            }

            &:nth-of-type(n+6) {
              margin-top: 23px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .section .m-context.ani-on {
      h3.section-title {
        animation: slideUp 0.9s cubic-bezier(0.65, 0, 0.35, 1) both;
        -webkit-animation: slideUp 0.9s cubic-bezier(0.65, 0, 0.35, 1) both;
      }
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .section {
      &.in-partner {
        .m-context {
          h3.section-title {
            margin: 0 0 25px 0;
          }

          &.ani-on {
            .partner {
              .item {
                &:nth-child(n+1):nth-child(-n+6) {
                  animation: slideUp 1.1s cubic-bezier(0.65, 0, 0.35, 1) both;
                  -webkit-animation: slideUp 1.1s cubic-bezier(0.65, 0, 0.35, 1) both;
                }

                &:nth-child(n+7):nth-child(-n+12) {
                  animation: slideUp 1.2s cubic-bezier(0.65, 0, 0.35, 1) both;
                  -webkit-animation: slideUp 1.2s cubic-bezier(0.65, 0, 0.35, 1) both;
                }

                &:nth-child(n+13):nth-child(-n+18) {
                  animation: slideUp 1.3s cubic-bezier(0.65, 0, 0.35, 1) both;
                  -webkit-animation: slideUp 1.3s cubic-bezier(0.65, 0, 0.35, 1) both;
                }

                &:nth-child(n+19):nth-child(-n+24) {
                  animation: slideUp 1.4s cubic-bezier(0.65, 0, 0.35, 1) both;
                  -webkit-animation: slideUp 1.4s cubic-bezier(0.65, 0, 0.35, 1) both;
                }

                &:nth-child(n+25):nth-child(-n+30) {
                  animation: slideUp 1.5s cubic-bezier(0.65, 0, 0.35, 1) both;
                  -webkit-animation: slideUp 1.5s cubic-bezier(0.65, 0, 0.35, 1) both;
                }

                &:nth-child(n+31) {
                  animation: slideUp 1.6s cubic-bezier(0.65, 0, 0.35, 1) both;
                  -webkit-animation: slideUp 1.6s cubic-bezier(0.65, 0, 0.35, 1) both;
                }
              }
            }
          }

          .partner {
            .item {
              width: calc((100% / 3) - 11px);
              margin-left: 16px;

              &:nth-of-type(3n+1),
              &:first-of-type {
                margin-left: 0;
              }

              &:nth-of-type(n+4) {
                margin-top: 23px;
              }
            }
          }
        }
      }

      &:not(.in-partner) {
        .m-context {
          &.ani-on {
            .partner .item {
              &:nth-child(n+1):nth-child(-n+5) {
                animation: slideUp 1.1s cubic-bezier(0.65, 0, 0.35, 1) both;
                -webkit-animation: slideUp 1.1s cubic-bezier(0.65, 0, 0.35, 1) both;
              }

              &:nth-child(n+6):nth-child(-n+10) {
                animation: slideUp 1.2s cubic-bezier(0.65, 0, 0.35, 1) both;
                -webkit-animation: slideUp 1.2s cubic-bezier(0.65, 0, 0.35, 1) both;
              }

              &:nth-child(n+11):nth-child(-n+15) {
                animation: slideUp 1.3s cubic-bezier(0.65, 0, 0.35, 1) both;
                -webkit-animation: slideUp 1.3s cubic-bezier(0.65, 0, 0.35, 1) both;
              }

              &:nth-child(n+16):nth-child(-n+20) {
                animation: slideUp 1.4s cubic-bezier(0.65, 0, 0.35, 1) both;
                -webkit-animation: slideUp 1.4s cubic-bezier(0.65, 0, 0.35, 1) both;
              }

              &:nth-child(n+21):nth-child(-n+25) {
                animation: slideUp 1.5s cubic-bezier(0.65, 0, 0.35, 1) both;
                -webkit-animation: slideUp 1.5s cubic-bezier(0.65, 0, 0.35, 1) both;
              }

              &:nth-child(n+26):nth-child(-n+30) {
                animation: slideUp 1.6s cubic-bezier(0.65, 0, 0.35, 1) both;
                -webkit-animation: slideUp 1.6s cubic-bezier(0.65, 0, 0.35, 1) both;
              }

              &:nth-child(n+31) {
                animation: slideUp 1.7s cubic-bezier(0.65, 0, 0.35, 1) both;
                -webkit-animation: slideUp 1.7s cubic-bezier(0.65, 0, 0.35, 1) both;
              }
            }
          }


          .partner {
            .item {
              width: calc((100% / 5) - 10px);
              margin-left: 12px;

              &:nth-of-type(5n+1),
              &:first-of-type {
                margin-left: 0;
              }

              &:nth-of-type(n+6) {
                margin-top: 23px;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 610px) and (max-width: 768px) {
    .section {
      &.inTopButton {
        .m-context {
          h3.section-title {
            margin: 0 0 62px 0;
            text-align: center;
          }
        }
      }
    }

    .section {
      &.in-partner {
        .m-context {
          h3.section-title {
            margin: 0 0 25px 0;
            font-size: 30px;
            line-height: 40px;
          }

          &.ani-on {
            .partner {
              .item {
                &:nth-child(n+1):nth-child(-n+6) {
                  animation: slideUp 1.1s cubic-bezier(0.65, 0, 0.35, 1) both;
                  -webkit-animation: slideUp 1.1s cubic-bezier(0.65, 0, 0.35, 1) both;
                }

                &:nth-child(n+7):nth-child(-n+12) {
                  animation: slideUp 1.2s cubic-bezier(0.65, 0, 0.35, 1) both;
                  -webkit-animation: slideUp 1.2s cubic-bezier(0.65, 0, 0.35, 1) both;
                }

                &:nth-child(n+13):nth-child(-n+18) {
                  animation: slideUp 1.3s cubic-bezier(0.65, 0, 0.35, 1) both;
                  -webkit-animation: slideUp 1.3s cubic-bezier(0.65, 0, 0.35, 1) both;
                }

                &:nth-child(n+19):nth-child(-n+24) {
                  animation: slideUp 1.4s cubic-bezier(0.65, 0, 0.35, 1) both;
                  -webkit-animation: slideUp 1.4s cubic-bezier(0.65, 0, 0.35, 1) both;
                }

                &:nth-child(n+25):nth-child(-n+30) {
                  animation: slideUp 1.5s cubic-bezier(0.65, 0, 0.35, 1) both;
                  -webkit-animation: slideUp 1.5s cubic-bezier(0.65, 0, 0.35, 1) both;
                }

                &:nth-child(n+31) {
                  animation: slideUp 1.6s cubic-bezier(0.65, 0, 0.35, 1) both;
                  -webkit-animation: slideUp 1.6s cubic-bezier(0.65, 0, 0.35, 1) both;
                }
              }
            }
          }

          .partner {
            max-width: 530px;
            .item {
              width: calc((100% / 3) - 8px);
              margin-left: 12px;

              &:nth-of-type(3n+1),
              &:first-of-type {
                margin-left: 0;
              }

              &:nth-of-type(n+4) {
                margin-top: 23px;
              }
            }
          }
        }
      }


      &:not(.in-partner) {
        .m-context {
          &.ani-on {
            .partner {
              .item {
                &:nth-child(n+1):nth-child(-n+4) {
                  animation: slideUp 1.1s cubic-bezier(0.65, 0, 0.35, 1) both;
                  -webkit-animation: slideUp 1.1s cubic-bezier(0.65, 0, 0.35, 1) both;
                }

                &:nth-child(n+5):nth-child(-n+9) {
                  animation: slideUp 1.2s cubic-bezier(0.65, 0, 0.35, 1) both;
                  -webkit-animation: slideUp 1.2s cubic-bezier(0.65, 0, 0.35, 1) both;
                }

                &:nth-child(n+10):nth-child(-n+14) {
                  animation: slideUp 1.3s cubic-bezier(0.65, 0, 0.35, 1) both;
                  -webkit-animation: slideUp 1.3s cubic-bezier(0.65, 0, 0.35, 1) both;
                }

                &:nth-child(n+15):nth-child(-n+19) {
                  animation: slideUp 1.4s cubic-bezier(0.65, 0, 0.35, 1) both;
                  -webkit-animation: slideUp 1.4s cubic-bezier(0.65, 0, 0.35, 1) both;
                }

                &:nth-child(n+20):nth-child(-n+24) {
                  animation: slideUp 1.5s cubic-bezier(0.65, 0, 0.35, 1) both;
                  -webkit-animation: slideUp 1.5s cubic-bezier(0.65, 0, 0.35, 1) both;
                }

                &:nth-child(n+25):nth-child(-n+29) {
                  animation: slideUp 1.6s cubic-bezier(0.65, 0, 0.35, 1) both;
                  -webkit-animation: slideUp 1.6s cubic-bezier(0.65, 0, 0.35, 1) both;
                }

                &:nth-child(n+30) {
                  animation: slideUp 1.7s cubic-bezier(0.65, 0, 0.35, 1) both;
                  -webkit-animation: slideUp 1.7s cubic-bezier(0.65, 0, 0.35, 1) both;
                }
              }
            }
          }

          .partner {
            .item {
              width: calc((100% / 4) - 9px);
              margin-left: 12px;

              &:nth-of-type(4n+1),
              &:first-of-type {
                margin-left: 0;
              }

              &:nth-of-type(n+5) {
                margin-top: 23px;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 609px) {
    .section.inTopButton {
      .m-context {
        .partner {
          .item {
            &:nth-of-type(n+15) {
              display: none;
            }
          }
        }
      }
    }

    .section {
      &.in-partner {
        .m-context {
          h3.section-title {
            margin: 0 0 25px 0;
            font-size: 30px;
            line-height: 40px;
          }

          &.ani-on {
            .partner {
              .item {
                &:nth-child(n+1):nth-child(-n+4) {
                  animation: slideUp 1.1s cubic-bezier(0.65, 0, 0.35, 1) both;
                  -webkit-animation: slideUp 1.1s cubic-bezier(0.65, 0, 0.35, 1) both;
                }

                &:nth-child(n+5):nth-child(-n+9) {
                  animation: slideUp 1.2s cubic-bezier(0.65, 0, 0.35, 1) both;
                  -webkit-animation: slideUp 1.2s cubic-bezier(0.65, 0, 0.35, 1) both;
                }

                &:nth-child(n+10):nth-child(-n+14) {
                  animation: slideUp 1.3s cubic-bezier(0.65, 0, 0.35, 1) both;
                  -webkit-animation: slideUp 1.3s cubic-bezier(0.65, 0, 0.35, 1) both;
                }

                &:nth-child(n+15):nth-child(-n+19) {
                  animation: slideUp 1.4s cubic-bezier(0.65, 0, 0.35, 1) both;
                  -webkit-animation: slideUp 1.4s cubic-bezier(0.65, 0, 0.35, 1) both;
                }

                &:nth-child(n+20):nth-child(-n+24) {
                  animation: slideUp 1.5s cubic-bezier(0.65, 0, 0.35, 1) both;
                  -webkit-animation: slideUp 1.5s cubic-bezier(0.65, 0, 0.35, 1) both;
                }

                &:nth-child(n+25):nth-child(-n+29) {
                  animation: slideUp 1.6s cubic-bezier(0.65, 0, 0.35, 1) both;
                  -webkit-animation: slideUp 1.6s cubic-bezier(0.65, 0, 0.35, 1) both;
                }

                &:nth-child(n+30) {
                  animation: slideUp 1.7s cubic-bezier(0.65, 0, 0.35, 1) both;
                  -webkit-animation: slideUp 1.7s cubic-bezier(0.65, 0, 0.35, 1) both;
                }
              }
            }
          }

          .partner {
            .item {
              width: calc((100% / 3) - 8px);
              margin-left: 12px;

              &:nth-of-type(3n+1),
              &:first-of-type {
                margin-left: 0;
              }

              &:nth-of-type(n+4) {
                margin-top: 23px;
              }

              /**
              &:nth-of-type(n+15) {
                display: none;
              }
               */
            }
          }
        }
      }

      &:not(.in-partner) {
        .m-context {
          &.ani-on {
            .partner {
              .item {
                &:nth-child(n+1):nth-child(-n+4) {
                  animation: slideUp 1.1s cubic-bezier(0.65, 0, 0.35, 1) both;
                  -webkit-animation: slideUp 1.1s cubic-bezier(0.65, 0, 0.35, 1) both;
                }

                &:nth-child(n+5):nth-child(-n+9) {
                  animation: slideUp 1.2s cubic-bezier(0.65, 0, 0.35, 1) both;
                  -webkit-animation: slideUp 1.2s cubic-bezier(0.65, 0, 0.35, 1) both;
                }

                &:nth-child(n+10):nth-child(-n+14) {
                  animation: slideUp 1.3s cubic-bezier(0.65, 0, 0.35, 1) both;
                  -webkit-animation: slideUp 1.3s cubic-bezier(0.65, 0, 0.35, 1) both;
                }

                &:nth-child(n+15):nth-child(-n+19) {
                  animation: slideUp 1.4s cubic-bezier(0.65, 0, 0.35, 1) both;
                  -webkit-animation: slideUp 1.4s cubic-bezier(0.65, 0, 0.35, 1) both;
                }

                &:nth-child(n+20):nth-child(-n+24) {
                  animation: slideUp 1.5s cubic-bezier(0.65, 0, 0.35, 1) both;
                  -webkit-animation: slideUp 1.5s cubic-bezier(0.65, 0, 0.35, 1) both;
                }

                &:nth-child(n+25):nth-child(-n+29) {
                  animation: slideUp 1.6s cubic-bezier(0.65, 0, 0.35, 1) both;
                  -webkit-animation: slideUp 1.6s cubic-bezier(0.65, 0, 0.35, 1) both;
                }

                &:nth-child(n+30) {
                  animation: slideUp 1.7s cubic-bezier(0.65, 0, 0.35, 1) both;
                  -webkit-animation: slideUp 1.7s cubic-bezier(0.65, 0, 0.35, 1) both;
                }
              }
            }
          }

          .partner {
            .item {
              width: calc((100% / 2) - 6px);
              margin-left: 12px;

              &:nth-of-type(2n+1),
              &:first-of-type {
                margin-left: 0;
              }

              &:nth-of-type(n+3) {
                margin-top: 23px;
              }
            }
          }
        }
      }
    }
  }
</style>