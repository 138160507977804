<template>
  <div class="m-context effect-policy">
    <div class="policy" :class="{isEng: view.isEng}">
      <div class="title">
        <p>{{list.title.title[0]}}</p>
        <h4>{{list.title.title[1]}}</h4>
        <span>2021.01.01</span>
      </div>
      <div class="banner">
        <img src="@assets/images/about/about_policy_img.png" />
      </div>
      <div class="p-item">
        <div class="item" v-for="(policy, index) in list.content" :key="index">
          <div class="head">
            <h4 class="order">0{{index + 1}}.</h4>
          </div>
          <div class="word">
            <h5>{{policy.content[0]}}</h5>
            <p>{{policy.content[1]}}</p>
          </div>
          <p class="content">{{policy.content[2]}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      policyList: {
        type: Object
      }
    },
    data() {
      return {
        list: [],
        view: {
          isEng: false
        }
      };
    },
    created() {
      this.initPolicyList(Number(sessionStorage.getItem('languageType')));
    },
    methods: {
       initPolicy() {
         document.querySelector(".effect-policy").classList.remove("ani-on");
      },
      setPolicy() {
         document.querySelector(".effect-policy").classList.add("ani-on");
      },
      isEng(languageType) {
        if (languageType) {
          this.list.title.title = this.policyList.title.titleEng;
          for (let i = 0; i < this.policyList.content.length; i++) {
            this.list.content[i].content = this.policyList.content[i].contentEng;
          }
          this.view.isEng = true;
        } else {
          this.list.title.title = this.policyList.title.titleKor;
          for (let i = 0; i < this.policyList.content.length; i++) {
            this.list.content[i].content = this.policyList.content[i].contentKor;
          }
          this.view.isEng = false;
        }
      },
      initPolicyList(languageType) {
        this.list = this.policyList;
        if (languageType === 0) {
          this.list.title.title = this.policyList.title.titleKor;
          for (let i = 0; i < this.policyList.content.length; i++) {
            this.list.content[i].content = this.policyList.content[i].contentKor;
          }
          this.view.isEng = false;
        } else {
          this.list.title.title = this.policyList.title.titleEng;
          for (let i = 0; i < this.policyList.content.length; i++) {
            this.list.content[i].content = this.policyList.content[i].contentEng;
          }
          this.view.isEng = true;
        }
      }
    },
    components: {},
  };
</script>
<style lang="scss" scoped>
  .m-context {
    padding: 180px 0 128px 0;
    &.effect-policy {
      &.ani-on {
      .policy {
        .title {
           animation: slideUp 0.9s cubic-bezier(0.65, 0, 0.35, 1) both;
          -webkit-animation: slideUp 0.9s cubic-bezier(0.65, 0, 0.35, 1) both;
          p {

          }
          h4 {

          }
        }
        .banner {
          animation: slideUp 1.0s cubic-bezier(0.65, 0, 0.35, 1) both;
          -webkit-animation: slideUp 1.0s cubic-bezier(0.65, 0, 0.35, 1) both;
        }
        .p-item {
          .item {
            &:nth-of-type(1) {
               animation: slideUp 1.1s cubic-bezier(0.65, 0, 0.35, 1) both;
          -webkit-animation: slideUp 1.1s cubic-bezier(0.65, 0, 0.35, 1) both;
            }
            &:nth-of-type(2) {
               animation: slideUp 1.2s cubic-bezier(0.65, 0, 0.35, 1) both;
          -webkit-animation: slideUp 1.2s cubic-bezier(0.65, 0, 0.35, 1) both;
            }
            &:nth-of-type(3) {
               animation: slideUp 1.3s cubic-bezier(0.65, 0, 0.35, 1) both;
          -webkit-animation: slideUp 1.3s cubic-bezier(0.65, 0, 0.35, 1) both;
            }
            &:nth-of-type(4) {
               animation: slideUp 1.4s cubic-bezier(0.65, 0, 0.35, 1) both;
          -webkit-animation: slideUp 1.4s cubic-bezier(0.65, 0, 0.35, 1) both;
            }
          }
        }
      }
    }
    }
  }

  h3.section-title {
    @include initfont($font-size:40px, $line-height:70px, $font-family:'Poppins-Bold', $color:#292a2b, $text-align:left);
  }

  .policy {
    &.isEng {
      .title {
        p {
          font-family: "Poppins-Regular";
        }

        h4 {
          font-family: "Poppins-SemiBold";
        }
      }

      .p-item {
        .item {
          .word {
            width: 240px;
            margin: 0 40px 0 0;

            p {
              font-family: "Poppins-Regular";
            }
          }

          p.content {
            font-family: "Poppins-Regular";
            width: calc(100% - 385px);
          }
        }
      }
    }

    .title {
      display: flex;
      flex-wrap: wrap;
      margin: 0 0 20px 0;
      align-items: baseline;

      p {
        width: 100%;
        @include initfont($font-size:30px, $font-family:'AppleSDGothicNeoR00', $color:#292a2b, $line-height: 48px, $letter-spacing: -0.9px, $text-align: left);
      }

      h4 {
        @include initfont($font-size:40px, $font-family:'AppleSDGothicNeoB00', $color:#292a2b, $line-height: 48px, $letter-spacing: -1.2px);
        margin: 0 10px 0 0;
      }

      span {
        @include initfont($font-size:20px, $font-family:'AppleSDGothicNeoR00', $color:#292a2b, $line-height: 20px, $letter-spacing: -0.48px, $text-align: left);
      }
    }

    .banner {
      margin: 0 0 20px 0;

      &.dummy {
        background: #eef1f3;
        width: 100%;
        height: 186px;
      }

      img {
        max-width: 100%;
      }
    }

    .p-item {
      .item {
        padding: 20px 0;
        display: flex;
        border: 0px solid #d7dadc;
        border-width: 1px 0 0 0;
        align-items: center;
        flex-wrap: wrap;

        &:nth-of-type(1) {
          border-width: 0 0 0 0;

          .word h5 {
            padding: 0 0 0 14px;

            &:after {
              content: "F";
            }
          }

        }

        &:nth-of-type(2) {
          .word h5 {
            padding: 0 0 0 10px;

            &:after {
              content: "I";
            }
          }
        }

        &:nth-of-type(3) {
          .word h5 {
            padding: 0 0 0 23.5px;

            &:after {
              content: "N";
            }
          }
        }

        &:nth-of-type(4) {
          .word h5 {
            padding: 0 0 0 17px;

            &:after {
              content: "E";
            }
          }
        }

        .head {
          width: 70px;
          margin: 0 27px 0 0;

          h4.order {
            @include initfont($font-size:40px, $font-family:'Poppins-Bold', $color:#d7dadc, $line-height: 50px, $letter-spacing: -1.2px);
          }
        }

        .word {
          margin: 0 73px 0 0;
          width: 175px;

          h5 {
            position: relative;
            @include initfont($font-size:24px, $font-family:'Poppins-SemiBold', $color:#292a2b, $line-height: 30px, $text-align:left);

            &:after {
              position: absolute;
              left: 0;
              font-size: 30px;
              color: #328ac4;
              bottom: 2.5px;
            }
          }

          p {
            @include initfont($font-size:17px, $font-family:'AppleSDGothicNeoM00', $color:#292a2b, $line-height: 24px, $letter-spacing: -0.54px, $text-align:left);
          }
        }

        p.content {
          width: calc(100% - 345px);
          @include initfont($font-size:17px, $font-family:'AppleSDGothicNeoR00', $color:#292a2b, $line-height: 24px, $letter-spacing: -0.54px, $text-align: left);
        }
      }
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .policy {
      .title {
        p {}

        h4 {}

        span {
          font-size: 16px;
        }
      }
    }
  }

  @media screen and (min-width: 610px) and (max-width: 768px) {
    .policy {
      .title {
        p {
          font-size: 20px;
          line-height: 40px;
        }

        h4 {
          font-size: 30px;
          line-height: 40px;
        }

        span {
          font-size: 16px;
        }
      }

      .p-item {
        .item {
          padding: 20px 0 30px 0;

          .word {
            width: calc(100% - 97px) !important;
            margin: 0 !important;
            display: flex;
            align-items: center;

            h5 {
              margin: 0 10px 0 0;
            }

            p {
              margin: 0;
            }
          }

          p.content {
            padding-left: 97px !important;
            width: 100% !important;
          }
        }
      }
    }
  }

  @media screen and (max-width: 609px) {
    .policy {
      .title {
        margin: 0;
        p {
          font-family: "AppleSDGothicNeoB00";
          font-size: 20px;
          line-height: 24px;
        }

        h4 {
          font-family: "AppleSDGothicNeoB00";
          font-size: 20px;
          line-height: 24px;
          margin: 0 0 5px 0;
        }

        span {
          font-size: 16px;
          display: block;
          width: 100%;
        }
      }

      .banner {
        display: none;
      }

      .p-item {
        .item {
          &:nth-of-type(1) {
            .word {
              &:after {
                content: "01.";
              }
             
            }
          }
          &:nth-of-type(2) {
            .word {
              &:after {
                content: "02.";
              }
              h5:after {
                left: 42px;
              }
            }
          }
          &:nth-of-type(3) {
            .word {
              &:after {
                content: "03.";
              }
              h5 {
                padding: 0 0 0 59px !important;
                &:after {
                   left: 40px;
                }
              }
            }
          }
          &:nth-of-type(4) {
            .word {
              &:after {
                content: "04.";
              }
                h5 {
                padding: 0 0 0 53px !important;
                &:after {
                   left: 40px;
                }
              }
            }
          }
          .head {
            display: none;
          }

          .word {
            width: calc(100% - 97px);
            margin: 0;
            position: relative;
            &:after {
               position: absolute;
                top: 0;
                font-family: "Poppins-SemiBold";
                color: #292a2b;
                text-align: left;
                line-height: 30px;
                font-size: 20px;
                letter-spacing: normal;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                left: 0;
            }
            p {
              margin: 0 0 10px 0;
               font-size: 15px;
            }

            h5 {
              padding: 0 0 0 50px !important;
               font-size: 20px;
              &:after {
                font-size: 20px;
                line-height: 30px;
                left: 38px;
                bottom:0;
              }
            }
          }

          p.content {
            padding-left: 0;
            width: 100% !important;
            font-size: 15px;
          }
        }
      }
    }
  }
</style>
