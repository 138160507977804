<template>
  <p>테스트</p>
</template>

<script>
export default {
  props: {
    
  },
  data() {
    return {
    
    };
  },
  created() {},
  methods: {},
  computed: {},
  components: {},
};
</script>
<style lang="scss" scoped>
</style>
