<template>
  <div class="m-context effect-unit">
    <div class="unit" :class="{isEng: view.isEng}">
      <div class="card-wrap">
        <div class="card" v-for="(card, index) in list" :key="index" @mouseover="hoverOn(index)"
          @click="openModal(index)"
          @mouseleave="hoverOut(index)">
          <div class="img-wrap">
            <img :src="require(`@/assets/images/unit/${card.img}`)" />
          </div>
          <div class="content">
            <div class="text">
              <h6>{{card.content[0]}}</h6>
              <p v-html="computedLine(card.content[1])"></p>
            </div>
          </div>
        </div>
      </div>
      <!--
      <div class="more" @click="moreList(button.isHide)" v-if="button.isVisible" :class="{isSpread: button.isHide}">
        <div class="icon-wrap">
        </div>
        <button type="button"  v-text="button.name"></button>
      </div>
      -->
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      unitList: {
        type: Array
      },
      selectedCard: {
        type: String
      },
      isPlusMode: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        list: [],
        plusList: [],
        rootCard: null,
        initFlag: false,
        button: {
          isHide: false,
          name: 'more',
          isVisible: false
        },
        view: {
          isEng: false
        },
      };
    },
    created() {
      this.initUnitList(Number(sessionStorage.getItem('languageType')));
      this.checkPlusMode();
    },
    mounted() {
      this.setUnit();
    },
    methods: {
      openModal(index) {
        this.$emit('openModal', index);
      },
      initUnit() {
         document.querySelector(".effect-unit").classList.remove("ani-on");
      },
      setUnit() {
         document.querySelector(".effect-unit").classList.add("ani-on");
      },
      checkPlusMode() {
        if (this.isPlusMode) {
          this.button.isVisible = true;
        }
      },
      moreList(condition) {
        if (condition) {
          this.initUnitList(Number(sessionStorage.getItem('languageType')));
          this.button.isHide = false;
          this.button.name = 'more';
        } else {
          let material;
        for (let i = 0; i < this.plusList.length; i++) {
          material = {
                img: this.unitList[i].img,
                content: this.plusList[i].content
              }
          this.list.unshift(material);
        }
        this.button.isHide = true;
        this.button.name = 'hide';
        }
      },
      currentNavClick(text) {
        this.rootCard = text;
        this.initCard();
        this.setCard();
      },
      initCard() {
        for (let i = 0; i < document.querySelector(".card-wrap").childElementCount; i++) {
          if (document.querySelector(".card-wrap").childNodes[i].classList.contains("hovered")) {
            document.querySelector(".card-wrap").childNodes[i].classList.remove("hovered");
          } 
        }
      },
      setCard() {
        this.$nextTick(_ => {
          if (this.rootCard !== undefined) {
            if (this.rootCard === 'skinex' || this.rootCard === 'skin') {
              document.querySelector(".card-wrap").childNodes[0].classList.add("hovered");
            } else if (this.rootCard === 'lime' || this.rootCard === 'emr') {
              document.querySelector(".card-wrap").childNodes[1].classList.add("hovered");
            } else if (this.rootCard === 'team') {
              document.querySelector(".card-wrap").childNodes[2].classList.add("hovered");
            } else if (this.rootCard === 'clinic') {
              document.querySelector(".card-wrap").childNodes[3].classList.add("hovered");
            }
            this.initFlag = true;
          }
        });
      },
      hoverOn(order) {
        if (this.initFlag) {
          this.initCard();
          this.initFlag = false;
        }
        document.querySelector(".card-wrap").childNodes[order].classList.add("hovered");
      },
      hoverOut(order) {
        document.querySelector(".card-wrap").childNodes[order].classList.remove("hovered");
      },
      initArray() {
         this.list = [];
        this.plusList = [];
      },
      isEng(languageType) {
        this.initArray();
        let material
        if (languageType) {
          for (let i = 0; i < this.unitList.length; i++) {
            if (i < 4) {
              material = {
                img: this.unitList[i].img,
                content: this.unitList[i].contentEng
              }
              this.list.push(material);
            } else {
              material = {
                img: this.unitList[i].img,
                content: this.unitList[i].contentEng
              }
              this.plusList.push(material);
            }
          }
          this.view.isEng = true;
        } else {
          for (let i = 0; i < this.unitList.length; i++) {
            if (i < 4) {
              material = {
                img: this.unitList[i].img,
                content: this.unitList[i].contentKor
              }
              this.list.push(material);
            } else {
              material = {
                img: this.unitList[i].img,
                content: this.unitList[i].contentKor
              }
              this.plusList.push(material);
            } 
          }
          this.view.isEng = false;
        }
        if (this.button.isHide) {
          this.moreList(false);
        }
      },
      initUnitList(languageType) {
        this.initArray();
        let material;
        if (languageType === 0) {
          for (let i = 0; i < this.unitList.length; i++) {
            if (i < 4) {
              material = {
                img: this.unitList[i].img,
                content: this.unitList[i].contentKor
              }
              this.list.push(material);
            } else {
              material = {
                img: this.unitList[i].img,
                content: this.unitList[i].contentKor
              }
              this.plusList.push(material);
            }
          }
          this.view.isEng = false;
        } else {
          for (let i = 0; i < this.unitList.length; i++) {
            if (i < 4) {
              material = {
                img: this.unitList[i].img,
                content: this.unitList[i].contentEng
              }
              this.list.push(material);
            } else {
              material = {
                img: this.unitList[i].img,
                content: this.unitList[i].contentEng
              }
              this.plusList.push(material);
            }
          }
          this.view.isEng = true;
        }
        console.log("list-끝", this.list);
        this.rootCard = this.selectedCard;
        this.setCard();
      }
    },
    computed: {
      computedLine() {
        return text => {
          if (text.indexOf("\n") !== -1 && window.outerWidth > 768) {
            return text.split("\n").join("<br />");
          } else {
            return text;
          }
        };
      },
    },
  };
</script>
<style lang="scss" scoped>
  .m-context {
    padding: 160px 0 200px 0;
    &.effect-unit {
      &.ani-on {
        .unit {
          .card-wrap {
            .card {
              cursor: pointer;
              animation: slideUp 0.9s cubic-bezier(0.65, 0, 0.35, 1) both;
            -webkit-animation: slideUp 0.9s cubic-bezier(0.65, 0, 0.35, 1) both;
            .content {
                .text {
                
                }
              }
              &:nth-of-type(2) {
                animation: slideUp 1.1s cubic-bezier(0.65, 0, 0.35, 1) both;
              -webkit-animation: slideUp 1.1s cubic-bezier(0.65, 0, 0.35, 1) both;
              .content {
                  .text {
                    
                  }
                }
              }
            }
          }
        }
      }
    }
  
  .unit {
    &.isEng {
      .content {
        .text {
          p {
            font-family: "Poppins-Regular";
          }
        }
      }
    }
  }
    .card-wrap {
      width: calc(100% - 256px);
      margin: 0 auto 100px auto;
      .card {
        border-radius: 5px;
        box-shadow: 0 3px 20px 0 rgba(112, 116, 120, 0.16);
        border: solid 1px #d7dadc;
        margin: 34px 0 0 0;
        min-height: 362px;
        display: flex;
        overflow: hidden;
        &:first-of-type {
          margin: 0;
        }

        &.hovered {
          border: solid 2px #114b8d;
        }

        .img-wrap {
          width: calc(100% - 320px);
          height: inherit;
          position: relative;
          &:after {
            content: "";
            width: 1px;
            height: 100%;
            background: #d7dadc;
            position: absolute;
            right: 0;
          }
          &.dummy {
            background: #eef1f3;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            background-color: white;
          }
        }

        .content {
          width: 320px;
          position: relative;
          background: #fff;
          .text {
            position: absolute;
            width: calc(100% - 84px);
            left: 50%;
            transform: translateX(-50%);
            bottom: 30px;

            h6 {
              @include initfont($font-size:18px, $font-family:'Poppins-SemiBold', $color:#0e3056, $line-height: 27px, $text-align: left);
              margin: 0 0 10px 0;
            }

            p {
              @include initfont($font-size:16px, $font-family:'AppleSDGothicNeoR00', $color:#292a2b, $line-height: 21px, $text-align: left);
            }
          }
        }
      }
    }
    .more {
      position:relative;
      width: 260px;
      height: 50px;
      border-radius: 25px;
      border: 1px solid #d7dadc;
      margin: 0 auto;
      background: transparent;
      &.isSpread {
        .icon-wrap {
           background-image: url("~@assets/images/unit/icon_more_hide.svg");
        }
        &:hover {
           .icon-wrap {
           background-image: url("~@assets/images/unit/icon_more_hide_hover.svg");
        }
        }
      }
      &:hover {
        background: #292a2b;
         border: 1px solid #292a2b;
        .icon-wrap {
          background-image: url("~@assets/images/unit/icon_more_white.svg");
          /**
          background-image: url("~@assets/images/unit/icon_more_hide.svg");
           */
        }
        button {
          color: #fff;
        }
      }
      .icon-wrap {
        position: absolute;
        background-image: url("~@assets/images/unit/icon_more.svg");
        width:24px;
        height:24px;
        margin: 0 5px 0 0;
        left:calc(50% - 36px);
        top:calc(50% + 1.5px);
        transform:translate(-50%,-50%);
      }
      button {
         @include initfont($font-size:18px, $font-family:'AppleSDGothicNeoB00', $color:#8d8f91, $line-height: 24px);
         background:transparent;
         padding:0;
         border:0;
         width: inherit;
         height: inherit;
         cursor:pointer;
      }

    }
  }

    @media screen and (min-width: 769px) and (max-width: 1024px) {
    .fullPage-container .m-context {
      padding: 180px 0 80px 0;
      .unit {
        .card-wrap {
          width: calc(100% - 200px);
          .card {
            margin: 30px 0 0 0;
            min-height: 330px;
            &:first-of-type {
              margin: 0;
            }
          }
          .content {
            .text {
              width: calc(100% - 56px);
              bottom: 28px;
              h6 {
                margin: 0 0 8px 0;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 610px) and (max-width: 768px) {
    .fullPage-container .m-context {
      padding: 160px 0 40px 0;
      .unit {
        .card-wrap {
          width: calc(100% - 112px);
          .card {
            margin: 24px 0 0 0;
            min-height: 263px;
            &:first-of-type {
              margin: 0;
            }
            .img-wrap {
              width: calc(100% - 220px);
              img {
                background-color: white;
              }
            }
          }
          .content {
            width: 220px;
            .text {
              width: calc(100% - 32px);
              bottom: 16px;
              h6 {
                margin: 0 0 6px 0;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 609px) {
    .fullPage-container .m-context {
      padding: 130px 0 40px 0;
      .unit {
        .card-wrap {
          width: calc(100% - 56px);
          margin: 0 auto 50px auto;
          .card {
            margin: 16px 0 0 0;
            min-height: 143px;
            &:first-of-type {
              margin: 0;
            }
            .img-wrap {
              width: calc(100% - 125px);
              img {
                background-color: white;
              }
            }
          }
          .content {
            width: 125px;
            .text {
              width: calc(100% - 20px);
              bottom: 10px;
              h6 {
                margin: 0;
                font-size: 16px;
                line-height: 24px;
              }
              p {
                display: none;
              }
            }
          }
        }
      }
    }
  }
</style>
