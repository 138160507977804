<template>
  <div class="about">
    <header-component ref="headerContext" :pageType="pageType" @currentLanguageType="languageTrans" :menuList="menuList" @sideMenu-call="sideMenuCall" @telederm-nav-clicked="moveSection"></header-component>
    <div class="menu-wrap" @click.stop :class="{deactive:!isMenu}">
    <!--
    <ul id="menu">
      <li data-menuanchor="page1" :class="{active: selectedMenu === 0}" @click="menuClick(0)"><a href="#page1">Skinex</a></li>
      <li data-menuanchor="page2" :class="{active: selectedMenu === 1}" @click="menuClick(1)"><a href="#page2">Lime Skin</a></li>
    </ul>
    -->
    </div>
    <full-page class="fullPage-container" ref="fullpage" :options="options" id="fullpage" @after-load="afterLoad"
      @on-leave="onLeave">
      <modal v-if="isModal">
          <div slot="title" class="title_block">
            <span class="page_count">{{currentNumber}} / 2</span>
            <button class="close_btn" type="button" slot="title" @click="isModal = false">
              <img src="@/assets/images/modal/close_icon.png" />
            </button>
          </div>
          <carousel slot="content" :images="modalData" @currentImage="currentImage" />
        </modal>
      <div class="section fp-auto-height bg-gray">
        <unit :unitList="unitList" :selectedCard="selectedCard" @openModal="openModal" ref="unitContext"></unit>
        <footer-component :footerList="footerList" ref="footerContext" @sideMenu-call="sideMenuCall"></footer-component>
      </div>
    </full-page>
    <top-button ref="topButtonContext" @pressedTopButton="moveTop"></top-button>
  </div>
</template>
<script>
  import HeaderComponent from '@component/HeaderBlack.vue';
  import FooterComponent from '@component/Footer.vue';
  import TopButton from '@component/TopButton.vue';
  import Unit from '@page/commons/Unit.vue'
  import Modal from '../components/Modal.vue';
  import Carousel from '@/components/Carousel.vue';

  export default {
    data() {
      return {
        pageType: 1,
        options: {
          licenseKey: '1B30CEC9-876B4E97-A3C378D3-89432445',
          afterLoad: this.afterLoad,
          onLeave: this.onLeave,
          scrollOverflow: false,
          responsiveWidth: '768',
          autoScrolling: false,
          fitToSectionDelay: 1000,
          navigation: false,
          },
        menuList: [
          {
            title: "About Us",
            child: ["Who We Are", "Ethics Policy", "Partnerships", "Finehealthcare CI"],
            link: ["about", "about#page2", "about#page3", "about#page4"]
          },
          {
            title: "Telederm Units",
            child: ["Skinex","Lime Skin"],
            link: ["telederm/skinex","telederm/lime"]
          },
          {
            title: "Big Data Platforms",
            child: ["Lime EMR", "Lime Team", "Lime Clinic"],
            link: ["platform/emr", "platform/team", "platform/clinic"]
          },
          {
            title: "Fine News",
            child: ["News"],
            link: ["news"]
          },
          {
            title: "Contact Us",
            child: ["Contact", "Direction & Map"],
            link: ["contact", "contact#page2"]
          }
        ],
        unitList:[
          {
          img: "skinex.png",
          contentKor: ["Skinex", "매일 더 똑똑해지는 AI 피부가이드,\n인공지능기반 피부 진단 소프트웨어"],
          contentEng: ["Skinex", "AI Skin guide that gets smarter every day\n AI based skin diagnosis software"]
          },
          {
          img: "lime_skin.png",
          contentKor: ["Lime Skin", "의료진과 환자를 직접 연결하는\nB2B 비대면 피부 상담 플랫폼"],
          contentEng: ["Lime Skin", "A B2B Teledermatology platform\nconnecting healthcare provider and patient"]
          }
        ],
        footerList: {
          menuLink:["",""],
          menuKor: ["개인정보 취급방침", "사이트맵"],
          menuEng: ["Privacy Policy", "SiteMap"],
          companyKor: ["(주) Finehealthcare", "주소 : (06069) 서울특별시 강남구 선릉로 704(청담동), 청담빌딩 7층 "],
          companyEng: ["Finehealthcare",
            "(06069) 7F, Cheongdam Building. 704, Seolleung-ro, Gangnam-gu, Seoul"
          ],
          infoKor: ["대표이사 : 신현경", "사업자등록번호 : 141-86-01677", "대표번호 : 02-565-2920",
            "이메일 : anobo@finehc.ai"
          ],
          infoEng: ["CEO: SHIN HYUN KYUNG", "Corporate Registration Number : 141-86-01677",
            "Telephone : 02-565-2920", "E-mail : anobo@finehc.ai"
          ],
        },
        languageType: sessionStorage.getItem('languageType'),
        isMenu: false,
        selectedMenu: 0,
        selectedCard: this.$route.params.code,
        isModal: false,
        modalData: null,
        currentNumber: 1,
      };
    },
    created() {
    },
    mounted() {
      this.currentMenu();
    },
    components: {
      HeaderComponent,
      FooterComponent,
      TopButton,
      Unit,
      Modal,
      Carousel,
    },

    computed: {},
    methods: {
      currentImage(currentImage) {
        this.currentNumber = currentImage + 1;
      },
      openModal(index) {
        window.scrollTo(0, 0);
        if (index === 1) {
          return;
        }
        this.isModal = !this.isModal;
        this.modalData = ['skinx-1', 'skinx-2']
      },
      currentMenu() {
        document.querySelector('.navigator').childNodes[1].classList.add("currented");
      },
      sideMenuCall(condition) {
        if (condition) {
          this.$refs.fullpage.api.silentMoveTo('page1');
          this.$refs.headerContext.isSpreadMenuByChild(true);
        }
      },
      moveSection(order) {
          if (order === 0) {
            this.$refs.unitContext.currentNavClick('skinex');
          } else if (order === 1) {
            this.$refs.unitContext.currentNavClick('lime');
          }
      },
      menuClick(order) {
        this.selectedMenu = order;
      },
      moveTop() {
        this.$refs.fullpage.api.moveTo('page1');
        this.$refs.headerContext.navTrans(0);
        this.$refs.topButtonContext.isView(false);
      },
      languageTrans(languageType) {
        this.$nextTick(_ => {
          if (languageType === 0) {
            this.$refs.unitContext.isEng(false);
            this.$refs.footerContext.isEng(false);
          } else {
            this.$refs.unitContext.isEng(true);
            this.$refs.footerContext.isEng(true);
          }
          this.$refs.fullpage.api.reBuild();
        });
      },
      onLeave(origin, destination, direction) {
        this.selectedMenu = destination.index;
        if (origin.index === 1 && direction === 'up') {
          this.$refs.headerContext.navTrans(0);
          this.$refs.topButtonContext.isView(false);
          this.isMenu = false;
        } else {
          this.$refs.headerContext.navTrans(2);
          this.$refs.topButtonContext.isView(true);
          this.isMenu = true;
        }
        console.log('Leave origin', origin.index);
        console.log('Leave destination', destination.index);
        console.log('Leave direction', direction);
      },
      afterLoad(origin, destination, direction) {
        console.log('After origin', origin.index);
        console.log('After destination', destination.index);
        console.log('After direction', direction);
      },
    },
    unmounted() {},
  };
</script>
<style lang="scss" scoped>
  @import "@assets/css/sectionType2";
  @import '@assets/css/_mixin';

  .title_block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px;
    width: 100%;
    @include mobile {
      padding: 0;
      padding-top: 10px;
    }
    .close_btn {
      cursor: pointer;
      background: none;
      border: none;
      @include mobile {
        padding: 0;
      }
    }
    .page_count {
      color: #fff;
    }
  }
</style>
