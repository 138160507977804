<template>
  <div class="footer-wrap" :class="{isEng: view.isEng}">
    <div class="footer">
      <ul class="menu-wrap">
        <ul class="menu">
          <li v-for="(menu, index) in list.menu" :key="index + 'A'">
            <router-link target="_blank" :event="index !== 1 ? 'click' : ''" @click.native="callSideMenu(index, $event)"
            :class="{isEng: view.isEng}" :to="'/' + list.menuLink[index]">{{menu}}</router-link>
          </li>
        </ul>
        <ul class="sns">
          <li v-for="(sns, index) in list.sns" :key="index + 'B'" class="sns-item" >
            <a target='_blank' :href='sns.link' :class="sns.name">
              <img :src="require(`@/assets/images/footer/${sns.name}.png`)" />
            </a>
          </li>
        </ul>
      </ul>
      <div class="company">
        <p class="company-name" v-text="list.company[0]"></p>
        <p v-text="list.company[1]"></p>
        <div class="info">
          <p>{{list.info[0]}}</p>
          <p>{{list.info[1]}}</p>
          <p>{{list.info[2]}}</p>
          <p>{{list.info[3]}}</p>
        </div>
        <p class="copyright">finehealthcare ⓒ 2024 All rights reserved</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      footerList: {
        type: Object
      },

    },
    data() {
      return {
        list: [],
        view: {
          isEng: false
        }
      };
    },
    created() {
      this.initFooterList(Number(sessionStorage.getItem('languageType')));
    },
    methods: {
      callSideMenu(order, e) {
        console.log("푸터순서 :", order);
       if (order === 0) {
         e.stopPropagation(); // Only seems to
          e.preventDefault(); // work with both
          // alert("준비중입니다.");
          console.log(e,"이벤트")
          window.open("https://sdoc24.acryl.ai/public/terms/privacy.html" )
       } else if (order === 1) {
          e.stopPropagation(); // Only seems to
          e.preventDefault(); // work with both
          this.$emit('sideMenu-call', true);
        }
      },
      isEng(languageType) {
        if (languageType) {
          this.list.menu = this.footerList.menuEng;
          this.list.company = this.footerList.companyEng;
          this.list.info = this.footerList.infoEng;
          this.view.isEng = true;
        } else {
          this.list.menu = this.footerList.menuKor;
          this.list.company = this.footerList.companyKor;
          this.list.info = this.footerList.infoKor;
          this.view.isEng = false;
        }
      },
      initFooterList(languageType) {
        this.list = this.footerList;
        if (languageType === 0) {
          this.list.menu = this.footerList.menuKor;
          this.list.company = this.footerList.companyKor;
          this.list.info = this.footerList.infoKor;
          this.view.isEng = false;
        } else {
          this.list.menu = this.footerList.menuEng;
          this.list.company = this.footerList.companyEng;
          this.list.info = this.footerList.infoEng;
          this.view.isEng = true;
        }
        this.list.sns = {
          naver:{link:"https://blog.naver.com/finehc_",name:"naver"},
          facebook:{link:"https://www.facebook.com/finehc",name:"facebook"},
          youtube:{link:"https://youtube.com/@finehc",name:"youtube"},
        }
      },
    },
    components: {},
  };
</script>
<style lang="scss" scoped>
  .footer-wrap {
    height: 299px;
    width: 100%;
    background: #0c1a29;

    &.isEng {
      .footer {
        ul.menu {
          li {
            a {
              font-family: 'Poppins-Regular';
            }
          }
        }

        .company {
          p {
            font-family: 'Poppins-Regular';

            &.company-name {
              font-family: 'Poppins-SemiBold';
            }
          }

          .info {
            p {

              line-height: 33px;
              font-family: 'Poppins-Regular';

              &:after {
                content: "";
                position: absolute;
                left: 8px;
                height: 17px;
                width: 2px;
                background: #8d8f91;
                top: 8px;
              }
            }
          }
        }
      }
    }

    .footer {
      padding: 44px 0 0 0;
      max-width: 1160px;
      margin: 0 auto;
      .menu-wrap{
        display: flex;
        justify-content: space-between;
      }
      ul.menu {
        display: flex;
        margin: 0 0 21px 0;
        li {
          position: relative;
          padding: 0 0 0 18px;

          &:first-of-type {
            padding: 0;

            &:after {
              display: none;
            }
          }

          &:after {
            content: "";
            position: absolute;
            left: 8px;
            height: 13px;
            width: 2px;
            background: #8d8f91;
            top: 4px;
          }

          a {
            @include initfont($font-size:18px, $text-align: left, $line-height:21px, $font-family:'AppleSDGothicNeoR00', $color:#8d8f91);
            border: 0;
            padding: 0;
            background: transparent;
            cursor: pointer;
          }
        }
      }
      ul.sns{
        display: flex;
        justify-content: space-between;
        height:21px;
        .sns-item{
          padding:0 0.7vw 0 0;
          img{
            height: 100%;
          }
        }
      }
      .company {
        p {
          @include initfont($font-size:18px, $text-align: left, $line-height:21px, $font-family:'AppleSDGothicNeoR00', $color:#8d8f91);

          &.company-name {
            font-family: 'AppleSDGothicNeoB00';
            margin: 0 0 8px 0;
          }

          &.copyright {
            font-family: 'Poppins-Regular'
          }
        }

        .info {
          display: flex;
          flex-wrap: wrap;
          margin: 8px 0 31px 0;

          p {
            position: relative;
            padding: 0 0 0 18px;

            &:first-of-type {
              padding: 0;

              &:after {
                display: none;
              }
            }

            &:after {
              content: "";
              position: absolute;
              left: 8px;
              height: 13px;
              width: 2px;
              background: #8d8f91;
              top: 4px;
            }
          }
        }
      }
    }
  }

  @media screen and  (max-width: 1399px) {
    .footer-wrap {
      .footer {
        width:100%;
      }
    }
  }
  @media screen and (min-width: 1025px) and (max-width: 1399px) {
     .footer-wrap {
      .footer {
        padding:44px 100px 0 100px;
         .company {
          .info {
            p {
              white-space: nowrap;
            }
          }
         }
      }
    }
  }

@media screen and (min-width: 1025px) and (max-width: 1299px) {
   .footer-wrap {
      .footer {
         .company {
          .info {
            p {
              &:last-of-type {
                    width: 100%;
                    margin: 8px 0 0 0;
                    padding: 0;
                    &:after {
                      display:none;
                    }
              }
            }
          }
         }
      }
    }
}

  @media screen and (max-width: 769px) {
    .footer-wrap {
      .footer {
      ul.sns{
        display: flex;
        justify-content: space-between;
        height:21px;
        .sns-item{
          padding:0 2vw 0 0;
          img{
            height: 100%;
          }
        }
      }
    }
  }
}
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .footer-wrap {
      &.isEng {
        height: 320px;
      }
      .footer {
        padding:44px 100px 0 100px;
        ul.menu {
          margin: 0 0 23px 0
        }
        .company {
          .info {
            margin: 8px 0 22px 0;
          }
            p {
              font-size: 16px;
              line-height: 19px;
            }
        }
      }
    }
  }
  @media screen and (min-width: 610px) and (max-width: 768px) {
    .footer-wrap {
      &.isEng {
        height: 320px;
      }
      .footer {
        padding:49px 56px 0 56px;
        ul.menu {
          margin: 0 0 23px 0
        }
        .company {
          .info {
            margin: 8px 0 22px 0;
          }
            p {
              font-size: 16px;
              line-height: 19px;
            }
        }
      }
    }
  }
  @media screen and (max-width: 609px) {
    .footer-wrap {
      .footer {
        padding:28px 28px 0 28px;
        ul.menu {
          margin: 0 0 10px 0;
          li {
            a {
              font-size:15px;
              line-height: 19px;
            }
            &:after {
              top: 3px;
              width: 1px;
            }
          }
        }
        ul.sns{
          height: 19px;
        }
        .company {
          .info {
            margin: 8px 0 14px 0;
            p {
               &:nth-of-type(3), &:nth-of-type(4) {
                    width: 100%;
                    margin: 8px 0 0 0;
                    padding: 0;
                    &:after {
                      display:none;
                    }
              }
              &:after {
                top: 3px;
                width: 1px;
              }
            }
          }
            p {
              font-size: 15px;
              line-height: 20px;
            }
        }
      }
    }
  }
</style>