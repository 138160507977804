<template>
  <div class="m-context effect-vision">
    <h3 class="section-title">Who We Are</h3>
    <div class="vision" :class="{isEng: view.isEng}">
      <div class="banner">
        <p>Vertically Integrated</p>
        <h4>Global Big Data + Teledermatology Company</h4>
      </div>
      <div class="type">
        <div class="v-item" v-for="(vision, index) in list" :key="index">
          <h5 class="title" v-text="vision.content[0]"></h5>
          <p v-html="computedLine(vision.content[1])"></p>
        </div>
      </div>
      <div class="circle-wrap">
        <div class="circle">
          <div class="content">
            <div class="item">
              <h4 class="title">DNA</h4>
              <ul>
                <li>BIG <b>D</b>ATA</li>
                <li><b>N</b>ETWORK</li>
                <li><b>A</b>I ALGORITHM</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="circle">
          <div class="content">
            <div class="item">
              <h4 class="title">EXE</h4>
              <ul>
                <li>GLOBAL <b>E</b>XPERIENCE</li>
                <li><b>X</b></li>
                <li>HEALTHCARE <b>E</b>XPERT</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="deco">x</div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      visionList: {
        type: Array
      }
    },
    data() {
      return {
        list: [],
        view: {
          isEng: false
        }
      };
    },
    created() {
      this.initVisionList(Number(sessionStorage.getItem('languageType')));
    },
    mounted() {
      this.setVision();
    },
    components: {},
    computed: {
      computedLine() {
        return text => {
          if (text.indexOf("\n") !== -1 && window.outerWidth > 1299) {
            return text.split("\n").join("<br />");
          } else {
            return text;
          }
        };
      },
    },
    methods: {
      initVision() {
        document.querySelector(".effect-vision").classList.remove("ani-on");
      },
      setVision() {
         document.querySelector(".effect-vision").classList.add("ani-on");
      },
      isEng(languageType) {
        if (languageType) {
          for (let i = 0; i < this.visionList.length; i++) {
            this.list[i].content = this.visionList[i].contentEng;
          }
          this.view.isEng = true;
        } else {
          for (let i = 0; i < this.visionList.length; i++) {
            this.list[i].content = this.visionList[i].contentKor;
          }
          this.view.isEng = false;
        }
      },
      initVisionList(languageType) {
        this.list = this.visionList;
        if (languageType === 0) {
          for (let i = 0; i < this.visionList.length; i++) {
            this.list[i].content = this.visionList[i].contentKor;
          }
          this.view.isEng = false;
        } else {
          for (let i = 0; i < this.visionList.length; i++) {
            this.list[i].content = this.visionList[i].contentEng;
          }
          this.view.isEng = true;
        }
      },
    },
    unmounted() {},
  };
</script>
<style lang="scss" scoped>
  .m-context {
    padding: 180px 0 90px 0;
    &.effect-vision {
      &.ani-on {
      .section-title {
           animation: slideUp 0.9s cubic-bezier(0.65, 0, 0.35, 1) both;
          -webkit-animation: slideUp 0.9s cubic-bezier(0.65, 0, 0.35, 1) both;
      }
      .banner {
          animation: slideUp 0.9s cubic-bezier(0.65, 0, 0.35, 1) both;
          -webkit-animation: slideUp 0.9s cubic-bezier(0.65, 0, 0.35, 1) both;
        p {
          
        }
        h4 {
          
        }
      }
      .type {
         animation: slideUp 1.0s cubic-bezier(0.65, 0, 0.35, 1) both;
          -webkit-animation: slideUp 1.0s cubic-bezier(0.65, 0, 0.35, 1) both;
        .v-item:nth-of-type(1) {
         
        }
        .v-item:nth-of-type(2) {
          
        }
        .v-item:nth-of-type(3) {
       
        }
      }
      .circle-wrap {
         animation: slideUp 1.1s cubic-bezier(0.65, 0, 0.35, 1) both;
          -webkit-animation: slideUp 1.1s cubic-bezier(0.65, 0, 0.35, 1) both;
        .circle {
          &:nth-of-type(2) {

          }
        }
      }
    }
    }
  }

  h3.section-title {
    @include initfont($font-size:40px, $line-height:70px, $font-family:'Poppins-Bold', $color:#292a2b, $text-align:left);

    &:nth-of-type(1) {
      margin: 0 0 26px 0;
    }

    margin: 0 0 78px 0;
  }

  .vision {
    padding: 0 62px;

    &.isEng {
      .type {
        .v-item {
          p {
            font-family: "Poppins-Regular";
          }
        }
      }
    }

    .banner {
      padding: 15px 0;
      background: #edf3f8;
      width: 100%;
      margin: 0 0 26px 0;

      p {
        @include initfont($font-size:30px, $line-height:48px, $font-family:'Poppins-Regular', $color:#292a2b, $letter-spacing: -0.3px);
      }

      h4 {
        @include initfont($font-size:36px, $line-height:48px, $font-family:'Poppins-SemiBold', $color:#0e3056, $letter-spacing: -0.36px);
        font-weight: 600;
      }
    }

    .type {
      display: flex;
      margin: 0 0 46px 0;

      .v-item {
        margin: 0 0 0 48px;
        width: calc((100% / 3) - 32px);
        position: relative;

        &:after {
          content: "";
          position: absolute;
          top: 0;
          width: 100%;
          height: 1px;
          background: #0e3056;
        }

        &:first-of-type {
          margin: 0;
        }

        h5.title {
          margin: 12px 0;
          @include initfont($font-size:20px, $line-height:26px, $font-family:'Poppins-SemiBold', $color:#0e3056);
        }

        p {
          @include initfont($font-size:16px, $line-height:26px, $font-family:'AppleSDGothicNeoR00', $color:#292a2b, $letter-spacing: -0.8px);
        }
      }
    }

    .circle-wrap {
      display: flex;
      justify-content: center;
      position: relative;

      .deco {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @include initfont($font-size:26px, $line-height:32px, $font-family:'Poppins-Regular', $color:#292a2b);
      }

      .circle {
        width: 266px;
        height: 266px;
        border-radius: 50%;
        overflow: hidden;
        background: rgba(36, 168, 255, 0.5);
        position: relative;

        &:nth-of-type(2) {
          background: rgba(138, 255, 169, 0.5);
          margin: 0 0 0 -24px;

          .content {
            .item {
              h4.title {
                color: #1cd94e;
              }

              ul {
                li {
                  b {
                    color: #1cd94e;
                  }
                }
              }
            }
          }
        }
        .content {
          position: absolute;
          width: 218px;
          height: 218px;
          border-radius: 50%;
          overflow: hidden;
          background: #fff;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          box-shadow: 0 3px 20px 0 rgba(112, 116, 120, 0.16);

          .item {
            position: absolute;
            width: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            h4.title {
              @include initfont($font-size:30px, $line-height:43px, $font-family:'Poppins-SemiBold', $color:#24a8ff);
              width: 100%;
              margin: 0 0 14px 0;
            }

            ul {
              width: 100%;

              li {
                @include initfont($font-size:18px, $line-height:30px, $font-family:'Poppins-Regular', $color:#292a2b, $letter-spacing: -0.54px);

                b {
                  color: #24a8ff;
                }
              }
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 1025px) and (max-width: 1299px) {
    .vision {
      padding: 0;
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .m-context {
      .vision {
        padding: 0;
        .banner {
          padding: 25px;
          margin: 0 0 40px 0;
          p {
            font-size: 28px;
            text-align: left;
          }
          h4 {
            font-size: 34px;
            text-align: left;
          }
        }
        .type {
          flex-wrap: wrap;
          margin: 0 0 62.5px 0;
          .v-item {
            width: 100%;
            margin: 50px 0 0 0;
            &:first-of-type {
              margin: 0;
            }
            &:after {
              width: 40px;
              height: 2px;
            }
            h5 {
              text-align: left;
            }
            p {
              text-align: left;
              .tltl {
                padding: 0;
              }
            }
          }
        }
      }
    }
  }
@media screen and (min-width: 610px) and (max-width: 768px) {
  #app .m-context {
      h3.section-title {
        font-size:30px;
        line-height: 40px;
      }
      .vision {
        padding: 0;
        .circle-wrap {
          .circle {
            width: 210px;
            height: 210px;
            .content {
              width: 172px;
              height: 172px;
              .item {
                h4.title {
                  font-size: 23px;
                  line-height: 31px;
                }
                ul {
                  li {
                    font-size: 14px;
                    line-height: 23px;
                  }
                }
              }
            }
          }
        }
        .banner {
          padding: 20px;
          margin: 0 0 30px 0;
          p {
            font-size: 16px;
            line-height: 26px;
            text-align: left;
          }
          h4 {
            font-size: 20px;
            line-height: 26px;
            text-align: left;
          }
        }
        .type {
          flex-wrap: wrap;
          margin: 0 0 42.5px 0;
          .v-item {
            width: 100%;
            margin: 37.5px 0 0 0;
            &:first-of-type {
              margin: 0;
            }
            &:after {
              width: 40px;
              height: 2px;
            }
            h5 {
              text-align: left;
            }
            p {
              text-align: left;
              .tltl {
                padding: 0;
              }
            }
          }
        }
      }
    }
}
@media screen and (max-width: 609px) {
  #app .m-context {
      h3.section-title {
        font-size:20px;
        line-height: 40px;
        margin: 0 0 20px 0;
      }
      .vision {
        padding: 0;
        .circle-wrap {
          .circle {
            width: 210px;
            height: 210px;
            .content {
              width: 172px;
              height: 172px;
              .item {
                h4.title {
                  font-size: 23px;
                  line-height: 31px;
                }
                ul {
                  li {
                    font-size: 14px;
                    line-height: 23px;
                  }
                }
              }
            }
          }
        }
        .banner {
          padding: 20px;
          margin: 0 0 43px 0;
          p {
            font-size: 14px;
            line-height: 23px;
            text-align: left;
          }
          h4 {
            font-size: 15px;
            line-height: 23px;
            text-align: left;
          }
        }
        .type {
          flex-wrap: wrap;
          margin: 0 0 42.5px 0;
          .v-item {
            width: 100%;
            margin: 21px 0 0 0;
            &:first-of-type {
              margin: 0;
            }
            &:after {
              width: 40px;
              height: 2px;
            }
            h5 {
              text-align: left;
              font-size: 16px;
              line-height: 24px;
            }
            p {
              font-size: 15px;
              text-align: left;
              line-height: 20px;
            }
          }
        }
      }
    }
}
@media screen and (max-width: 520px) {
  #app .m-context .vision .circle-wrap .circle {
    width: 187.5px;
    height: 187.5px;
    .content {
      width: 153.5px;
      height: 153.5px;
      .item {
        h4 {
          font-size: 16px;
          line-height: 27px;
        }
        ul li {
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
  }
}
</style>