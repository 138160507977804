<template>
  <transition name="modal" appear>
   <div class="modal">
      <div class="modal__window">
        <div class="modal__title">
          <slot name="title" />
        </div>
        <div class="modal__content">
          <slot name="content" />
        </div>
      </div>
      <div class="modal__overlay" @click.self="$emit('close')"></div>
    </div>
  </transition>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.modal {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: 10;
  
  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;

    background-color: black;
    opacity: 0.9;
  }

  &__window {
    width: 100%;
    height: 100%;
    border-radius: 0.4rem;
    overflow: hidden;
    padding: 1rem;
    z-index: 1;
    /* margin-top: -40%; */
    /* background-color: white; */
  }

  &__title {
    height: 85px;
    z-index: 10;
    width: 100%;
  }

  &__content {
    width: 100%;
    height: 100%;
  }


	// 상황에 따라 transition 변경가능 enter,leave class는 상단 문서 참고
  &-enter,
  &-leave-to {
    opacity: 0;
    transition: opacity 0.4s ease;
  }

  &-enter-to,
  &-leave {
    transition: opacity 0.4s ease;
  }
}
</style>