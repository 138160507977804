<template>
 <div id="app">
        <ul id="menu">
            <li data-menuanchor="page1" class="active"><a href="#page1">Section 1</a></li>
            <li data-menuanchor="page2"><a href="#page2">Section 2</a></li>
            <li data-menuanchor="page3"><a href="#page3">Section 3</a></li>
            <li>
                <a href="https://twitter.com/imac2" target="_blank" rel="noopener" class="twitter-share">
                    TWI
                </a>
            </li>
        </ul>
        <full-page class="fullPage-container" ref="fullpage" :options="options" id="fullpage"  @after-load="afterLoad" @on-leave="onLeave"> 
            <div class="section">
                <h3>Section 1</h3>
                <div class="pre-context">
                  
                </div>
            </div>
            <div class="section">
                <div class="slide">
                    <h3>Slide 2.1</h3>
                </div>
                <div class="slide">
                    <h3>Slide 2.2</h3>
                </div>
                <div class="slide">
                    <h3>Slide 2.3</h3>
                </div>
            </div>
            <div class="section">
                <h3>Section 3</h3>
            </div>
            <div class="section fp-auto-height">
                <div class="footer">
                  <p>이것은 푸터 영역</p>
                </div>
            </div>
            
        </full-page>
        
    </div>
</template>
<script>
export default {
  data() {
    return {
      options: {
        licenseKey: '1B30CEC9-876B4E97-A3C378D3-89432445',
        afterLoad: this.afterLoad,
        onLeave: this.onLeave,
        scrollOverflow: true,
        scrollBar: false,
        fitToSectionDelay: 1000,
        normalScrollElements: '.pre-context',
        offsetSections: true,
        menu: '#menu',
        navigation: false,
        anchors: ['page1', 'page2', 'page3'],
        sectionsColor: ['#41b883', '#ff5f45', '#0798ec', '#fec401', '#1bcee6', '#ee1a59', '#2c3e4f', '#ba5be9', '#b4b8ab'],
      },
    };
  },
  created() {
  },
  mounted() {
      
  },
  computed: {
  },
  methods: {
    onLeave(origin, destination, direction) {
       console.log('Leave origin', origin.index);
      console.log('Leave destination', destination.index);
      console.log('Leave direction', direction);
      if (destination.index > 0) {
          document.querySelector('#menu').classList.add('fixed');
      } else {
          document.querySelector('#menu').classList.remove('fixed');
      }
    },
    afterLoad(origin, destination, direction) {
      console.log('After origin', origin.index);
      console.log('After destination', destination.index);
      console.log('After direction', direction);      
    },
    toggleNavigation() {
      this.options.navigation = !this.options.navigation;
    },
    toggleScrollbar() {
      console.log('Changing scrollbar...');
      this.options.scrollBar = !this.options.scrollBar;
    },
  },

  unmounted() {
  },
};
</script>
<style lang="scss" scoped>
    .footer {
            min-height: 300px;
            width: 600px;
            margin: 0 auto;
    }

    
    .pre-context {
            height: 200px;
            width: 600px;
            overflow: hidden;
            margin: 0 auto;
    }
    .box {
           display: block;
            background: red;
            margin: 20px;
            padding: 20px;
    }
    #menu {
      position: absolute;
      background: white;
      top: 640px;
      z-index: 1;
      height: 16px;
      transition-property: all;
      transition-duration: 650ms;
      transition-timing-function: ease;
      transition-delay: 0s;
        &.fixed {
          top:40px;
          bottom: 0;
        }
      }
    ul {
        list-style-type: none;
        padding: 0;
    }
    li {
        display: inline-block;
        margin: 0 10px;
    }
    a {
        color: #42b983;
    }
</style>
