import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import basic from "./modules/basic";
const modules = {
  basic
};

const plugins = [
  createPersistedState({
    paths: ["basic"]
  })
];
Vue.use(Vuex);
export const store = new Vuex.Store({
  modules,
  plugins
});
