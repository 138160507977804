<template>
  <div class="swiper-wrap">
    <swiper class="swiper" :options="swiperOption" @slide-change-transition-end="slideEnd"
      @slide-change-transition-start="slideStart" ref="mySwiperRef" v-if="view.swiperOn">
      <!-- v-for -->
      <swiper-slide v-for="(swiperItem, index) in list" :key="index">
        <div class="banner" :class="{initial: resizeType !== 3}">
          <div class="b-background">
            <img :src="require(`@/assets/images/swiper/${swiperItem.img[0]}`)" v-if="resizeType === 0" />
            <img :src="require(`@/assets/images/swiper/${swiperItem.img[1]}`)" v-if="resizeType === 1" />
            <img :src="require(`@/assets/images/swiper/${swiperItem.img[2]}`)" v-if="resizeType === 2" />
            <img :src="require(`@/assets/images/swiper/${swiperItem.img[3]}`)" v-if="resizeType === 3" />
            <div class="b-content">
              <h4 class="subtitle" :class="{isEng: view.isEng}">{{swiperItem.content[0]}}</h4>
              <h2 class="title" :class="{isEng: view.isEng}">{{swiperItem.content[1]}}</h2>
              <p :class="{isEng: view.isEng}">{{swiperItem.content[2]}}</p>
            </div>
            <div class="scroll-hint">
              <p class="text">Scroll Down</p>
              <p class="line"></p>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>

    <div class="custom-swiper-button-prev" @click="prevClick()">
      <!-- <img src="@/assets/images/swiper/main_icon_carousel_arrow_left.svg" /> -->
    </div>
    <div class="custom-swiper-button-next" @click="nextClick()">
      <!-- <img src="@/assets/images/swiper/main_icon_carousel_arrow_right.svg" />-->
    </div>

  </div>
</template>

<script>
  import {
    Swiper,
    SwiperSlide
  } from "vue-awesome-swiper";
  import "swiper/swiper-bundle.css";
  export default {
    props: {
      swiperList: {
        type: Array
      }
    },
    data() {
      return {
        list: [],
        view: {
          swiperOn: false,
          isEng: false
        },
        resizeType: 3,
        width: '',
        height: '',
        swiperOption: {
          slidesPerView: 1,
          grabCursor: true,
          setWrapperSize: true,
          Height: window.outerHeight,
          resistanceRatio: 0,
          init: false,
          loop: true,
          observeParents: true,
          autoplay: {
            delay: 152500,
            disableOnInteraction: false,
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
          }
        }
      };
    },
    mounted() {
      this.slideStart(0);
      this.slideEnd(0);
    },
    unmounted() {},
    computed: {},
    created() {
      this.initSwiperList(Number(sessionStorage.getItem('languageType')));
    },
    methods: {
      slideEnd(info) {
        console.log("slide-end");
        for (let i = 0; i < this.$el.querySelector(".swiper-wrapper").childElementCount; i++) {
          if (this.$el.querySelector(".swiper-wrapper").childNodes[i].querySelector(".b-content h4.subtitle").classList
            .contains("ani-on")) {
            setTimeout(() => {
              this.$el.querySelector(".swiper-wrapper").childNodes[i].querySelector(".b-content h4.subtitle")
                .classList.remove("ani-on")
            }, 900);
          }
          if (this.$el.querySelector(".swiper-wrapper").childNodes[i].querySelector(".b-content h2.title").classList
            .contains("ani-on")) {
            setTimeout(() => {
              this.$el.querySelector(".swiper-wrapper").childNodes[i].querySelector(".b-content h2.title")
                .classList.remove("ani-on")
            }, 900);
          }
          if (this.$el.querySelector(".swiper-wrapper").childNodes[i].querySelector(".b-content p").classList
            .contains("ani-on")) {
            setTimeout(() => {
              this.$el.querySelector(".swiper-wrapper").childNodes[i].querySelector(".b-content p")
                .classList.remove("ani-on")
            }, 900);
          }
        }
      },
      slideStart() {
        console.log("slide-start");
         this.$el.querySelector(".swiper-slide-active .b-content h4.subtitle").classList.add("ani-on");
          this.$el.querySelector(".swiper-slide-active .b-content h2.title").classList.add("ani-on");
          this.$el.querySelector(".swiper-slide-active .b-content p").classList.add("ani-on");
      },
      setResizeType(order) {
        this.resizeType = order;
      },
      isEng(languageType) {
        this.view.swiperOn = false;
        if (languageType) {
          for (let i = 0; i < this.swiperList.length; i++) {
            this.list[i].content = this.swiperList[i].contentEng;
          }
          this.view.isEng = true;
        } else {
          for (let i = 0; i < this.swiperList.length; i++) {
            this.list[i].content = this.swiperList[i].contentKor;
          }
          this.view.isEng = false;
        }
        this.swiperOption.init = true;
        this.view.swiperOn = true;
      },
      initSwiperList(languageType) {
        this.list = this.swiperList;
        if (languageType === 0) {
          for (let i = 0; i < this.swiperList.length; i++) {
            this.list[i].content = this.swiperList[i].contentKor;
          }
          this.view.isEng = false;
        } else {
          for (let i = 0; i < this.swiperList.length; i++) {
            this.list[i].content = this.swiperList[i].contentEng;
          }
          this.view.isEng = true;
        }
        this.swiperOption.init = true;
        this.view.swiperOn = true;
      },
      setSwiperOpt() {
        /** document.querySelector(".swiper-wrapper").classList.add("special"); */
      },
      prevClick() {
        this.$refs.mySwiperRef.$swiper.slidePrev();
      },
      nextClick() {
        this.$refs.mySwiperRef.$swiper.slideNext();
      }
    },
    components: {
      Swiper,
      SwiperSlide
    }
  };
</script>

<style lang="scss" scoped>
  .swiper-wrap {
    overflow: hidden;
  }

  .banner {
    height: 100%;
    width: 100%;
    position: relative;

    &.initial {
      height: inherit;

      .b-background {
        img {
          height: inherit;
        }
      }
    }

    .b-background {
      height: inherit;
      position: relative;

      img {
        height: 100%;
      }

    }

    .b-content {
      position: absolute;
      width: 1160px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      h4.subtitle {
        @include initfont($font-size:30px, $line-height:40px, $font-family:'Poppins-Regular', $color:#ffffff, $text-align:left);

        &.ani-on {
          animation: slideUp 0.9s cubic-bezier(0.65, 0, 0.35, 1) both;
          -webkit-animation: slideUp 0.9s cubic-bezier(0.65, 0, 0.35, 1) both;
        }
      }

      h2.title {
        margin: 0;
        @include initfont($font-size:80px, $line-height:90px, $font-family:'Poppins-SemiBold', $color:#ffffff, $text-align:left);
         &.ani-on {
          animation: slideUp 0.9s cubic-bezier(0.65, 0, 0.35, 1) both;
          -webkit-animation: slideUp 0.9s cubic-bezier(0.65, 0, 0.35, 1) both;
        }
      }

      p {
        margin: 30px 0 0 0;
        @include initfont($font-size:20px, $line-height:26px, $font-family:'Poppins-Light', $color:#ffffff, $text-align:left);
         &.ani-on {
          animation: slideUp 0.9s cubic-bezier(0.65, 0, 0.35, 1) both;
          -webkit-animation: slideUp 0.9s cubic-bezier(0.65, 0, 0.35, 1) both;
        }
      }
    }
  }
@media screen and (min-width: 1025px) and (max-width: 1399px) {
    .swiper-wrap {

      .custom-swiper-button-prev,
      .custom-swiper-button-next {
        top: calc(50% - 73px);
      }
    }

    .banner {
      .b-content {
        width: calc(100% - 200px);
        top: calc(50% - 75px);

        h4.subtitle {
          font-size: 20px;
          line-height: 28px;
        }

        h2.title {
          font-size: 60px;
          line-height: 84px;
        }

        p {
          margin: 18px 0 0 0;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .swiper-wrap {
      .custom-swiper-button-prev {
        left: -39px;
        width: 125px;
        height: 125px;
      }

      .custom-swiper-button-next {
        right: -39px;
        width: 125px;
        height: 125px;
      }
    }

    .banner {
      .b-content {
        width: calc(100% - 200px);

        h4.subtitle {
          font-size: 20px;
          line-height: 28px;
        }

        h2.title {
          font-size: 60px;
          line-height: 84px;
        }

        p {
          margin: 18px 0 0 0;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    .scroll-hint {
      display: none;
    }
  }

  @media screen and (min-width: 610px) and (max-width: 768px) {
    .banner {
      .b-content {
        width: calc(100% - 200px);

        h4.subtitle {
          font-size: 20px;
          line-height: 28px;
        }

        h2.title {
          font-size: 60px;
          line-height: 64px;
        }

        p {
          margin: 20px 0 0 0;
          font-size: 14px;
          line-height: 18px;
        }
      }
    }

    .swiper-wrap {
      .custom-swiper-button-prev {
        left: -38px;
        width: 120px;
        height: 120px;
      }

      .custom-swiper-button-next {
        right: -38px;
        width: 120px;
        height: 120px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .scroll-hint {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  @media screen and (max-width: 609px) {
    .section.default {
    .banner {
      .b-content {
        width: 290px;

        h4.subtitle {
          font-size: 20px;
          line-height: 28px;
          text-align: center;
        }

        h2.title {
          font-size: 42px;
          line-height: 56px;
          text-align: center;
        }

        p {
          margin: 20px 0 0 0;
          font-size: 14px;
          line-height: 18px;
          text-align: center;
          white-space: nowrap;
        }
      }

      .b-background {
        img {
          height: min-content;
    width: 100%;
    max-width: none;
        }
      }
    }
    }

    .swiper-wrap {
      .custom-swiper-button-prev {
        left: -28px;
        width: 90px;
        height: 90px;
      }

      .custom-swiper-button-next {
        right: -28px;
        width: 90px;
        height: 90px;
      }
    }

    .scroll-hint {
      .text {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
</style>
