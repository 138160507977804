<template>
  <div class="about">
    <header-component
      ref="headerContext"
      :pageType="pageType"
      @currentLanguageType="languageTrans"
      @sideMenu-call="sideMenuCall"
      :menuList="menuList"
    ></header-component>
    <div class="menu-wrap" @click.stop :class="{ deactive: !isMenu }">
      <!--
    <ul id="menu">
      <li data-menuanchor="page1" :class="{active: selectedMenu === 0}" @click="menuClick(0)"><a href="#page1">Skinex</a></li>
      <li data-menuanchor="page2" :class="{active: selectedMenu === 1}" @click="menuClick(1)"><a href="#page2">Lime Skin</a></li>
    </ul>
    --></div>
    <full-page
      class="fullPage-container"
      ref="fullpage"
      :options="options"
      id="fullpage"
      @after-load="afterLoad"
      @on-leave="onLeave"
    >
      <div class="section fp-auto-height news">
        <news :newsList="newsList" ref="newsContext" :isPlusMode="true"></news>
        <top-button
          ref="topButtonContext"
          @pressedTopButton="moveTop"
        ></top-button>
        <footer-component
          :footerList="footerList"
          ref="footerContext"
          @sideMenu-call="sideMenuCall"
        ></footer-component>
      </div>
    </full-page>
  </div>
</template>
<script>
import HeaderComponent from "@component/HeaderBlack.vue";
import FooterComponent from "@component/Footer.vue";
import TopButton from "@component/TopButtonNormal.vue";
import News from "@page/commons/News.vue";

export default {
  data() {
    return {
      pageType: 3,
      options: {
        licenseKey: "1B30CEC9-876B4E97-A3C378D3-89432445",
        afterLoad: this.afterLoad,
        onLeave: this.onLeave,
        scrollOverflow: false,
        autoScrolling: false,
        fitToSectionDelay: 1000,
        responsiveWidth: "768",
        navigation: false,
      },
      menuList: [
        {
          title: "About Us",
          child: [
            "Who We Are",
            "Ethics Policy",
            "Partnerships",
            "Finehealthcare CI",
          ],
          link: ["about", "about#page2", "about#page3", "about#page4"],
        },
        {
          title: "Telederm Units",
          child: ["Skinex", "Lime Skin"],
          link: ["telederm/skinex", "telederm/lime"],
        },
        {
          title: "Big Data Platforms",
          child: ["Lime EMR", "Lime Team", "Lime Clinic"],
          link: ["platform/emr", "platform/team", "platform/clinic"],
        },
        {
          title: "Fine News",
          child: ["News"],
          link: ["news"],
        },
        {
          title: "Contact Us",
          child: ["Contact", "Direction & Map"],
          link: ["contact", "contact#page2"],
        },
      ],
      newsList: [
        {
          img: "20241113.png",
          link: "https://www.sedaily.com/DigitalPaper/20241113?PAGE_NO=008#",
          contentEng: [
            "Establishing Our Place as a Leading AI Healthcare Innovator Alongside Lunit and Selvas",
            "Sedaily",
            "2024.11.13",
          ],
          contentKor: [
            "루닛·셀바스와 함께 주목받는 AI 헬스케어 혁신 기업으로 자리매김",
            "서울경제",
            "2024.11.13",
          ],
        },
        {
          img: "20240405.png",
          link: "https://news.mt.co.kr/mtview.php?no=2024040509470366557",
          contentEng: [
            "Signed an MOU with LINET, the world's No. 1 bed and medical device manufacturer",
            "MONEYTODAY",
            "2024.04.05",
          ],
          contentKor: [
            "세계 1위 침대 및 의료기기 제조업체 리넷과 MOU 체결",
            "머니투데이",
            "2024.04.05",
          ],
        },
        {
          img: "20240322.png",
          link: "https://news.mt.co.kr/mtview.php?no=2024032213420093019",
          contentEng: [
            "Clinical Photography Management Program, 'PCOM' Released",
            "MONEYTODAY",
            "2024.03.21",
          ],
          contentKor: [
            "임상사진관리프로그램, '피콤' 출시",
            "머니투데이",
            "2024.03.21",
          ],
        },
        {
          img: "20231230.png",
          link: "http://www.monews.co.kr/news/articleView.html?idxno=328966",
          contentEng: [
            "Samsung Seoul Hospital's Nursing Division has established a strategic partnership",
            "MEDICAL Observer",
            "2023.12.30",
          ],
          contentKor: [
            "삼성서울병원 간호본부와 전략적 파트너십 체결",
            "메디컬업저버",
            "2023.12.30",
          ],
        },
        {
          img: "20231121.png",
          link: "https://medifonews.com/news/article.html?no=185124",
          contentEng: [
            "Pressure Injuries management system using AI, actually useful for clinical site",
            "medifonews",
            "2023.11.21",
          ],
          contentKor: [
            "AI 이용 욕창 관리 시스템, 임상 현장에 실제적인 도움 돼",
            "메디포뉴스",
            "2023.11.21",
          ],
        },
        {
          img: "20231113.png",
          link: "https://www.healthinnews.co.kr/news/articleView.html?idxno=42246",
          contentEng: [
            "Seminar on pressure injury management and wound treatment using AI will be held",
            "Healthinnews",
            "2023.11.13",
          ],
          contentKor: [
            "AI를 이용한 욕창 관리·상처 치료 세미나 개최",
            "헬스인뉴스",
            "2023.11.13",
          ],
        },
        {
          img: "20230907.png",
          link: "https://news.mt.co.kr/mtview.php?no=2023090618373329393",
          contentEng: [
            "'Woundoc' Wins 2023 Korea 4th Industrial Leading Company Award",
            "MONEYTODAY",
            "2023.09.07",
          ],
          contentKor: [
            "'상처닥', 2023 대한민국 4차산업 리딩기업 대상 수상",
            "머니투데이",
            "2023.09.07",
          ],
        },
        {
          img: "20230809.png",
          link: "https://news.mt.co.kr/mtview.php?no=2023080809503139330",
          contentEng: [
            "During the vacation season, we present first aid methods for wounds that occur on the beach and other places such as 'Woundoc', an essential app for skin disease AI care",
            "MONEYTODAY",
            "2023.08.08",
          ],
          contentKor: [
            "휴가철 피부질환 AI 케어 필수 앱 '상처닥', 바닷가 등에서 발생하는 상처응급처치법 제시",
            "머니투데이",
            "2023.08.08",
          ],
        },
        {
          img: "20230517.png",
          link: "http://www.medipharmnews.com/news/articleView.html?idxno=115048",
          contentEng: [
            "Winning the Grand Prize in AI Technology Innovation at the 2023 Korean Startup Association",
            "medipharmnews",
            "2023.05.17",
          ],
          contentKor: [
            "2023 한국창업학회에서 AI 기술혁신부문 대상 수상",
            "메디팜뉴스",
            "2023.05.17",
          ],
        },
        {
          img: "20230418.png",
          link: "https://www.healthbiz.co.kr/news/articleView.html?idxno=16924",
          contentEng: [
            "'Image-based Burn Depth classfication software' gets apporval for clinical trial plan as Wound image analysis software.",
            "healthbiz",
            "2023.04.18",
          ],
          contentKor: [
            "이미지 기반 화상 심도 평가 '상처영상분석소프트웨어' 임상시험계획승인",
            "헬스비즈",
            "2023.04.18",
          ],
        },
        {
          img: "20230223.png",
          link: "https://news.mt.co.kr/mtview.php?no=2023022210390720413",
          contentEng: [
            "Shin Hyun-kyung, CEO, Digital Home Healthcare Solution... 'Creating a ChatGPT for Skin'",
            "MONEYTODAY",
            "2023.02.22",
          ],
          contentKor: [
            "신현경 대표, 디지털홈헬스케어 솔루션...'피부 챗GPT 만들터'",
            "머니투데이",
            "2023.02.22",
          ],
        },
        {
          img: "20220902.png",
          link: "https://news.mt.co.kr/mtview.php?no=2022090118442836595",
          contentEng: [
            "'Skinex' Wins 2022 Korea 4th Industrial Leading Company Award",
            "MONEYTODAY",
            "2022.09.01",
          ],
          contentKor: [
            "'스키넥스(Skinex)', 2022 대한민국 4차산업 리딩기업 대상 수상",
            "머니투데이",
            "2022.09.01",
          ],
        },
        {
          img: "20220829.png",
          link: "http://www.itdaily.kr/news/articleView.html?idxno=209793",
          contentEng: [
            "Shin Hyun-kyung, CEO, registered as an international academic journal for 'Image Data Foundation Deep Learning Demonstration Research Results'",
            "IT DAILY",
            "2022.08.29",
          ],
          contentKor: [
            "신현경 대표, ‘화상 데이터 기반 딥러닝 실증 연구 결과’ 논문 국제학술지 등재",
            "아이티테일리",
            "2022.08.29",
          ],
        },

        {
          img: "20220802.png",
          link: "https://www.hankyung.com/economy/article/2022062317616",
          contentEng: [
            "Manage burns and ulcers with AI··· 1 million data gather 'SW medical device Development'",
            "hankyung",
            "2022.07.29",
          ],
          contentKor: [
            "AI로 화상·욕창 관리···데이터 100만건 모아 'SW의료기기 개발'",
            "한국경제",
            "2022.07.29",
          ],
        },
        {
          img: "20220704.png",
          link: "https://zdnet.co.kr/view/?no=20220704104528",
          contentEng: [
            "Bedsore AI will be launched next year··· Overseas expansion after accept to FDA",
            "ZDNet Korea",
            "2022.07.04",
          ],
          contentKor: [
            "욕창AI 내년 론칭···FDA 승인 후 해외 진출",
            "지디넷코리아",
            "2022.07.04",
          ],
        },
        {
          img: "20220623-1.png",
          link: "http://www.docdocdoc.co.kr/news/articleView.html?idxno=2024292",
          contentEng: [
            "AI System accuracy, The core is to divide bigdata 'jade and stone",
            "Youth doctor",
            "2022.06.22",
          ],
          contentKor: [
            "AI 시스템 정확도, 빅데이터 ‘옥과 석’ 가르기가 핵심",
            "청년의사",
            "2022.06.22",
          ],
        },

        {
          img: "20220512-1.png",
          link: "https://www.seenews365.com/news/articleView.html?idxno=45791",
          contentEng: [
            "AI badsore diagnosis 'Skinex' is entering North American market",
            "SEJONG KYEONGJE NEWS",
            "2022.05.11",
          ],
          contentKor: [
            "AI 욕창 진단 '스키넥스' 북미시장 진출 나선다",
            "세종경제뉴스",
            "2022.05.11",
          ],
        },
        {
          img: "20220509-4.png",
          link: "https://www.etoday.co.kr/news/view/2131523",
          contentEng: [
            "Samsung Seoul Hospital, Fine Healthcare, Development of AI-based 'Step Prediction Solution for Bathing Sores'",
            "Etoday",
            "2022.05.09",
          ],
          contentKor: [
            "삼성서울병원과 AI 욕창 단계 예측 솔루션 시스템 개발",
            "이투데이",
            "2022.05.09",
          ],
        },
        {
          img: "20220509-3.png",
          link: "http://mbnmoney.mbn.co.kr/program/vod/?program_no=1000109&menu_no=451",
          contentEng: [
            "keukchan Giup - Skin & Wound Medical Device Development Company",
            "Maeil Business TV",
            "2022.05.06",
          ],
          contentKor: [
            "극찬氣업 - Skin & Wound 의료기기 개발 기업",
            "매일경제TV",
            "2022.05.06",
          ],
        },
        {
          img: "20220509-2.png",
          link: "https://www.seenews365.com/news/articleView.html?idxno=45741",
          contentEng: [
            "'Skinex' to reduce gap between unskillful and skillful medical staff",
            "SEJONG KYEONGJE NEWS",
            "2022.05.04",
          ],
          contentKor: [
            "비숙련‧숙련 의료진 간극 줄이는 ‘스키넥스’",
            "세종경제뉴스",
            "2022.05.04",
          ],
        },
        {
          img: "20220509-1.png",
          link: "https://www.yakup.com/news/index.html?mode=view&cat=12&nid=268947",
          contentEng: [
            "Published 'communicable diseases Management Book' with Vietnam General Hospital.",
            "Yakup",
            "2022.05.02",
          ],
          contentKor: [
            "베트남 종합병원과 '감염병 관리 도서' 발간",
            "약업신문",
            "2022.05.02",
          ],
        },
        {
          img: "20220414.png",
          link: "https://www.nextunicorn.kr/unicornlive/tomorrows-unicorn-finehc",
          contentEng: [
            "Meet tomorrow's unicorn : Finehealthcare",
            "nextunicorn",
            "2022.04.14",
          ],
          contentKor: [
            "내일의 유니콘을 만나다 : 파인헬스케어",
            "넥스트 유니콘",
            "2022.04.14",
          ],
        },
        {
          img: "20220311.png",
          link: "http://www.healthinnews.co.kr/news/articleView.html?idxno=28526",
          contentEng: [
            "Discuss and educate African Health Authority officials on national application of digital healthcare systems",
            "Healthinnews",
            "2022.03.11",
          ],
          contentKor: [
            "아프리카 보건국 관계자들과 디지털 헬스케어 시스템 국가별 적용 방안 논의 및 교육 실시",
            "헬스인뉴스",
            "2022.03.11",
          ],
        },
        {
          img: "20220121.png",
          link: "https://www.hankyung.com/it/article/202201215244i",
          contentEng: [
            "Full-fledged advancement of digital healthcare with Cameroon General Hospiatl",
            "hankyung",
            "2022.01.21",
          ],
          contentKor: [
            "카메룬 종합병원과 디지털 헬스케어 고도화 본격화",
            "한국경제",
            "2022.01.21",
          ],
        },
        {
          img: "20211026.png",
          link: "http://www.medicaltimes.com/Users/News/NewsView.html?mode=view&ID=1143510&REFERER=NP",
          contentEng: [
            "Seminar on building an infectious disease platform with Danang Hospital",
            "MedicalTimes",
            "2021.10.14",
          ],
          contentKor: [
            "다낭병원과 감염병 플랫폼 구축 세미나",
            "메디칼타임즈",
            "2021.10.14",
          ],
        },
        {
          img: "20211013.png",
          link: "https://www.hankyung.com/it/article/202110085713i",
          contentEng: [
            "Agreement with Kelliman Central Hospital in Mozambique to establish medical big data",
            "hankyung",
            "2021.10.08",
          ],
          contentKor: [
            "모잠비크 켈리만 중앙병원과 의료 빅데이터 구축 협약",
            "한국경제",
            "2021.10.08",
          ],
        },
        {
          img: "20211006.png",
          link: "http://www.dybionews.com/news/articleView.html?idxno=12992",
          contentEng: [
            "Implementation of telemedicine system training for public health officials and medical staff in Tajikistan",
            "dybionews",
            "2021.09.29",
          ],
          contentKor: [
            "타지키스탄 보건직공무원 및 의료진 원격의료시스템 교육 실시",
            "동양바이오뉴스",
            "2021.10.06",
          ],
        },
        {
          img: "img2_bigdataplatform.png",
          link: "https://www.yakup.com/news/index.html?mode=view&cat=12&nid=261480",
          contentEng: [
            "Collaboration between AI and skin treatment… 'Reasonable medical bills are essential'",
            "Yakup",
            "2021.09.29",
          ],
          contentKor: [
            "AI와 피부치료의 컬래버레이션…'합리적 의료수가 필수'",
            "약업신문",
            "2021.09.29",
          ],
        },
        {
          img: "20210927-1.png",
          link: "http://www.seenews365.com/news/articleView.html?idxno=43434",
          contentEng: [
            "'Medical innovation' in one field with one picture",
            "SEJONG KYEONGJE NEWS",
            "2021.09.14",
          ],
          contentKor: [
            "사진 한 장으로 전문 상처진단... '의료혁신' 이룬다",
            "세종경제뉴스",
            "2021.09.16",
          ],
        },
        {
          img: "20210924-7.png",
          link: "https://www.rapportian.com/news/articleView.html?idxno=138666",
          contentEng: [
            "Held an online seminar for ‘AI-based wound and skin research",
            "RAPPORTIAN",
            "2021.09.14",
          ],
          contentKor: [
            "AI 기반 상처·피부 연구’ 온라인 세미나 개최",
            "라포르시안",
            "2021.09.14",
          ],
        },
        {
          img: "20210924-6.png",
          link: "https://www.getnews.co.kr/news/articleView.html?idxno=549769",
          contentEng: [
            "Telemedicine system advances to 13 overseas hospitals in 6 countries in earnest",
            "Getnews",
            "2021.09.10",
          ],
          contentKor: [
            "원격의료시스템 6개국 13개 해외병원 진출 사업 본격화",
            "글로벌경제",
            "2021.09.10",
          ],
        },
        {
          img: "20210924-5.png",
          link: "http://www.yakup.com/news/index.html?mode=view&cat=12&nid=260540",
          contentEng: [
            "Recruitment of Dr. Woo-Sung Kim as CMO... Accelerate the development of Teledermatology",
            "Yakup",
            "2021.08.26",
          ],
          contentKor: [
            "김우성 의사 CMO로 영입...텔레더마톨로지 개발에 박차",
            "약업신문",
            "2021.08.26",
          ],
        },
        {
          img: "20210924-4.png",
          link: "http://www.bokuennews.com/news/article.html?no=206138",
          contentEng: [
            "Hallym University Gangnam Sacred Heart, Fine Healthcare and Digital Healthcare MOU",
            "Bokuennews",
            "2021.07.22",
          ],
          contentKor: [
            "한림대강남성심, 파인헬스케어와 디지털 헬스케어 MOU",
            "보건뉴스",
            "2021.07.22",
          ],
        },
        {
          img: "20210924-3.png",
          link: "http://www.medipharmhealth.co.kr/news/article.html?no=73352",
          contentEng: [
            "Full-scale entry into the Vietnamese medical market",
            "Medipharmhealth",
            "2021.05.31",
          ],
          contentKor: [
            "베트남 의료시장 진출 본격화",
            "메디팜헬스",
            "2021.05.31",
          ],
        },
        {
          img: "20210924-2.png",
          link: "https://www.etnews.com/20210525000244",
          contentEng: [
            "Started online medical treatment business for CIS countries",
            "Etnews",
            "2021.05.25",
          ],
          contentKor: [
            "CIS 국가 대상 온라인 진료 사업 착수",
            "전자신문",
            "2021.05.25",
          ],
        },
        {
          img: "20210927-2.png",
          link: "http://www.mdtoday.co.kr/mdtoday/index.html?no=419695",
          contentEng: [
            "GMP certified by the Ministry of Food and Drug Safety as a medical device for predicting the depth of a burn",
            "MEDICAL Today",
            "2021.05.04",
          ],
          contentKor: [
            "화상심도예측 의료기기로 식약처 GMP 인증",
            "메디컬투데이",
            "2021.05.04",
          ],
        },
        {
          img: "img2_bigdataplatform.png",
          link: "https://www.etnews.com/20210205000092",
          contentEng: [
            "presents 'K-teledermatology' to the US",
            "Electronic Times Internet",
            "2021.02.07",
          ],
          contentKor: [
            "미국에 'K-텔레더마톨로지' 선보인다",
            "전자신문",
            "2021.02.07",
          ],
        },
        {
          img: "img2_bigdataplatform.png",
          link: "http://www.medigatenews.com/news/2081243687",
          contentEng: [
            "develops skin\nteleconsulltation platform 'Lime Skin'",
            "MEDI:GATE NEWS",
            "2021.01.28",
          ],
          contentKor: [
            "피부비대면 상담서비스 플랫폼\n '라임스킨' 개발",
            "메디게이트뉴스",
            "2021.01.28",
          ],
        },
        {
          img: "3.png",
          link: "https://mbn.co.kr/news/economy/4402379",
          contentEng: [
            "provides telemediccine training\n for Mozambique healthcare providers",
            "MBN",
            "2021.01.19",
          ],
          contentKor: [
            "파인헬스케어, 모잠비크 의료진에 원격의료 교육",
            "매일경제",
            "2021.01.19",
          ],
        },
        {
          img: "4.png",
          link: "http://www.medipharmhealth.co.kr/news/article.html?no=69999",
          contentEng: [
            "receives EMR certification with Lime",
            "medipharmhealth",
            "2021.01.15",
          ],
          contentKor: [
            "자체 개발한 ‘Lime’으로 EMR 인증 획득",
            "메디팜헬스",
            "2021.01.15",
          ],
        },
        {
          img: "5.png",
          link: "http://www.mdtoday.co.kr/mdtoday/index.html?no=408109",
          contentEng: [
            "signs partnership agreement with Samkwang Medical Laboratories for digital healthcare platform development",
            "MEDICAL Today",
            "2020.12.07",
          ],
          contentKor: [
            "삼광의료재단과 디지털 헬스케어 플랫폼 구축 위한 업무협약",
            "메디컬투데이",
            "2020.12.07",
          ],
        },
        {
          img: "img2_bigdataplatform.png",
          link: "http://www.bokuennews.com/news/article.html?no=198035",
          contentEng: [
            "launches a B2E mobile healthcare platform 'Lime Clinic'",
            "bokuennews",
            "2020.12.02",
          ],
          contentKor: [
            "B2E 모바일 헬스케어 플랫폼 'Lime Clinic' 론칭",
            "보건뉴스",
            "2020.12.02",
          ],
        },
        {
          img: "7.png",
          link: "https://magazine-hd.kr/17",
          contentEng: [
            "CEO Shin Hyun-kyung: A future where online and offline hospitalss co-exist",
            "MAGAZINE HD",
            "2020.11.17",
          ],
          contentKor: [
            "신현경 대표이사_의료빅데이터 연구를 통해 온오프라인 병원이 공존하는 시대를 그리다.",
            "매거진HD",
            "2020.11.17",
          ],
        },
        {
          img: "20210927-3.png",
          link: "http://www.hitnews.co.kr/news/articleView.html?idxno=30823",
          contentEng: [
            "Telemedicine is key to changing the paradigm of medical tourism",
            "HITNEWS",
            "2020.11.06",
          ],
          contentKor: [
            "원격의료는요, 의료관광 패러다임 바꿀 열쇠에요",
            "히트뉴스",
            "2020.11.06",
          ],
        },
        {
          img: "9.png",
          link: "http://www.dynews.co.kr/news/articleView.html?idxno=606606",
          contentEng: [
            "provides  education for telemedicine using next-generation EMR system Lime Team",
            "dynews",
            "2020.11.11",
          ],
          contentKor: [
            "차세대 의료정보시스템 라임 팀(Lime Team) 활용 원격 진료 교육",
            "동양일보",
            "2020.11.11",
          ],
        },
        {
          img: "img2_bigdataplatform.png",
          link: "http://www.medigatenews.com/news/2564084296",
          contentEng: [
            "solution selected for Korean International Medical Association's foreigner online consultation event",
            "MEDI:GATE NEWS",
            "2020.11.03",
          ],
          contentKor: [
            "국제의료협회 외국인 온라인 진료행사 솔루션 공급업체 선정",
            "메디게이트뉴스",
            "2020.11.03",
          ],
        },
        {
          img: "11.png",
          link: "http://www.mdtoday.co.kr/mdtoday/index.html?no=404473",
          contentEng: [
            "signs Lime Team partnership agreement with Global Together",
            "MEDICAL Today",
            "2020.11.02",
          ],
          contentKor: [
            "글로벌투게더와 라임팀 업무협약 체결",
            "메디컬투데이",
            "2020.11.02",
          ],
        },
        {
          img: "12.png",
          link: "http://www.medigatenews.com/news/1269860996",
          contentEng: [
            "accelerates export to Indonesia hospitals with Medical Device Industry Cooperative",
            "MEDI:GATE NEWS",
            "2020.10.24",
          ],
          contentKor: [
            "의료기기공업협동조합과 인도네시아 전문병원 시장 수출 박차",
            "메디게이트뉴스",
            "2020.10.24",
          ],
        },
        {
          img: "13.png",
          link: "http://www.movist.com/movist3d/view.asp?type=76&id=atc000000004511",
          contentEng: [
            "Patient-centered mobile app 'ThanQ' now available",
            "movist",
            "2020.10.15",
          ],
          contentKor: [
            "환자 중심 모바일 앱 ‘땡큐’ 오픈",
            "무비스트",
            "2020.10.15",
          ],
        },
        {
          img: "14.png",
          link: "https://www.startuptoday.kr/news/articleView.html?idxno=30013",
          contentEng: [
            "CEO 'Shin Hyun-kyung' not only foreign nationals but also foreigners can experience K-medicine",
            "STARTUP TODAY",
            "2020.06.09",
          ],
          contentKor: [
            "신현경 대표, “외국 거주 한국인뿐만 아니라 외국인도 K의료 경험 가능”",
            "스타트업투데이",
            "2020.06.09",
          ],
        },
        {
          img: "img2_bigdataplatform.png",
          link: "https://byline.network/2020/04/18-87/",
          contentEng: [
            "Interviewing 11 CEOs about  post-COVID start-ups",
            "Byline Network",
            "2020.04.18",
          ],
          contentKor: [
            "코로나 이후의 스타트업, 11명의 대표에게 물었습니다",
            "바이라인네트워크",
            "2020.04.18",
          ],
        },
        /**
          {
            img: "img1_medicalai.png",
            link: "http://www.etoday.co.kr/news/view/1854750",
            contentEng: ["establishes AI burn depth diagnosis and telemedicine system in Indonesia",
              "etoday",
              "2020.02.10"
            ],
            contentKor: ["인도네시아에 AI 화상심도 진단 및 원격의료시스템 구축", "이투데이", "2020.02.10"]
          },
          {
            img: "img2_bigdataplatform.png",
            link: "http://www.aitimes.kr/news/articleView.html?idxno=14758",
            contentEng: ["Fine Insight - a big-data based healthcare platform company - provides a solution for burn golden hours ",
              "Artificial Intelligence Times",
              "2019.11.26"
            ],
            contentKor: ["'화상 골든타임 잡는다'…빅데이터 기반 헬스케어 플랫폼 전문 기업 '파인인사이트'", "인공지능신문", "2019.11.26"]
          },
          {
            img: "img2_bigdataplatform.png",
            link: "https://www.dailysecu.com/news/articleView.html?idxno=79384",
            contentEng: ["Microsoft supports Fine Insight's  burn teleconsultation system",
              "dailysecu",
              "2019.11.14"
            ],
            contentKor: ["마이크로소프트, 파인인사이트의 원격 화상치료 상담 시스템 지원", "데일리시큐", "2019.11.14"]
          },
          {
            img: "19.png",
            link: "http://www.ipharmnews.com/news/view.php?no=42742",
            contentEng: ["Wpias - an app to consult burn wound/scars with a medical expert",
              "ipharmnews",
              "2019.11.13"
            ],
            contentKor: ["화상상처·흉터를 의학전문가와 상담하는 앱 ‘위피아스’", "아이팜뉴스", "2019.11.13"]
          },
          {
            img: "img2_bigdataplatform.png",
            link: "http://www.hitnews.co.kr/news/articleView.html?idxno=12731",
            contentEng: ["CEO Shin Hyun-kyung receives CQO award by Korean Society for Quality Management (KSQM)",
              "HITNEWS",
              "2019.11.05"
            ],
            contentKor: ["신현경 대표, 품질경영학회 CQO상 수상", "히트뉴스", "2019.11.05"]
          },
          {
            img: "21.jpg",
            link: "http://biz.chosun.com/site/data/html_dir/2019/09/06/2019090601512.html",
            contentEng: ["establishes mobile health questionnaire system in Bestian Hospital",
              "ChosunBiz",
              "2019.09.09"
            ],
            contentKor: ["베스티안병원에 모바일 건강문진 시스템 구축", "조선비즈", "2019.09.09"]
          },
          {
            img: "22.jpg",
            link: "http://www.datanet.co.kr/news/articleView.html?idxno=136209",
            contentEng: ["establishes e-healthcare system in Yogyakarta, Indonesia",
              "DATANET",
              "2019.08.05"
            ],
            contentKor: ["印尼 족자카르타에 E-헬스케어 시스템 구축", "데이터넷", "2019.08.05"]
          },
          {
            img: "23.jpg",
            link: "http://www.dailymedi.com/detail.php?number=834240&thread=22r06",
            contentEng: ["provides internship opportunities to unviersity students",
              "Dailymedi",
              "2018.08.31"
            ],
            contentKor: ["대학생에 실무체험 기회 제공", "데일리메디", "2018.08.31"]
          },
          {
            img: "img2_bigdataplatform.png",
            link: "http://www.fnnews.com/news/201803251642529930",
            contentEng: ["CEO Shin Hyun-kyung: Targeting Australia & China with IT Healthcare",
              "The financial news",
              "2018.03.25"
            ],
            contentKor: ["신현경 대표 'IT-헬스케어로 호주·中 등 공략'", "파이낸셜뉴스", "2018.03.25"]
          }
           */
      ],
      footerList: {
        menuLink: ["", ""],
        menuKor: ["개인정보 취급방침", "사이트맵"],
        menuEng: ["Privacy Policy", "SiteMap"],
        companyKor: [
          "(주) Finehealthcare",
          "주소 : (06069) 서울특별시 강남구 선릉로 704(청담동), 청담빌딩 7층 ",
        ],
        companyEng: [
          "Finehealthcare",
          "(06069) 7F, Cheongdam Building. 704, Seolleung-ro, Gangnam-gu, Seoul",
        ],
        infoKor: [
          "대표이사 : 신현경",
          "사업자등록번호 : 141-86-01677",
          "대표번호 : 02-565-2920",
          "이메일 : anobo@finehc.ai",
        ],
        infoEng: [
          "CEO: SHIN HYUN KYUNG",
          "Corporate Registration Number : 141-86-01677",
          "Telephone : 02-565-2920",
          "E-mail : anobo@finehc.ai",
        ],
      },
      languageType: sessionStorage.getItem("languageType"),
      isMenu: false,
      selectedMenu: 0,
      selectedCard: this.$route.params.code,
    };
  },
  created() {},
  mounted() {
    this.currentMenu();
  },
  components: {
    HeaderComponent,
    FooterComponent,
    TopButton,
    News,
  },
  computed: {},
  methods: {
    currentMenu() {
      document
        .querySelector(".navigator")
        .childNodes[3].classList.add("currented");
    },
    sideMenuCall(condition) {
      if (condition) {
        this.$refs.fullpage.api.silentMoveTo("page1");
        this.$refs.headerContext.isSpreadMenuByChild(true);
        this.$refs.topButtonContext.isView(false);
      } else {
        this.$refs.topButtonContext.isView(true);
      }
    },
    menuClick(order) {
      this.selectedMenu = order;
    },
    moveTop() {
      this.$refs.fullpage.api.moveTo("page1");
      this.$refs.headerContext.navTrans(0);
      this.$refs.topButtonContext.isView(false);
    },
    languageTrans(languageType) {
      this.$nextTick((_) => {
        if (languageType === 0) {
          this.$refs.newsContext.isEng(false);
          this.$refs.footerContext.isEng(false);
        } else {
          this.$refs.newsContext.isEng(true);
          this.$refs.footerContext.isEng(true);
        }
        this.$refs.fullpage.api.reBuild();
      });
    },
    onLeave(origin, destination, direction) {
      this.selectedMenu = destination.index;
      if (origin.index === 1 && direction === "up") {
        this.$refs.headerContext.navTrans(0);
        /**
          this.$refs.topButtonContext.isView(false);
           */
        this.isMenu = false;
      } else {
        this.$refs.headerContext.navTrans(2);
        /**
          this.$refs.topButtonContext.isView(true);
           */
        this.isMenu = true;
      }
      console.log("Leave origin", origin.index);
      console.log("Leave destination", destination.index);
      console.log("Leave direction", direction);
    },
    afterLoad(origin, destination, direction) {
      console.log("After origin", origin.index);
      console.log("After destination", destination.index);
      console.log("After direction", direction);
    },
  },
  unmounted() {},
};
</script>
<style lang="scss" scoped>
.about {
  position: relative;
}
@import "@assets/css/sectionType2";
</style>
